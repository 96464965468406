import { ComponentProps, useContext, useMemo } from 'react';
import { StyleSheet, Text as RNText, Platform } from 'react-native';
import { FontVariantKey, isFontVariantKey, useTheme } from '../../theme';
import { resolveFont } from '../../theme/fonts';
import { DisplayMode } from '../../contexts/displayMode';

/**
 * Custom text component. Key differences from react-native's Text:
 *
 * - defaults text color to 'theme.color.primary'
 * - defaults allowFontScaling to the value in the theme
 * - styles the text with color from the theme
 * - accepts a 'variant' prop, which corresponds to a font variant from our theme
 * - defaults to use 'theme.fonts.default' if you do not specify the variant prop
 *   of text on a PDF Worksheet or Mark Scheme
 */
export default function Text({
  allowFontScaling,
  variant,
  style,
  selectable = false,
  ...props
}: ComponentProps<typeof RNText> & {
  variant?: FontVariantKey;
}) {
  const displayMode = useContext(DisplayMode);
  const theme = useTheme();

  // Handle custom variants
  const variantStyle = isFontVariantKey(variant) ? theme.fonts[variant] : theme.fonts.default;

  // Flatten and resolve font style.
  const resolvedStyle = useMemo(
    () =>
      resolveFont(
        StyleSheet.flatten([
          {
            color: displayMode === 'digital' ? theme.colors.primary : theme.colors.pdfPrimary
          },
          variantStyle,
          style
        ])
      ),
    [displayMode, theme.colors.primary, theme.colors.pdfPrimary, variantStyle, style]
  );

  // Hacky support for ellipseMode="head" on web
  // This involves setting RTL styling with 'plaintext' unicode-bidi
  // NOTE: This may cause unintended issues in actual RTL languages - this solution has only been checked with English.
  // We may need to adapt this depending on the language.
  return Platform.OS === 'web' && props.ellipsizeMode === 'head' ? (
    <RNText
      allowFontScaling={allowFontScaling ?? theme.allowFontScaling}
      style={[resolvedStyle, { textAlign: 'right' }]}
      selectable={selectable}
      dir="rtl"
      {...props}
    >
      {/* Hack: fontSize needed here to override Quiz Studio web's global styling */}
      <div
        style={{ unicodeBidi: 'plaintext', display: 'inline', fontSize: resolvedStyle.fontSize }}
      >
        {props.children}
      </div>
    </RNText>
  ) : (
    <RNText
      allowFontScaling={allowFontScaling ?? theme.allowFontScaling}
      style={resolvedStyle}
      selectable={selectable}
      {...props}
    />
  );
}
