import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'FractionsA',
  field: 'Number',
  weeks: [9, 12],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'FindFractionsEquivalentToAUnitFraction',
      questionTypes: ['aoS', 'aoT', 'aoU', 'aoV', 'aoW', 'aoX'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/1FindFractionsEquivalentToAUnitFraction" */ './1FindFractionsEquivalentToAUnitFraction'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'FindFractionsEquivalentToANonUnitFraction',
      questionTypes: ['aoY', 'aoZ', 'ao0', 'ao1', 'ao2', 'ao3'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/2FindFractionsEquivalentToANonUnitFraction" */ './2FindFractionsEquivalentToANonUnitFraction'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'RecogniseEquivalentFractions',
      questionTypes: ['ao4', 'ao5', 'ao6', 'ao7', 'ao8', 'ao9'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/3RecogniseEquivalentFractions" */ './3RecogniseEquivalentFractions'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'ConvertImproperFractionsToMixedNumbers',
      questionTypes: ['apa', 'apb', 'apc', 'apd', 'ape', 'apf'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/4ConvertImproperFractionsToMixedNumbers" */ './4ConvertImproperFractionsToMixedNumbers'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'ConvertMixedNumbersToImproperFractions',
      questionTypes: ['apg', 'aph', 'api', 'apj', 'apk', 'apl'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/5ConvertMixedNumbersToImproperFractions" */ './5ConvertMixedNumbersToImproperFractions'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'CompareFractionsLessThan1',
      questionTypes: ['apm', 'apn', 'apo2', 'app', 'apq', 'apr'],
      archivedQuestionTypes: ['apo'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/6CompareFractionsLessThan1" */ './6CompareFractionsLessThan1'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'OrderFractionsLessThan1',
      questionTypes: ['aps', 'apt', 'apu', 'apv', 'apw', 'apx'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/7OrderFractionsLessThan1" */ './7OrderFractionsLessThan1'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'CompareAndOrderFractionsGreaterThan1',
      questionTypes: ['apy', 'apz', 'apA', 'apB', 'apC', 'apD'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/8CompareAndOrderFractionsGreaterThan1" */ './8CompareAndOrderFractionsGreaterThan1'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'AddAndSubtractFractionsWithTheSameDenominator',
      questionTypes: ['apE', 'apF', 'apG', 'apH', 'apI', 'apJ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/9AddAndSubtractFractionsWithTheSameDenominator" */ './9AddAndSubtractFractionsWithTheSameDenominator'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'AddFractionsWithin1',
      questionTypes: ['apL', 'apM', 'apN', 'apO', 'apP', 'apQ2'],
      archivedQuestionTypes: ['apQ'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/10AddFractionsWithin1" */ './10AddFractionsWithin1'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'AddFractionsWithTotalGreaterThan1',
      questionTypes: ['apR', 'apS', 'apT', 'apU', 'apV', 'apW'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/11AddFractionsWithTotalGreaterThan1" */ './11AddFractionsWithTotalGreaterThan1'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'AddToAMixedNumber',
      questionTypes: ['apX', 'apY', 'apZ', 'ap0', 'ap1', 'ap2'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/12AddToAMixedNumber" */ './12AddToAMixedNumber'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'AddTwoMixedNumbers',
      questionTypes: ['ap3', 'ap4', 'ap5', 'ap6', 'ap7', 'ap8'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/13AddTwoMixedNumbers" */ './13AddTwoMixedNumbers'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'SubtractFractions',
      questionTypes: ['asa', 'asb', 'asc', 'asd', 'ase', 'asf'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/14SubtractFractions" */ './14SubtractFractions'
        ).then(mod => mod.default)
    }),
    // Step 15
    newSmallStepContent({
      smallStep: 'SubtractFromAMixedNumber',
      questionTypes: ['asg2', 'ash', 'asi', 'asj', 'ask', 'asl'],
      archivedQuestionTypes: ['asg'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/15SubtractFromAMixedNumber" */ './15SubtractFromAMixedNumber'
        ).then(mod => mod.default)
    }),
    // Step 16
    newSmallStepContent({
      smallStep: 'SubtractFromAMixedNumberBreakingTheWhole',
      questionTypes: ['asm', 'asn', 'aso', 'asp', 'asq', 'asr'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/16SubtractFromAMixedNumberBreakingTheWhole" */ './16SubtractFromAMixedNumberBreakingTheWhole'
        ).then(mod => mod.default)
    }),
    // Step 17
    newSmallStepContent({
      smallStep: 'SubtractTwoMixedNumbers',
      questionTypes: ['ass', 'ast', 'asu', 'asv', 'asw', 'asx'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/FractionsA/17SubtractTwoMixedNumbers" */ './17SubtractTwoMixedNumbers'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
