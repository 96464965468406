// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newTermContent } from '../../Term';
import PlaceValue from './PlaceValue';
import AdditionAndSubtraction from './AdditionAndSubtraction';
import MultiplicationAndDivisionA from './MultiplicationAndDivisionA';
import FractionsA from './FractionsA';

const Term = newTermContent({
  term: 'Autumn',
  blocks: [PlaceValue, AdditionAndSubtraction, MultiplicationAndDivisionA, FractionsA]
});
export default Term;
