import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'PerimeterAndArea',
  field: 'Measurement',
  weeks: [9, 10],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'PerimeterOfRectangles',
      questionTypes: ['aRI', 'aRJ', 'aRK2', 'aRL', 'aRM', 'aRN'],
      archivedQuestionTypes: ['aRK'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/PerimeterAndArea/1PerimeterOfRectangles" */ './1PerimeterOfRectangles'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'PerimeterOfRectilinearShapes',
      questionTypes: ['aRO', 'aRP', 'aRQ2', 'aRR', 'aRS', 'aRT2'],
      archivedQuestionTypes: ['aRQ', 'aRT'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/PerimeterAndArea/2PerimeterOfRectilinearShapes" */ './2PerimeterOfRectilinearShapes'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'PerimeterOfPolygons',
      questionTypes: ['aRU', 'aRV', 'aRX', 'aRW', 'aRY', 'aRZ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/PerimeterAndArea/3PerimeterOfPolygons" */ './3PerimeterOfPolygons'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'AreaOfRectangles',
      questionTypes: ['aR0', 'aR1', 'aR22', 'aR3', 'aR4', 'aR5'],
      archivedQuestionTypes: ['aR2'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/PerimeterAndArea/4AreaOfRectangles" */ './4AreaOfRectangles'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'AreaOfCompoundShapes',
      questionTypes: ['aR6', 'aR7', 'aR8', 'aR9', 'aSa2', 'aSb'],
      archivedQuestionTypes: ['aSa'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/PerimeterAndArea/5AreaOfCompoundShapes" */ './5AreaOfCompoundShapes'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'EstimateArea',
      questionTypes: ['aSc', 'aSd', 'aSe', 'aSf2', 'aSg', 'aSh'],
      archivedQuestionTypes: ['aSf'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/PerimeterAndArea/6EstimateArea" */ './6EstimateArea'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
