import { CameraView, useCameraPermissions } from 'expo-camera';
import { Platform } from 'react-native';
import showAlert from './showAlert';
import { noop } from 'common/utils/flowControl';
import { useCallback } from 'react';

const isWeb = Platform.OS === 'web';

/**
 * Hook returning a function to: check camera permissions, and run the appropriate callback.
 *
 * This is a hook because the expo-camera API was changed in expo SDK 51, and the function to request permissions was
 * no longer exported directly.
 */
export function useCheckCameraPermissions(): (
  successCallback: () => void,
  failureCallback?: () => void
) => Promise<void> {
  const [_, requestPermission] = useCameraPermissions();

  return useCallback(
    async (successCallback, failureCallback = noop) => {
      try {
        const webHasCamera = isWeb && (await CameraView.isAvailableAsync());
        if (isWeb && !webHasCamera) {
          showAlert(
            'Your device does not have a useable camera. You can enter the code manually.',
            'Scanning unavailable'
          );
        }

        const { status } = await requestPermission();
        if (status === 'granted') {
          successCallback();
        } else if (status === 'denied') {
          showAlert(
            'This app does not have permission to access the camera.',
            'Scanning unavailable'
          );
        }
      } catch {
        showAlert(
          'Your device does not have a useable camera. You can enter the code manually.',
          'Scanning unavailable',
          [
            {
              text: 'Ok',
              onPress: failureCallback
            }
          ]
        );
      }
    },
    [requestPermission]
  );
}
