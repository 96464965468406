import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Fractions',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'IntroductionToPartsAndWhole',
      questionTypes: ['bj8', 'bj9', 'bka'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Fractions/1IntroductionToPartsAndWhole" */ './1IntroductionToPartsAndWhole'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'EqualAndUnequalParts',
      questionTypes: ['bkb', 'bkc', 'bkd'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Fractions/2EqualAndUnequalParts" */ './2EqualAndUnequalParts'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'RecogniseAHalf',
      questionTypes: ['bke', 'bkf', 'bkg'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Fractions/3RecogniseAHalf" */ './3RecogniseAHalf'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'FindAHalf',
      questionTypes: ['bkh', 'bki', 'bkj'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year2/Summer/Fractions/4FindAHalf" */ './4FindAHalf').then(
          mod => mod.default
        )
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'RecogniseAQuarter',
      questionTypes: ['bkk', 'bkl', 'bkm'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Fractions/5RecogniseAQuarter" */ './5RecogniseAQuarter'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'FindAQuarter',
      questionTypes: ['bkn', 'bko', 'bkp'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Fractions/6FindAQuarter" */ './6FindAQuarter'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'RecogniseAThird',
      questionTypes: ['bkq', 'bkr', 'bks'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Fractions/7RecogniseAThird" */ './7RecogniseAThird'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'FindAThird',
      questionTypes: ['bkt', 'bku', 'bkv'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year2/Summer/Fractions/8FindAThird" */ './8FindAThird').then(
          mod => mod.default
        )
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'FindTheWhole',
      questionTypes: ['bkw', 'bkx', 'bky'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Fractions/9FindTheWhole" */ './9FindTheWhole'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'UnitFractions',
      questionTypes: ['bkz', 'bkA', 'bkB'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Fractions/10UnitFractions" */ './10UnitFractions'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'NonUnitFractions',
      questionTypes: ['bkC', 'bkD', 'bkE'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Fractions/11NonUnitFractions" */ './11NonUnitFractions'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'RecogniseEquivalenceOfHalfAndTwoQuarters',
      questionTypes: ['bkF', 'bkG', 'bkH'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Fractions/12RecogniseEquivalenceOfHalfAndTwoQuarters" */ './12RecogniseEquivalenceOfHalfAndTwoQuarters'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'RecogniseThreeQuarters',
      questionTypes: ['bkI', 'bkJ', 'bkK'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Fractions/13RecogniseThreeQuarters" */ './13RecogniseThreeQuarters'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'FindThreeQuarters',
      questionTypes: ['bkL', 'bkM', 'bkN'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Fractions/14FindThreeQuarters" */ './14FindThreeQuarters'
        ).then(mod => mod.default)
    }),
    // Step 15
    newSmallStepContent({
      smallStep: 'CountInFractionsUpToAWhole',
      questionTypes: ['bkO', 'bkP', 'bkQ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Fractions/15CountInFractionsUpToAWhole" */ './15CountInFractionsUpToAWhole'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
