import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Decimals',
  field: 'Number',
  weeks: [5, 6],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'PlaceValueWithin1',
      questionTypes: ['aUI', 'aUJ', 'aUK2', 'aUL2', 'aUM', 'aUN'],
      archivedQuestionTypes: ['aUK', 'aUL'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Decimals/1PlaceValueWithin1" */ './1PlaceValueWithin1'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'PlaceValueIntegersAndDecimals',
      questionTypes: ['aUO', 'aUP', 'aUQ', 'aUR', 'aUS2', 'aUT'],
      archivedQuestionTypes: ['aUS'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Decimals/2PlaceValueIntegersAndDecimals" */ './2PlaceValueIntegersAndDecimals'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'RoundDecimals',
      questionTypes: ['aUU', 'aUV', 'aUW2', 'aUX2', 'aUY', 'aUZ'],
      archivedQuestionTypes: ['aUW', 'aUX'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Decimals/3RoundDecimals" */ './3RoundDecimals'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'AddAndSubtractDecimals',
      questionTypes: ['aU0', 'aU1', 'aU2', 'aU3', 'aU4', 'aU5'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Decimals/4AddAndSubtractDecimals" */ './4AddAndSubtractDecimals'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'MultiplyBy10100And1000',
      questionTypes: ['aU6', 'aU7', 'aU82', 'aU9', 'aVa2', 'aVb2'],
      archivedQuestionTypes: ['aU8', 'aVa', 'aVb'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Decimals/5MultiplyBy10100And1000" */ './5MultiplyBy10100And1000'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'DivideBy10100And1000',
      questionTypes: ['aVc2', 'aVd', 'aVe', 'aVf2', 'aVg2', 'aVh2'],
      archivedQuestionTypes: ['aVc', 'aVf', 'aVg', 'aVh'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Decimals/6DivideBy10100And1000" */ './6DivideBy10100And1000'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'MultiplyDecimalsByIntegers',
      questionTypes: ['aVi', 'aVj', 'aVk2', 'aVl', 'aVm2', 'aVn2'],
      archivedQuestionTypes: ['aVk', 'aVm', 'aVn'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Decimals/7MultiplyDecimalsByIntegers" */ './7MultiplyDecimalsByIntegers'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'DivideDecimalsByIntegers',
      questionTypes: ['aVo', 'aVp', 'aVq', 'aVr2', 'aVs2', 'aVt'],
      archivedQuestionTypes: ['aVr', 'aVs'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Decimals/8DivideDecimalsByIntegers" */ './8DivideDecimalsByIntegers'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'MultiplyAndDivideDecimalsInContext',
      questionTypes: ['aVu', 'aVv', 'aVw', 'aVx', 'aVy2', 'aVz'],
      archivedQuestionTypes: ['aVy'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Decimals/9MultiplyAndDivideDecimalsInContext" */ './9MultiplyAndDivideDecimalsInContext'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
