import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'NegativeNumbers',
  field: 'Number',
  weeks: [9, 9],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'UnderstandNegativeNumbers',
      questionTypes: ['aCw', 'aCx', 'aCy', 'aCz', 'aCA2', 'aCB'],
      archivedQuestionTypes: ['aCA'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/NegativeNumbers/1UnderstandNegativeNumbers" */ './1UnderstandNegativeNumbers'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'CountThroughZeroIn1s',
      questionTypes: ['aCC', 'aCD', 'aCE', 'aCF', 'aCG', 'aCH'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/NegativeNumbers/2CountThroughZeroIn1s" */ './2CountThroughZeroIn1s'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'CountThroughZeroInMultiples',
      questionTypes: ['aCI', 'aCJ', 'aCK', 'aCL2', 'aCM', 'aCN'],
      archivedQuestionTypes: ['aCL'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/NegativeNumbers/3CountThroughZeroInMultiples" */ './3CountThroughZeroInMultiples'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'CompareAndOrderNegativeNumbers',
      questionTypes: ['aCO', 'aCP', 'aCQ2', 'aCR', 'aCS', 'aCT'],
      archivedQuestionTypes: ['aCQ'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/NegativeNumbers/4CompareAndOrderNegativeNumbers" */ './4CompareAndOrderNegativeNumbers'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'FindTheDifference',
      questionTypes: ['aCU', 'aCV', 'aCW', 'aCX', 'aCY2', 'aCZ'],
      archivedQuestionTypes: ['aCY'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/NegativeNumbers/5FindTheDifference" */ './5FindTheDifference'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
