// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newTermContent } from '../../Term';
import PlaceValue from './PlaceValue';
import AdditionAndSubtraction from './AdditionAndSubtraction';
import Area from './Area';
import MultiplicationAndDivisionA from './MultiplicationAndDivisionA';

const Term = newTermContent({
  term: 'Autumn',
  blocks: [PlaceValue, AdditionAndSubtraction, Area, MultiplicationAndDivisionA]
});
export default Term;
