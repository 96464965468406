import { useTheme } from '../../theme';
import Button from '../atoms/Button';
import Text from '../typography/Text';
import ModalCard from './ModalCard';
import { useI18nContext } from '../../i18n/i18n-react';
import { View } from 'react-native';

/**
 * Simple modal which simply shows a title, some text, and an OK button.
 */
export default function OkModal({
  title,
  text,
  modalHeight,
  onDismiss
}: {
  title: string;
  text?: string;
  modalHeight?: number;
  onDismiss: () => void;
}) {
  const theme = useTheme();
  const translate = useI18nContext().LL;

  return (
    <ModalCard
      onDismiss={onDismiss}
      containerStyle={{
        alignItems: 'center',
        rowGap: 8,
        marginVertical: 60,
        height: modalHeight ?? (text !== undefined ? 428 : 324),
        width: 580
      }}
    >
      <Text variant="WRN700" style={{ textAlign: 'center' }}>
        {title}
      </Text>
      {text !== undefined && (
        <Text variant="WRN400" style={{ fontSize: 32, lineHeight: 48, textAlign: 'center' }}>
          {text}
        </Text>
      )}
      <View style={{ marginTop: 32 }}>
        <Button
          baseColor={theme.colors.secondaryContainer}
          onPress={onDismiss}
          style={{
            paddingLeft: 120,
            paddingRight: 120,
            paddingVertical: 24,
            width: 468
          }}
        >
          {translate.misc.OK()}
        </Button>
      </View>
    </ModalCard>
  );
}
