import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'MultiplicationAndDivision',
  field: 'Number',
  weeks: [3, 7],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'RecogniseEqualGroups',
      questionTypes: ['biD', 'biE', 'biF'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/1RecogniseEqualGroups" */ './1RecogniseEqualGroups'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'MakeEqualGroups',
      questionTypes: ['biG2', 'biH', 'biI'],
      archivedQuestionTypes: ['biG'],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/2MakeEqualGroups" */ './2MakeEqualGroups'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'AddEqualGroups',
      questionTypes: ['biJ', 'biK', 'biL'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/3AddEqualGroups" */ './3AddEqualGroups'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'IntroduceMultiplicationSymbol',
      questionTypes: ['biM', 'biN', 'biO'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/4IntroduceMultiplicationSymbol" */ './4IntroduceMultiplicationSymbol'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'MultiplicationSentences',
      questionTypes: ['biP', 'biQ', 'biR'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/5MultiplicationSentences" */ './5MultiplicationSentences'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'UseArrays',
      questionTypes: ['biS', 'biT', 'biU'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/6UseArrays" */ './6UseArrays'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'MakeEqualGroupsGrouping',
      questionTypes: ['biV', 'biW2', 'biX'],
      archivedQuestionTypes: ['biW'],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/7MakeEqualGroupsGrouping" */ './7MakeEqualGroupsGrouping'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'MakeEqualGroupsSharing',
      questionTypes: ['biY', 'biZ', 'bi0'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/8MakeEqualGroupsSharing" */ './8MakeEqualGroupsSharing'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'The2TimesTable',
      questionTypes: ['bi1', 'bi2', 'bi3'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/9The2TimesTable" */ './9The2TimesTable'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'DivideBy2',
      questionTypes: ['bi4', 'bi5', 'bi6'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/10DivideBy2" */ './10DivideBy2'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'DoublingAndHalving',
      questionTypes: ['bi7', 'bi82', 'bi9'],
      archivedQuestionTypes: ['bi8'],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/11DoublingAndHalving" */ './11DoublingAndHalving'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'OddAndEvenNumbers',
      questionTypes: ['bja', 'bjb', 'bjc'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/12OddAndEvenNumbers" */ './12OddAndEvenNumbers'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'The10TimesTable',
      questionTypes: ['bjd', 'bje', 'bjf'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/13The10TimesTable" */ './13The10TimesTable'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'DivideBy10',
      questionTypes: ['bjg', 'bjh', 'bji'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/14DivideBy10" */ './14DivideBy10'
        ).then(mod => mod.default)
    }),
    // Step 15
    newSmallStepContent({
      smallStep: 'The5TimesTable',
      questionTypes: ['bjj', 'bjk', 'bjl'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/15The5TimesTable" */ './15The5TimesTable'
        ).then(mod => mod.default)
    }),
    // Step 16
    newSmallStepContent({
      smallStep: 'DivideBy5',
      questionTypes: ['bjm', 'bjn', 'bjo'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/16DivideBy5" */ './16DivideBy5'
        ).then(mod => mod.default)
    }),
    // Step 17
    newSmallStepContent({
      smallStep: 'The5And10TimesTables',
      questionTypes: ['bjp', 'bjq', 'bjr'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MultiplicationAndDivision/17The5And10TimesTables" */ './17The5And10TimesTables'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
