import { SystemBars } from 'react-native-edge-to-edge';
import { CameraView, type BarcodeScanningResult } from 'expo-camera';
import { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import { type RootStackProps } from '../navigation/types';
import { parseOfflineQrCode, parseOnlineQrCode } from '../network/qrCodes';
import useQuizSessionStore, {
  type QuizSessionInfo,
  type QuestionInfo
} from '../storage/useQuizSessionStore';
import { createNewQuizSession } from '../network/quizSession';
import { useFocusEffect } from '@react-navigation/native';
import { Image } from 'expo-image';
import { getPlayer } from 'common/utils/Audio';
import { showErrorToast } from '../components/Toast';
import { useI18nContext } from '../i18n/i18n-react';
import {
  FQQuestionTypeContentUnloaded,
  lookupQuestion,
  type QuestionToken
} from 'common/SchemeOfLearning';
import useBreakpoints from '../hooks/useBreakpoints';
import { validateSchoolCode } from '../network/schoolCode';
import useLoginStore from '../storage/useLoginStore';
import uuid from 'react-native-uuid';
import Spinner from 'common/components/molecules/Spinner';
import Text from 'common/components/typography/Text';
import { colors } from 'common/theme/colors';

export default function ScanQRScreen({ navigation }: RootStackProps<'ScanQR'>) {
  const translate = useI18nContext().LL;

  const loggedInUser = useLoginStore(state => state.loggedInUser);
  const setQuizSession = useQuizSessionStore(state => state.setQuizSession);
  const loggedInLearningGroupShareCode = useLoginStore(state => state.school?.code);

  const { resize } = useBreakpoints();

  // Keep track of whether the current screen is focused (this doesn't cause rerenders though!)
  const isFocused = useRef(false);
  useFocusEffect(
    useCallback(() => {
      isFocused.current = true;
      return () => (isFocused.current = false);
    }, [])
  );

  const [scanned, setScanned] = useState(false);
  const [camera, setCamera] = useState<CameraView | null>(null);
  const { height } = useWindowDimensions();
  const [loading, setLoading] = useState<'initialLoading' | 'showLoadingSpinner' | 'notLoading'>(
    'notLoading'
  );

  // Set QR bounds
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [qrHeight, setQRHeight] = useState(0);
  const [qrWidth, setQRWidth] = useState(0);

  const setSchool = useLoginStore(state => state.setSchool);

  /** Callback for when something has gone wrong */
  const handleError = useCallback(
    (title: string, message: string) => {
      showErrorToast({ title, message, extraDuration: 1000, resize: resize });
      navigation.goBack();
    },
    [navigation, resize]
  );

  useEffect(() => {
    if (loading === 'initialLoading') {
      const timeout = setTimeout(() => {
        if (loading === 'initialLoading') {
          setLoading('showLoadingSpinner');
        }
        // Wait 1.5 second before showing the loading spinner as most requests won't take that long
      }, 1500);

      return () => clearTimeout(timeout); // Cleanup timeout if loading changes
    }

    if (loading === 'showLoadingSpinner') {
      const timeout = setTimeout(() => {
        if (loading === 'showLoadingSpinner') {
          handleError(
            translate.errorModals.loadingTheQuizHasBeenUnsuccessful(),
            translate.errorModals.pleaseTryAgain()
          );
        }
        // After 8 seconds show an error toast and navigate back to the home screen
      }, 8000);

      return () => clearTimeout(timeout); // Cleanup timeout if loading changes
    }
  }, [handleError, loading, translate.errorModals]);

  /** Callback for successfully scanning an offline QR code */
  const handleOfflineQrCode = useCallback(
    async (data: { tokens: QuestionToken[]; quizName: string }) => {
      // Add tiny delay to show target
      await new Promise(r => setTimeout(r, 500));

      if (!isFocused.current) {
        // User cancelled by navigating away
        return;
      }

      const questions = data.tokens.map((token, index) => {
        const questionInfo: QuestionInfo = {
          id: 'questions/1234',
          uid: typeof token === 'string' ? token : token[0],
          parameters: typeof token === 'string' ? undefined : JSON.stringify(token[1]),
          displayOrder: index + 1
        };

        return questionInfo;
      });

      // Fake up a year string, e.g. "Year 3" or "Year 3/4"
      const years = new Set<string>();
      data.tokens
        .map(token => lookupQuestion(token))
        // Filter out dodgy questions
        .filter((result): result is FQQuestionTypeContentUnloaded => typeof result !== 'string')
        // Add years to the years pool
        .forEach(it => years.add(it.year));

      // The QR code was an offline QR code.
      const quizSessionResult: QuizSessionInfo = {
        id: `import-${uuid.v4()}`,
        name: data.quizName,
        randomiseQuestionParameters: questions.every(
          //If imported quiz does not have parameters it will be randomised
          questionInfo => questionInfo.parameters === undefined
        ),
        questions: questions,
        quizSounds: true,
        retryInfo: { type: 'legacy' }
      };

      // Create the audio handler with the correct settings
      getPlayer(quizSessionResult.quizSounds);

      // Go to the Quiz Screen
      setQuizSession({
        ...quizSessionResult,
        questionResults: quizSessionResult.questionResults ?? []
      });
      navigation.replace('Quiz');
    },
    [navigation, setQuizSession]
  );

  /** Callback for successfully scanning an online QR code  */
  const handleOnlineQrCode = useCallback(
    async (data: { learningGroupShareCode: string; quizVersionShareShareCode: string }) => {
      // If student is logged in make sure school code in localStorage matches school code from scanned QR.
      if (
        loggedInUser &&
        loggedInLearningGroupShareCode &&
        loggedInLearningGroupShareCode !== data.learningGroupShareCode
      ) {
        handleError(
          translate.errorModals.cantAccessQuiz(),
          translate.errorModals.quizBelongsToDifferentGroup()
        );
        return;
      }

      setLoading('initialLoading');

      const schoolCodeResponse = await validateSchoolCode(data.learningGroupShareCode);

      if (typeof schoolCodeResponse === 'string') {
        // API validation failed, show an error message and hide the loading spinner

        switch (schoolCodeResponse) {
          case 'no infinity subscription':
            handleError(
              translate.errorModals.thereIsAnAccountIssue(),
              translate.errorModals.speakToYourTeacher()
            );
            return;
          case 'invalid response':
          case 'unknown error':
          case 'http error':
            handleError(
              translate.enterCodeOrPINScreen.somethingWentWrongError(),
              translate.errorModals.pleaseTryAgainLater()
            );
            return;
          case 'network error':
            handleError(
              translate.errorModals.internetConnectionLost(),
              translate.errorModals.thisAppRequiresAnInternetConnection()
            );
            return;
          case 'not found':
            handleError(
              translate.enterCodeOrPINScreen.schoolNotFoundError(),
              translate.errorModals.speakToYourTeacher()
            );
            return;
          default:
            // Produces TS error and throws runtime error if we missed a case
            throw new Error(`Logic error: unreachable (${schoolCodeResponse satisfies never})`);
        }
      }

      // Update the school in the store
      // UX note: If the user had a different school in the store but not logged in, we assume that is out of date, and the
      // school they want to use is the one from this QR code.
      // (If the user was logged in to a different school, we'd have hit the check above.)
      setSchool({
        code: data.learningGroupShareCode,
        name: schoolCodeResponse.name,
        hasInfinityPlus: schoolCodeResponse.hasInfinityPlus
      });

      // If user not logged in check if school has Infinity Plus before creating quiz session to prevent multiple
      // calls to quiz sessions
      if (
        useLoginStore.getState().loggedInUser === undefined &&
        // If user hasn't navigated away
        isFocused.current
      ) {
        if (schoolCodeResponse.hasInfinityPlus) {
          navigation.replace('EnterPupilAccessCode', {
            onSuccessLaunchQuizPin: data.quizVersionShareShareCode
          });
        }
      }

      // The QR code was an online QR code. We need to make a network request to get the quiz.

      const quizSessionResult = await createNewQuizSession(data);

      if (!isFocused.current) {
        // User cancelled by navigating away
        return;
      }

      if (typeof quizSessionResult === 'string') {
        // Request failed. Kick them back out to the home screen with an appropriate toast.
        switch (quizSessionResult) {
          case 'invalid response':
          case 'unknown error':
          case 'http error':
            handleError(
              translate.errorModals.somethingWentWrong(),
              translate.errorModals.pleaseTryAgainLater()
            );
            return;
          case 'network error':
            handleError(
              translate.errorModals.internetConnectionLost(),
              translate.errorModals.thisAppRequiresAnInternetConnection()
            );
            return;
          case 'not found':
            handleError(
              translate.errorModals.quizNotFoundForThisQrCode(),
              translate.errorModals.speakToYourTeacher()
            );
            return;
          case 'quiz locked':
            handleError(
              translate.errorModals.thisQuizIsLocked(),
              translate.errorModals.speakToYourTeacher()
            );
            return;
          default:
            // Produces TS error and throws runtime error if we missed a case
            throw new Error(`Logic error: unreachable (${quizSessionResult satisfies never})`);
        }
      }

      // Create the audio handler with the correct settings
      getPlayer(quizSessionResult.quizSounds);

      // Go to the Quiz Screen
      setQuizSession(quizSessionResult);

      navigation.replace('Quiz');

      setLoading('notLoading');
      return;
    },
    [
      handleError,
      loggedInLearningGroupShareCode,
      loggedInUser,
      navigation,
      setQuizSession,
      setSchool,
      translate.enterCodeOrPINScreen,
      translate.errorModals
    ]
  );

  /** Callback for when a barcode is scanned. */
  const handleBarCodeScanned = useCallback(
    async (result: BarcodeScanningResult) => {
      const { data, bounds } = result;
      camera?.pausePreview();
      setScanned(true);

      if (bounds) {
        const origin = { y: Number(bounds.origin.y), x: Number(bounds.origin.x) };
        const size = {
          height: Number(bounds.size.height),
          width: Number(bounds.size.width)
        };

        const padding = 50;
        setTop(origin.y - padding / 2);
        setLeft(origin.x - padding / 2);
        setQRHeight(size.height + padding);
        setQRWidth(size.height + padding);
      }

      const offlineParams = await parseOfflineQrCode(data);
      if (typeof offlineParams !== 'string') {
        await handleOfflineQrCode(offlineParams);
        return;
      }

      const onlineParams = parseOnlineQrCode(data);
      if (typeof onlineParams !== 'string') {
        await handleOnlineQrCode(onlineParams);
        return;
      }

      // Validation failed
      handleError(
        translate.errorModals.invalidQrCode(),
        translate.errorModals.tryAgainWithAValidQrCode()
      );
    },
    [camera, handleError, handleOfflineQrCode, handleOnlineQrCode, translate]
  );

  return loading === 'showLoadingSpinner' ? (
    <LoadingSubscreen height={height} />
  ) : (
    <View style={{ backgroundColor: 'black' }}>
      <SystemBars style="light" />
      <View
        style={{
          position: 'absolute',
          top: top,
          left: left,
          height: qrHeight,
          width: qrWidth,
          zIndex: 2,
          pointerEvents: 'none'
        }}
      >
        <Image
          source={require('pupil-app/assets/svg/QRGuide.svg')}
          style={{ height: qrHeight, width: qrWidth }}
        />
      </View>
      <View style={{ flex: 1 }}>
        <CameraView
          ref={ref => {
            setCamera(ref);
          }}
          style={[StyleSheet.absoluteFill, { height: height }]}
          onBarcodeScanned={scanned ? undefined : handleBarCodeScanned}
          barcodeScannerSettings={{ barcodeTypes: ['qr'] }}
          responsiveOrientationWhenOrientationLocked
        />
      </View>
    </View>
  );
}

/** Loading spinner to show when loading the quiz has taken more than 1.5 seconds. **/
function LoadingSubscreen({ height }: { height: number }) {
  const translate = useI18nContext().LL;
  return (
    <View
      key="loading"
      style={{ alignItems: 'center', justifyContent: 'center', height: height * 0.8 }}
    >
      <View style={{ gap: 24 }}>
        <Spinner height={156} />
        <Text variant="WRN400" style={{ fontSize: 32, lineHeight: 48, color: colors.prussianBlue }}>
          {translate.loadingEllipsis()}
        </Text>
      </View>
    </View>
  );
}
