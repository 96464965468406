// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newTermContent } from '../../Term';
import PlaceValue from './PlaceValue';
import AdditionSubtractionMultiplicationAndDivision from './AdditionSubtractionMultiplicationAndDivision';
import FractionsA from './FractionsA';
import FractionsB from './FractionsB';
import ConvertingUnits from './ConvertingUnits';

const Term = newTermContent({
  term: 'Autumn',
  blocks: [
    PlaceValue,
    AdditionSubtractionMultiplicationAndDivision,
    FractionsA,
    FractionsB,
    ConvertingUnits
  ]
});
export default Term;
