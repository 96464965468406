import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Ratio',
  field: 'Number',
  weeks: [1, 2],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'AddOrMultiply',
      questionTypes: ['aSM', 'aSN2', 'aSO2', 'aSP', 'aSQ', 'aSR'],
      archivedQuestionTypes: ['aSN', 'aSO'],
      module: () =>
        import(/* webpackChunkName: "Year6/Spring/Ratio/1AddOrMultiply" */ './1AddOrMultiply').then(
          mod => mod.default
        )
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'UseRatioLanguage',
      questionTypes: ['aSS2', 'aST2', 'aSU2', 'aSV2', 'aSW2', 'aSX2'],
      archivedQuestionTypes: ['aSS', 'aST', 'aSU', 'aSV', 'aSW', 'aSX'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Ratio/2UseRatioLanguage" */ './2UseRatioLanguage'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'IntroductionToTheRatioSymbol',
      questionTypes: ['aSY', 'aSZ', 'aS0', 'aS1', 'aS2', 'aS3'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Ratio/3IntroductionToTheRatioSymbol" */ './3IntroductionToTheRatioSymbol'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'RatioAndFractions',
      questionTypes: ['aS4', 'aS5', 'aS6', 'aS7', 'aS8', 'aS9'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Ratio/4RatioAndFractions" */ './4RatioAndFractions'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'ScaleDrawing',
      questionTypes: ['aTa', 'aTb', 'aTc', 'aTd', 'aTe', 'aTf'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year6/Spring/Ratio/5ScaleDrawing" */ './5ScaleDrawing').then(
          mod => mod.default
        )
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'UseScaleFactors',
      questionTypes: ['aTg', 'aTh', 'aTi', 'aTj2', 'aTk2', 'aTl2'],
      archivedQuestionTypes: ['aTj', 'aTk', 'aTl'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Ratio/6UseScaleFactors" */ './6UseScaleFactors'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'SimilarShapes',
      questionTypes: ['aTm2', 'aTn2', 'aTo2', 'aTp', 'aTq', 'aTr'],
      archivedQuestionTypes: ['aTm', 'aTn', 'aTo'],
      module: () =>
        import(/* webpackChunkName: "Year6/Spring/Ratio/7SimilarShapes" */ './7SimilarShapes').then(
          mod => mod.default
        )
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'RatioProblems',
      questionTypes: ['aTs2', 'aTt2', 'aTu', 'aTv', 'aTw', 'aTx'],
      archivedQuestionTypes: ['aTs', 'aTt'],
      module: () =>
        import(/* webpackChunkName: "Year6/Spring/Ratio/8RatioProblems" */ './8RatioProblems').then(
          mod => mod.default
        )
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'ProportionProblems',
      questionTypes: ['aTy', 'aTz2', 'aTA', 'aTB', 'aTC', 'aTD'],
      archivedQuestionTypes: ['aTz'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Ratio/9ProportionProblems" */ './9ProportionProblems'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'Recipes',
      questionTypes: ['aTE2', 'aTF2', 'aTG2', 'aTH2', 'aTI2', 'aTJ3'],
      archivedQuestionTypes: ['aTE', 'aTF', 'aTG', 'aTH', 'aTI', 'aTJ', 'aTJ2'],
      module: () =>
        import(/* webpackChunkName: "Year6/Spring/Ratio/10Recipes" */ './10Recipes').then(
          mod => mod.default
        )
    })
  ]
});
export default Block;
