import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'MultiplicationAndDivisionA',
  field: 'Number',
  weeks: [9, 11],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'MultiplesOf3',
      questionTypes: ['ak5', 'ak6', 'ak7', 'ak8', 'ak9', 'ala'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/MultiplicationAndDivisionA/1MultiplesOf3" */ './1MultiplesOf3'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'MultiplyAndDivideBy6',
      questionTypes: ['alb', 'alc', 'ald', 'ale', 'alf', 'alg'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/MultiplicationAndDivisionA/2MultiplyAndDivideBy6" */ './2MultiplyAndDivideBy6'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'Num6TimesTableAndDivisionFacts',
      questionTypes: ['alh', 'ali', 'alj', 'alk', 'all2', 'alm'],
      archivedQuestionTypes: ['all'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/MultiplicationAndDivisionA/3Num6TimesTableAndDivisionFacts" */ './3Num6TimesTableAndDivisionFacts'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'MultiplyAndDivideBy9',
      questionTypes: ['aln2', 'alo', 'alp', 'alq', 'alr', 'als'],
      archivedQuestionTypes: ['aln'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/MultiplicationAndDivisionA/4MultiplyAndDivideBy9" */ './4MultiplyAndDivideBy9'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'Num9TimesTableAndDivisionFacts',
      questionTypes: ['alt', 'alu', 'alv', 'alw', 'alx', 'aly'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/MultiplicationAndDivisionA/5Num9TimesTableAndDivisionFacts" */ './5Num9TimesTableAndDivisionFacts'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'The36And9TimesTables',
      questionTypes: ['alz', 'alA', 'alB', 'alC', 'alD2', 'alE'],
      archivedQuestionTypes: ['alD'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/MultiplicationAndDivisionA/6The36And9TimesTables" */ './6The36And9TimesTables'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'MultiplyAndDivideBy7',
      questionTypes: ['alF', 'alG', 'alH', 'alI', 'alJ', 'alK'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/MultiplicationAndDivisionA/7MultiplyAndDivideBy7" */ './7MultiplyAndDivideBy7'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'Num7TimesTableAndDivisionFacts',
      questionTypes: ['alL', 'alM', 'alN', 'alO', 'alP', 'alQ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/MultiplicationAndDivisionA/8Num7TimesTableAndDivisionFacts" */ './8Num7TimesTableAndDivisionFacts'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'Num11TimesTableAndDivisionFacts',
      questionTypes: ['alR', 'alS', 'alT', 'alU', 'alV', 'alW'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/MultiplicationAndDivisionA/9Num11TimesTableAndDivisionFacts" */ './9Num11TimesTableAndDivisionFacts'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'Num12TimesTableAndDivisionFacts',
      questionTypes: ['alX2', 'alY2', 'alZ', 'al0', 'al1', 'al2'],
      archivedQuestionTypes: ['alX', 'alY'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/MultiplicationAndDivisionA/10Num12TimesTableAndDivisionFacts" */ './10Num12TimesTableAndDivisionFacts'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'MultiplyBy1And0',
      questionTypes: ['al3', 'al4', 'al52', 'al6', 'al7', 'al8'],
      archivedQuestionTypes: ['al5'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/MultiplicationAndDivisionA/11MultiplyBy1And0" */ './11MultiplyBy1And0'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'DivideANumberBy1AndItself',
      questionTypes: ['al9', 'ama', 'amb', 'amc', 'amd', 'ame'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/MultiplicationAndDivisionA/12DivideANumberBy1AndItself" */ './12DivideANumberBy1AndItself'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'MultiplyThreeNumbers',
      questionTypes: ['amf', 'amg', 'amh', 'ami', 'amj', 'amk'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/MultiplicationAndDivisionA/13MultiplyThreeNumbers" */ './13MultiplyThreeNumbers'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
