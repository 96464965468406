import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Statistics',
  weeks: [10, 10],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'InterpretCharts',
      questionTypes: ['ayW', 'ayX', 'ayY', 'ayZ', 'ay0', 'ay1'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Statistics/1InterpretCharts" */ './1InterpretCharts'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'ComparisonSumAndDifference',
      questionTypes: ['ay2', 'ay3', 'ay4', 'ay5', 'ay6', 'ay72'],
      archivedQuestionTypes: ['ay7'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Statistics/2ComparisonSumAndDifference" */ './2ComparisonSumAndDifference'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'InterpretLineGraphs',
      questionTypes: ['ay8', 'ay9', 'aza', 'azb', 'azc', 'azd'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Statistics/3InterpretLineGraphs" */ './3InterpretLineGraphs'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'DrawLineGraphs',
      questionTypes: ['aze', 'azf', 'azg', 'azh', 'azi', 'azj'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Statistics/4DrawLineGraphs" */ './4DrawLineGraphs'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
