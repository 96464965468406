import { useState } from 'react';
import { useKeyboardHandler } from 'react-native-keyboard-controller';
import { runOnJS } from 'react-native-reanimated';

/**
 * Based on https://kirillzyusko.github.io/react-native-keyboard-controller/docs/api/hooks/keyboard/use-keyboard-handler.
 *
 * However, this library actually enables us to animated the components as the keyboard changes position, so it would
 * be nice if we migrated to using that.
 */
export default function useKeyboard() {
  const [keyboardShown, setKeyboardShown] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useKeyboardHandler(
    {
      onEnd: e => {
        'worklet';
        runOnJS(setKeyboardShown)(e.progress === 1);
        runOnJS(setKeyboardHeight)(e.height);
      }
    },
    []
  );

  return { keyboardShown, keyboardHeight };
}
