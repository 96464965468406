import { useCallback, useEffect, useRef } from 'react';
import { View, Platform } from 'react-native';
import LottieView from 'lottie-react-native';
import { useI18nContext } from '../../i18n/i18n-react';
import { useTheme } from '../../theme';
import { resolveFont } from '../../theme/fonts';
import Text from '../typography/Text';
import PngImage from '../../utils/pngImage';

type Props = {
  /** Callback called when lottie animation finish. */
  onAnimationFinish: () => void;
};

export function FullMarks({ onAnimationFinish: onAnimationFinishProp }: Props) {
  const theme = useTheme();
  const translate = useI18nContext().LL;
  const animation = useRef<LottieView>(null);

  // Play the animation after 100ms
  useEffect(() => {
    const timeout = setTimeout(() => {
      animation.current?.play();
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  const onAnimationFinish = useCallback(() => {
    // Pause when finishing the animation
    // https://github.com/lottie-react-native/lottie-react-native/issues/1200#issuecomment-2069427778
    Platform.OS !== 'web' && animation.current?.pause();
    onAnimationFinishProp();
  }, [onAnimationFinishProp]);

  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <PngImage
          source={require('../../assets/images/100ScreenBackground.png')}
          resizeMode="contain"
          style={{
            width: 1128,
            height: 600
          }}
        />
      </View>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute'
        }}
      >
        <LottieView
          ref={animation}
          loop={false}
          onAnimationFinish={onAnimationFinish}
          duration={2000}
          style={{
            width: 500,
            backgroundColor: 'transparent',
            aspectRatio: 1920 / 1080
          }}
          source={require('../../assets/lottie/100PercentStars.json')}
        />
        <Text
          style={resolveFont({
            fontFamily: 'Biotif',
            fontWeight: '900',
            fontSize: 150,
            lineHeight: 225,
            color: theme.colors.primary
          })}
        >
          {'100%'}
        </Text>
        <Text variant="WRN700">{translate.quiz.youGotAllStars()}</Text>
      </View>
    </View>
  );
}
