import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Shape',
  field: 'Geometry',
  weeks: [1, 3],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'MeasureAndClassifyAngles',
      questionTypes: ['aDY', 'aDZ', 'aD0', 'aD1', 'aD2', 'aD3'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Summer/Shape/1MeasureAndClassifyAngles" */ './1MeasureAndClassifyAngles'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'CalculateAngles',
      questionTypes: ['aD4', 'aD5', 'aD6', 'aD72', 'aD8', 'aD92'],
      archivedQuestionTypes: ['aD7', 'aD9'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Summer/Shape/2CalculateAngles" */ './2CalculateAngles'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'VerticallyOppositeAngles',
      questionTypes: ['aEa2', 'aEb2', 'aEc', 'aEd', 'aEe', 'aEf2'],
      archivedQuestionTypes: ['aEa', 'aEb', 'aEf'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Summer/Shape/3VerticallyOppositeAngles" */ './3VerticallyOppositeAngles'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'AnglesInATriangle',
      questionTypes: ['aEg2', 'aEh2', 'aEi', 'aEj', 'aEk', 'aEl'],
      archivedQuestionTypes: ['aEg', 'aEh'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Summer/Shape/4AnglesInATriangle" */ './4AnglesInATriangle'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'AnglesInATriangleSpecialCases',
      questionTypes: ['aEm2', 'aEo', 'aEn', 'aEp2', 'aEq', 'aEr'],
      archivedQuestionTypes: ['aEm', 'aEp'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Summer/Shape/5AnglesInATriangleSpecialCases" */ './5AnglesInATriangleSpecialCases'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'AnglesInATriangleMissingAngles',
      questionTypes: ['aEs2', 'aEx2', 'aEt', 'aEu', 'aEv', 'aEw2'],
      archivedQuestionTypes: ['aEs', 'aEx', 'aEw'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Summer/Shape/6AnglesInATriangleMissingAngles" */ './6AnglesInATriangleMissingAngles'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'AnglesInQuadrilaterals',
      questionTypes: ['aEy2', 'aEz', 'aEA', 'aEB', 'aEC', 'aED'],
      archivedQuestionTypes: ['aEy'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Summer/Shape/7AnglesInQuadrilaterals" */ './7AnglesInQuadrilaterals'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'AnglesInPolygons',
      questionTypes: ['aEE', 'aEF', 'aEG2', 'aEH2', 'aEI2', 'aEJ2'],
      archivedQuestionTypes: ['aEG', 'aEH', 'aEI', 'aEJ'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Summer/Shape/8AnglesInPolygons" */ './8AnglesInPolygons'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'Circles',
      questionTypes: ['aEK', 'aEL', 'aEM', 'aEN', 'aEO2', 'aEP'],
      archivedQuestionTypes: ['aEO'],
      module: () =>
        import(/* webpackChunkName: "Year6/Summer/Shape/9Circles" */ './9Circles').then(
          mod => mod.default
        )
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'DrawShapesAccurately',
      questionTypes: ['aEQ', 'aET2', 'aER', 'aES', 'aEU2', 'aEV2'],
      archivedQuestionTypes: ['aET', 'aEU', 'aEV'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Summer/Shape/10DrawShapesAccurately" */ './10DrawShapesAccurately'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'NetsOf3DShapes',
      questionTypes: ['aEW', 'aEX', 'aEY2', 'aEZ', 'aE0', 'aE1'],
      archivedQuestionTypes: ['aEY'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Summer/Shape/11NetsOf3DShapes" */ './11NetsOf3DShapes'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
