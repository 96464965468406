import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'FractionsDecimalsAndPercentages',
  field: 'Number',
  weeks: [7, 8],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'DecimalAndFractionEquivalents',
      questionTypes: ['aVA', 'aVB', 'aVC', 'aVD', 'aVE2', 'aVF2'],
      archivedQuestionTypes: ['aVE', 'aVF'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/FractionsDecimalsAndPercentages/1DecimalAndFractionEquivalents" */ './1DecimalAndFractionEquivalents'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'FractionsAsDivision',
      questionTypes: ['aVG', 'aVH', 'aVI', 'aVJ2', 'aVK', 'aVL'],
      archivedQuestionTypes: ['aVJ'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/FractionsDecimalsAndPercentages/2FractionsAsDivision" */ './2FractionsAsDivision'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'UnderstandPercentages',
      questionTypes: ['aVM', 'aVN2', 'aVO', 'aVP', 'aVQ', 'aVR'],
      archivedQuestionTypes: ['aVN'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/FractionsDecimalsAndPercentages/3UnderstandPercentages" */ './3UnderstandPercentages'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'FractionsToPercentages',
      questionTypes: ['aVS', 'aVT2', 'aVU2', 'aVV2', 'aVW', 'aVX'],
      archivedQuestionTypes: ['aVV', 'aVT', 'aVU'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/FractionsDecimalsAndPercentages/4FractionsToPercentages" */ './4FractionsToPercentages'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'EquivalentFractionsDecimalsAndPercentages',
      questionTypes: ['aVY', 'aVZ', 'aV0', 'aV1', 'aV2', 'aV32'],
      archivedQuestionTypes: ['aV3'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/FractionsDecimalsAndPercentages/5EquivalentFractionsDecimalsAndPercentages" */ './5EquivalentFractionsDecimalsAndPercentages'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'OrderFractionsDecimalsAndPercentages',
      questionTypes: ['aV4', 'aV5', 'aV6', 'aV7', 'aV8', 'aV9'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/FractionsDecimalsAndPercentages/6OrderFractionsDecimalsAndPercentages" */ './6OrderFractionsDecimalsAndPercentages'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'PercentageOfAnAmountOneStep',
      questionTypes: ['aWa', 'aWb', 'aWc2', 'aWd', 'aWe', 'aWf'],
      archivedQuestionTypes: ['aWc'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/FractionsDecimalsAndPercentages/7PercentageOfAnAmountOneStep" */ './7PercentageOfAnAmountOneStep'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'PercentageOfAnAmountMultiStep',
      questionTypes: ['aWg', 'aWh', 'aWi', 'aWj2', 'aWk2', 'aWl'],
      archivedQuestionTypes: ['aWj', 'aWk'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/FractionsDecimalsAndPercentages/8PercentageOfAnAmountMultiStep" */ './8PercentageOfAnAmountMultiStep'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'PercentagesMissingValues',
      questionTypes: ['aWm2', 'aWn', 'aWo', 'aWp', 'aWq2', 'aWr2'],
      archivedQuestionTypes: ['aWm', 'aWq', 'aWr'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/FractionsDecimalsAndPercentages/9PercentagesMissingValues" */ './9PercentagesMissingValues'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
