/** This file contains react-native-renanimated wrappings of components. */
import { Pressable } from 'react-native';
import Animated from 'react-native-reanimated';
import { Svg, Path, Rect, Line } from 'react-native-svg';

/** Workaround for https://github.com/software-mansion/react-native-svg/issues/1484 only. */
class SvgWorkaround extends Svg {
  public override render() {
    const newProps = {
      ...this.props,
      collapsable: undefined
    };
    return <Svg {...newProps} />;
  }
}

export const AnimatedPressable = Animated.createAnimatedComponent(Pressable);
export const AnimatedSvg = Animated.createAnimatedComponent(SvgWorkaround);
export const AnimatedPath = Animated.createAnimatedComponent(Path);
export const AnimatedRect = Animated.createAnimatedComponent(Rect);
export const AnimatedLine = Animated.createAnimatedComponent(Line);
