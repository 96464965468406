import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'DecimalsAndPercentages',
  field: 'Number',
  weeks: [6, 8],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'DecimalsUpTo2DecimalPlaces',
      questionTypes: ['aQg2', 'aQh', 'aQi2', 'aQj', 'aQk', 'aQl2'],
      archivedQuestionTypes: ['aQg', 'aQi', 'aQl'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/DecimalsAndPercentages/1DecimalsUpTo2DecimalPlaces" */ './1DecimalsUpTo2DecimalPlaces'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'EquivalentFractionsAndDecimalsTenths',
      questionTypes: ['aQm', 'aQn', 'aQo', 'aQp', 'aQq', 'aQr'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/DecimalsAndPercentages/2EquivalentFractionsAndDecimalsTenths" */ './2EquivalentFractionsAndDecimalsTenths'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'EquivalentFractionsAndDecimalsHundredths',
      questionTypes: ['aQs', 'aQt', 'aQu', 'aQv2', 'aQw2', 'aQx'],
      archivedQuestionTypes: ['aQv', 'aQw'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/DecimalsAndPercentages/3EquivalentFractionsAndDecimalsHundredths" */ './3EquivalentFractionsAndDecimalsHundredths'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'EquivalentFractionsAndDecimals',
      questionTypes: ['aQy', 'aQz', 'aQA2', 'aQB2', 'aQC', 'aQD2'],
      archivedQuestionTypes: ['aQA', 'aQB', 'aQD'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/DecimalsAndPercentages/4EquivalentFractionsAndDecimals" */ './4EquivalentFractionsAndDecimals'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'ThousandthsAsFractions',
      questionTypes: ['aQE2', 'aQF', 'aQG', 'aQH', 'aQI', 'aQJ'],
      archivedQuestionTypes: ['aQE'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/DecimalsAndPercentages/5ThousandthsAsFractions" */ './5ThousandthsAsFractions'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'ThousandthsAsDecimals',
      questionTypes: ['aQK', 'aQL', 'aQM', 'aQN', 'aQO', 'aQP'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/DecimalsAndPercentages/6ThousandthsAsDecimals" */ './6ThousandthsAsDecimals'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'ThousandthsOnAPlaceValueChart',
      questionTypes: ['aQQ2', 'aQR', 'aQS', 'aQT', 'aQU', 'aQV2'],
      archivedQuestionTypes: ['aQQ', 'aQV'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/DecimalsAndPercentages/7ThousandthsOnAPlaceValueChart" */ './7ThousandthsOnAPlaceValueChart'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'OrderAndCompareDecimalsSameNumberOfDecimalPlaces',
      questionTypes: ['aQW', 'aQX', 'aQY2', 'aQZ', 'aQ0', 'aQ1'],
      archivedQuestionTypes: ['aQY'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/DecimalsAndPercentages/8OrderAndCompareDecimalsSameNumberOfDecimalPlaces" */ './8OrderAndCompareDecimalsSameNumberOfDecimalPlaces'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'OrderAndCompareAnyDecimalsWithUpTo3DecimalPlaces',
      questionTypes: ['aQ2', 'aQ3', 'aQ4', 'aQ5', 'aQ6', 'aQ7'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/DecimalsAndPercentages/9OrderAndCompareAnyDecimalsWithUpTo3DecimalPlaces" */ './9OrderAndCompareAnyDecimalsWithUpTo3DecimalPlaces'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'RoundToTheNearestWholeNumber',
      questionTypes: ['aQ8', 'aQ9', 'aRa2', 'aRb', 'aRc2', 'aRd'],
      archivedQuestionTypes: ['aRa', 'aRc'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/DecimalsAndPercentages/10RoundToTheNearestWholeNumber" */ './10RoundToTheNearestWholeNumber'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'RoundTo1DecimalPlace',
      questionTypes: ['aRe', 'aRf', 'aRg', 'aRh2', 'aRi2', 'aRj'],
      archivedQuestionTypes: ['aRh', 'aRi'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/DecimalsAndPercentages/11RoundTo1DecimalPlace" */ './11RoundTo1DecimalPlace'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'UnderstandPercentages',
      questionTypes: ['aRk', 'aRl', 'aRm', 'aRn', 'aRo', 'aRp'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/DecimalsAndPercentages/12UnderstandPercentages" */ './12UnderstandPercentages'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'PercentagesAsFractions',
      questionTypes: ['aRq', 'aRr2', 'aRs', 'aRt2', 'aRu', 'aRv'],
      archivedQuestionTypes: ['aRr', 'aRt'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/DecimalsAndPercentages/13PercentagesAsFractions" */ './13PercentagesAsFractions'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'PercentagesAsDecimals',
      questionTypes: ['aRw', 'aRx', 'aRy', 'aRz2', 'aRA2', 'aRB'],
      archivedQuestionTypes: ['aRz', 'aRA'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/DecimalsAndPercentages/14PercentagesAsDecimals" */ './14PercentagesAsDecimals'
        ).then(mod => mod.default)
    }),
    // Step 15
    newSmallStepContent({
      smallStep: 'EquivalentFractionsDecimalsAndPercentages',
      questionTypes: ['aRC', 'aRD2', 'aRE', 'aRF', 'aRG', 'aRH'],
      archivedQuestionTypes: ['aRD'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/DecimalsAndPercentages/15EquivalentFractionsDecimalsAndPercentages" */ './15EquivalentFractionsDecimalsAndPercentages'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
