import { Button } from 'react-native-paper';
import { useTheme } from 'common/theme';
import useBreakpoints from '../hooks/useBreakpoints';
import { ComponentProps } from 'react';
import { resolveFont } from 'common/theme/fonts';

export default function FilledButton({
  style,
  iconOnRight = false,
  buttonWidth,
  testID,
  ...props
}: Pick<ComponentProps<typeof Button>, 'icon' | 'onPress' | 'children' | 'style'> & {
  iconOnRight?: boolean;
  buttonWidth?: number;
  testID?: string;
}) {
  const theme = useTheme();
  const { resize } = useBreakpoints();

  return (
    <Button
      mode="contained-tonal"
      contentStyle={{
        width: buttonWidth ?? 377 * resize,
        height: 96 * resize,
        gap: 5 * resize,
        paddingHorizontal: 5 * resize,
        paddingVertical: 0,
        flexDirection: iconOnRight ? 'row-reverse' : 'row'
      }}
      labelStyle={resolveFont({
        fontFamily: 'Biotif',
        fontWeight: '700',
        fontSize: 37 * resize,
        lineHeight: 55.5 * resize,
        color: theme.colors.primary,
        flex: 1,
        marginHorizontal: 24 * resize,
        marginTop: 8 * resize
      })}
      textColor={theme.colors.primary}
      style={[{ borderRadius: 999, borderColor: theme.colors.primary, borderWidth: 2 }, style]}
      maxFontSizeMultiplier={1}
      testID={testID}
      {...props}
    />
  );
}
