import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'FractionsB',
  field: 'Number',
  weeks: [4, 5],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'MultiplyAUnitFractionByAnInteger',
      questionTypes: ['aPA', 'aPB2', 'aPC2', 'aPD', 'aPE', 'aPF2'],
      archivedQuestionTypes: ['aPB', 'aPC', 'aPF'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/FractionsB/1MultiplyAUnitFractionByAnInteger" */ './1MultiplyAUnitFractionByAnInteger'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'MultiplyANonUnitFractionByAnInteger',
      questionTypes: ['aPG', 'aPH', 'aPI2', 'aPJ', 'aPK2', 'aPL'],
      archivedQuestionTypes: ['aPI', 'aPK'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/FractionsB/2MultiplyANonUnitFractionByAnInteger" */ './2MultiplyANonUnitFractionByAnInteger'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'MultiplyAMixedNumberByAnInteger',
      questionTypes: ['aPM2', 'aPN2', 'aPO2', 'aPP', 'aPQ2', 'aPR'],
      archivedQuestionTypes: ['aPM', 'aPN', 'aPO', 'aPQ'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/FractionsB/3MultiplyAMixedNumberByAnInteger" */ './3MultiplyAMixedNumberByAnInteger'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'CalculateAFractionOfAQuantity',
      questionTypes: ['aPS2', 'aPT2', 'aPU', 'aPV', 'aPW2', 'aPX2'],
      archivedQuestionTypes: ['aPS', 'aPT', 'aPW', 'aPX'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/FractionsB/4CalculateAFractionOfAQuantity" */ './4CalculateAFractionOfAQuantity'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'FractionOfAnAmount',
      questionTypes: ['aPY2', 'aPZ2', 'aP0', 'aP12', 'aP22', 'aP3'],
      archivedQuestionTypes: ['aPY', 'aPZ', 'aP1', 'aP2'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/FractionsB/5FractionOfAnAmount" */ './5FractionOfAnAmount'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'FindTheWhole',
      questionTypes: ['aP42', 'aP5', 'aP6', 'aP7', 'aP82', 'aP92'],
      archivedQuestionTypes: ['aP4', 'aP8', 'aP9'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/FractionsB/6FindTheWhole" */ './6FindTheWhole'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'UseFractionsAsOperators',
      questionTypes: ['aQa', 'aQb', 'aQc', 'aQd', 'aQe2', 'aQf'],
      archivedQuestionTypes: ['aQe'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/FractionsB/7UseFractionsAsOperators" */ './7UseFractionsAsOperators'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
