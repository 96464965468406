import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'AdditionAndSubtraction',
  field: 'Number',
  weeks: [4, 8],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'ApplyNumberBondsWithin10',
      questionTypes: ['afG', 'afH', 'afI', 'afJ', 'afK', 'afL'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/1ApplyNumberBondsWithin10" */ './1ApplyNumberBondsWithin10'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'AddAndSubtract1s',
      questionTypes: ['afM', 'afN', 'afO', 'afP', 'afQ', 'afR'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/2AddAndSubtract1s" */ './2AddAndSubtract1s'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'AddAndSubtract10s',
      questionTypes: ['afS', 'afT', 'afU', 'afV', 'afW', 'afX2'],
      archivedQuestionTypes: ['afX'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/3AddAndSubtract10s" */ './3AddAndSubtract10s'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'AddAndSubtract100s',
      questionTypes: ['afY', 'afZ', 'af0', 'af1', 'af2', 'af32'],
      archivedQuestionTypes: ['af3'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/4AddAndSubtract100s" */ './4AddAndSubtract100s'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'SpotThePattern',
      questionTypes: ['af4', 'af5', 'af62', 'af7', 'af8', 'af9'],
      archivedQuestionTypes: ['af6'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/5SpotThePattern" */ './5SpotThePattern'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'Add1sAcrossA10',
      questionTypes: ['aga', 'agb', 'agc', 'age2', 'agd', 'agf2'],
      archivedQuestionTypes: ['age', 'agf'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/6Add1sAcrossA10" */ './6Add1sAcrossA10'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'Add10sAcrossA100',
      questionTypes: ['agg3', 'agh', 'agi', 'agj', 'agl', 'agk'],
      archivedQuestionTypes: ['agg', 'agg2'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/7Add10sAcrossA100" */ './7Add10sAcrossA100'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'Subtract1sAcrossA10',
      questionTypes: ['agm', 'agn', 'ago', 'agp', 'agq', 'agr2'],
      archivedQuestionTypes: ['agr'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/8Subtract1sAcrossA10" */ './8Subtract1sAcrossA10'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'Subtract10sAcrossA100',
      questionTypes: ['ags2', 'agt', 'agu', 'agv', 'agw', 'agx2'],
      archivedQuestionTypes: ['ags', 'agx'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/9Subtract10sAcrossA100" */ './9Subtract10sAcrossA100'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'MakeConnections',
      questionTypes: ['agy', 'agz', 'agA', 'agB', 'agC', 'agD'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/10MakeConnections" */ './10MakeConnections'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'AddTwoNumbersNoExchange',
      questionTypes: ['agE', 'agF', 'agG', 'agH', 'agI', 'agJ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/11AddTwoNumbersNoExchange" */ './11AddTwoNumbersNoExchange'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'SubtractTwoNumbersNoExchange',
      questionTypes: ['agK2', 'agL', 'agM', 'agN', 'agO', 'agP'],
      archivedQuestionTypes: ['agK'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/12SubtractTwoNumbersNoExchange" */ './12SubtractTwoNumbersNoExchange'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'AddTwoNumbersAcrossA10',
      questionTypes: ['agQ', 'agR', 'agS', 'agT2', 'agU', 'agV'],
      archivedQuestionTypes: ['agT'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/13AddTwoNumbersAcrossA10" */ './13AddTwoNumbersAcrossA10'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'AddTwoNumbersAcrossA100',
      questionTypes: ['agW', 'agX', 'agY2', 'agZ', 'ag0', 'ag1'],
      archivedQuestionTypes: ['agY'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/14AddTwoNumbersAcrossA100" */ './14AddTwoNumbersAcrossA100'
        ).then(mod => mod.default)
    }),
    // Step 15
    newSmallStepContent({
      smallStep: 'SubtractTwoNumbersAcrossA10',
      questionTypes: ['ag2', 'ag32', 'ag4', 'ag5', 'ag6', 'ag7'],
      archivedQuestionTypes: ['ag3'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/15SubtractTwoNumbersAcrossA10" */ './15SubtractTwoNumbersAcrossA10'
        ).then(mod => mod.default)
    }),
    // Step 16
    newSmallStepContent({
      smallStep: 'SubtractTwoNumbersAcrossA100',
      questionTypes: ['ag82', 'ag9', 'aha', 'ahb', 'ahc', 'ahd'],
      archivedQuestionTypes: ['ag8'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/16SubtractTwoNumbersAcrossA100" */ './16SubtractTwoNumbersAcrossA100'
        ).then(mod => mod.default)
    }),
    // Step 17
    newSmallStepContent({
      smallStep: 'Add2DigitAnd3DigitNumbers',
      questionTypes: ['ahe', 'ahf', 'ahg', 'ahh', 'ahi', 'ahj'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/17Add2DigitAnd3DigitNumbers" */ './17Add2DigitAnd3DigitNumbers'
        ).then(mod => mod.default)
    }),
    // Step 18
    newSmallStepContent({
      smallStep: 'SubtractA2DigitNumberFromA3DigitNumber',
      questionTypes: ['ahk', 'ahl', 'ahm', 'ahn', 'aho', 'ahp'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/18SubtractA2DigitNumberFromA3DigitNumber" */ './18SubtractA2DigitNumberFromA3DigitNumber'
        ).then(mod => mod.default)
    }),
    // Step 19
    newSmallStepContent({
      smallStep: 'ComplementsTo100',
      questionTypes: ['ahq', 'ahr', 'ahs', 'aht', 'ahu', 'ahv'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/19ComplementsTo100" */ './19ComplementsTo100'
        ).then(mod => mod.default)
    }),
    // Step 20
    newSmallStepContent({
      smallStep: 'EstimateAnswers',
      questionTypes: ['ahw', 'ahx', 'ahy', 'ahz', 'ahA', 'ahB'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/20EstimateAnswers" */ './20EstimateAnswers'
        ).then(mod => mod.default)
    }),
    // Step 21
    newSmallStepContent({
      smallStep: 'InverseOperations',
      questionTypes: ['ahC', 'ahD', 'ahE', 'ahF', 'ahG', 'ahH'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/21InverseOperations" */ './21InverseOperations'
        ).then(mod => mod.default)
    }),
    // Step 22
    newSmallStepContent({
      smallStep: 'MakeDecisions',
      questionTypes: ['ahI', 'ahJ', 'ahK', 'ahL', 'ahM2', 'ahN2'],
      archivedQuestionTypes: ['ahM', 'ahN'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/AdditionAndSubtraction/22MakeDecisions" */ './22MakeDecisions'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
