import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Statistics',
  weeks: [11, 12],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'LineGraphs',
      questionTypes: ['aXe', 'aXf', 'aXg', 'aXh2', 'aXi', 'aXj'],
      archivedQuestionTypes: ['aXh'],
      module: () =>
        import(/* webpackChunkName: "Year6/Spring/Statistics/1LineGraphs" */ './1LineGraphs').then(
          mod => mod.default
        )
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'DualBarCharts',
      questionTypes: ['aXk', 'aXl', 'aXm2', 'aXn2', 'aXo', 'aXp2'],
      archivedQuestionTypes: ['aXm', 'aXn', 'aXp'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Statistics/2DualBarCharts" */ './2DualBarCharts'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'ReadAndInterpretPieCharts',
      questionTypes: ['aXq', 'aXr', 'aXs', 'aXt', 'aXu', 'aXv'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Statistics/3ReadAndInterpretPieCharts" */ './3ReadAndInterpretPieCharts'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'PieChartsWithPercentages',
      questionTypes: ['aXw', 'aXx', 'aXy', 'aXz', 'aXA', 'aXB'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Statistics/4PieChartsWithPercentages" */ './4PieChartsWithPercentages'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'DrawPieCharts',
      questionTypes: ['aXC', 'aXD', 'aXE', 'aXF', 'aXG', 'aXH'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Statistics/5DrawPieCharts" */ './5DrawPieCharts'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'TheMean',
      questionTypes: ['aXI', 'aXJ', 'aXK', 'aXL', 'aXM', 'aXN'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year6/Spring/Statistics/6TheMean" */ './6TheMean').then(
          mod => mod.default
        )
    })
  ]
});
export default Block;
