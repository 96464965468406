import { View } from 'react-native';
import { useTheme } from 'common/theme';
import Button from 'common/components/atoms/Button';
import ModalCard from 'common/components/modals/ModalCard';
import { useI18nContext } from '../i18n/i18n-react';
import TextStructure from 'common/components/molecules/TextStructure';

/**
 * Simple modal which simply shows a translate.errorModals.cantLogin(), some text, a confirm button and a cancel button.
 */
export default function CantLoginModal({
  onDismiss,
  onConfirm
}: {
  onDismiss: () => void;
  onConfirm: () => void;
}) {
  const theme = useTheme();
  const translate = useI18nContext().LL;

  return (
    <ModalCard
      onDismiss={onDismiss}
      containerStyle={{ alignItems: 'center', rowGap: 8, width: 708 }}
    >
      <TextStructure
        sentence={translate.errorModals.cantLoginPromptText()}
        textVariant="WRN400"
        textStyle={{ fontSize: 32, lineHeight: 48, textAlign: 'center' }}
      />

      <View style={{ flexDirection: 'column', rowGap: 40, marginTop: 32 }}>
        <Button
          baseColor={theme.colors.secondaryContainer}
          onPress={onConfirm}
          style={{ width: 580, height: 96 }}
          testId="CANT_LOGIN_CONTINUE_BTN"
        >
          {translate.misc.continue()}
        </Button>
        <Button
          baseColor={theme.colors.background}
          onPress={onDismiss}
          style={{ width: 580, height: 96 }}
          testId="CANT_LOGIN_CANCEL_BTN"
        >
          {translate.misc.cancel()}
        </Button>
      </View>
    </ModalCard>
  );
}
