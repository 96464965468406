import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'PositionAndDirection',
  field: 'Geometry',
  weeks: [9, 10],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'LanguageOfPosition',
      questionTypes: ['blx', 'bly', 'blz'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/PositionAndDirection/1LanguageOfPosition" */ './1LanguageOfPosition'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'DescribeMovement',
      questionTypes: ['blA', 'blB', 'blC'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/PositionAndDirection/2DescribeMovement" */ './2DescribeMovement'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'DescribeTurns',
      questionTypes: ['blD', 'blE', 'blF'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/PositionAndDirection/3DescribeTurns" */ './3DescribeTurns'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'DescribeMovementAndTurns',
      questionTypes: ['blG', 'blH', 'blI'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/PositionAndDirection/4DescribeMovementAndTurns" */ './4DescribeMovementAndTurns'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'ShapePatternsWithTurns',
      questionTypes: ['blJ', 'blK', 'blL'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/PositionAndDirection/5ShapePatternsWithTurns" */ './5ShapePatternsWithTurns'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
