import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'MultiplicationAndDivisionB',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'MultiplyUpToA4DigitNumberByA1DigitNumber',
      questionTypes: ['aOC2', 'aOD', 'aOE', 'aOF', 'aOG', 'aOH'],
      archivedQuestionTypes: ['aOC'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/MultiplicationAndDivisionB/1MultiplyUpToA4DigitNumberByA1DigitNumber" */ './1MultiplyUpToA4DigitNumberByA1DigitNumber'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'MultiplyA2DigitNumberByA2DigitNumberAreaModel',
      questionTypes: ['aZ02', 'aZ32', 'aZ4', 'aZ5', 'aZ22', 'aZ12'],
      archivedQuestionTypes: ['aZ0', 'aZ3', 'aZ2', 'aZ1'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/MultiplicationAndDivisionB/2MultiplyA2DigitNumberByA2DigitNumberAreaModel" */ './2MultiplyA2DigitNumberByA2DigitNumberAreaModel'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'MultiplyA2DigitNumberByA2DigitNumber',
      questionTypes: ['aOI', 'aOJ', 'aOK2', 'aOL2', 'aOM', 'aON'],
      archivedQuestionTypes: ['aOK', 'aOL'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/MultiplicationAndDivisionB/3MultiplyA2DigitNumberByA2DigitNumber" */ './3MultiplyA2DigitNumberByA2DigitNumber'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'MultiplyA3DigitNumberByA2DigitNumber',
      questionTypes: ['aOO2', 'aOP2', 'aOQ2', 'aOR2', 'aOS', 'aOT'],
      archivedQuestionTypes: ['aOO', 'aOP', 'aOQ', 'aOR'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/MultiplicationAndDivisionB/4MultiplyA3DigitNumberByA2DigitNumber" */ './4MultiplyA3DigitNumberByA2DigitNumber'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'MultiplyA4DigitNumberByA2DigitNumber',
      questionTypes: ['aOU2', 'aOV2', 'aOW2', 'aOX', 'aOY', 'aOZ'],
      archivedQuestionTypes: ['aOU', 'aOV', 'aOW'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/MultiplicationAndDivisionB/5MultiplyA4DigitNumberByA2DigitNumber" */ './5MultiplyA4DigitNumberByA2DigitNumber'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'SolveProblemsWithMultiplication',
      questionTypes: ['aO02', 'aO1', 'aO22', 'aO3', 'aO4', 'aO5'],
      archivedQuestionTypes: ['aO0', 'aO2'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/MultiplicationAndDivisionB/6SolveProblemsWithMultiplication" */ './6SolveProblemsWithMultiplication'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'ShortDivision',
      questionTypes: ['aO6', 'aO7', 'aO8', 'aO9', 'aPa', 'aPb'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/MultiplicationAndDivisionB/7ShortDivision" */ './7ShortDivision'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'DivideA4DigitNumberByA1DigitNumber',
      questionTypes: ['aPc', 'aPd', 'aPe', 'aPf', 'aPg', 'aPh'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/MultiplicationAndDivisionB/8DivideA4DigitNumberByA1DigitNumber" */ './8DivideA4DigitNumberByA1DigitNumber'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'DivideWithRemainders',
      questionTypes: ['aPi', 'aPj', 'aPk', 'aPl', 'aPm', 'aPn'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/MultiplicationAndDivisionB/9DivideWithRemainders" */ './9DivideWithRemainders'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'EfficientDivision',
      questionTypes: ['aPo', 'aPp2', 'aPq2', 'aPr', 'aPs2', 'aPt'],
      archivedQuestionTypes: ['aPp', 'aPq', 'aPs'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/MultiplicationAndDivisionB/10EfficientDivision" */ './10EfficientDivision'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'SolveProblemsWithMultiplicationAndDivision',
      questionTypes: ['aPu', 'aPv', 'aPw', 'aPx', 'aPy', 'aPz'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/MultiplicationAndDivisionB/11SolveProblemsWithMultiplicationAndDivision" */ './11SolveProblemsWithMultiplicationAndDivision'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
