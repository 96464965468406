import Svg, { SvgProps, Circle } from 'react-native-svg';
import { memo } from 'react';
import { colors } from '../../../theme/colors';
const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 38 38" {...props}>
    <Circle cx={18} cy={18} r={17} stroke={colors.black} strokeWidth={2} />
  </Svg>
);
const Memo = memo(SvgComponent);
export default Memo;
