import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Shape',
  field: 'Geometry',
  weeks: [8, 9],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'TurnsAndAngles',
      questionTypes: ['auM', 'auN', 'auO', 'auP', 'auQ', 'auR'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Shape/1TurnsAndAngles" */ './1TurnsAndAngles'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'RightAngles',
      questionTypes: ['auS', 'auT', 'auU', 'auV2', 'auW2', 'auX'],
      archivedQuestionTypes: ['auV', 'auW'],
      module: () =>
        import(/* webpackChunkName: "Year3/Summer/Shape/2RightAngles" */ './2RightAngles').then(
          mod => mod.default
        )
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'CompareAngles',
      questionTypes: ['auY', 'auZ', 'au0', 'au1', 'au22', 'au3'],
      archivedQuestionTypes: ['au2'],
      module: () =>
        import(/* webpackChunkName: "Year3/Summer/Shape/3CompareAngles" */ './3CompareAngles').then(
          mod => mod.default
        )
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'MeasureAndDrawAccurately',
      questionTypes: ['au4', 'au5', 'au6', 'au7', 'au82', 'au92'],
      archivedQuestionTypes: ['au8', 'au9'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Shape/4MeasureAndDrawAccurately" */ './4MeasureAndDrawAccurately'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'HorizontalAndVertical',
      questionTypes: ['ava', 'avb', 'avc', 'avd', 'ave', 'avf'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Shape/5HorizontalAndVertical" */ './5HorizontalAndVertical'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'ParallelAndPerpendicular',
      questionTypes: ['avg', 'avh', 'avi', 'avj', 'avk', 'avl'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Shape/6ParallelAndPerpendicular" */ './6ParallelAndPerpendicular'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'RecogniseAndDescribe2DShapes',
      questionTypes: ['avm2', 'avn', 'avo2', 'avp2', 'avq', 'avr2'],
      archivedQuestionTypes: ['avm', 'avo', 'avp', 'avr'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Shape/7RecogniseAndDescribe2DShapes" */ './7RecogniseAndDescribe2DShapes'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'DrawPolygons',
      questionTypes: ['avs', 'avt', 'avu', 'avv', 'avw2', 'avx2'],
      archivedQuestionTypes: ['avw', 'avx'],
      module: () =>
        import(/* webpackChunkName: "Year3/Summer/Shape/8DrawPolygons" */ './8DrawPolygons').then(
          mod => mod.default
        )
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'RecogniseAndDescribe3DShapes',
      questionTypes: ['avy', 'avz2', 'avA', 'avB2', 'avC2', 'avD'],
      archivedQuestionTypes: ['avC', 'avz', 'avB'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Shape/9RecogniseAndDescribe3DShapes" */ './9RecogniseAndDescribe3DShapes'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'Make3DShapes',
      questionTypes: ['avE', 'avF', 'avG', 'avH', 'avI', 'avJ'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year3/Summer/Shape/10Make3DShapes" */ './10Make3DShapes').then(
          mod => mod.default
        )
    })
  ]
});
export default Block;
