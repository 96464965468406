import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'FractionsA',
  field: 'Number',
  weeks: [7, 9],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'UnderstandTheDenominatorsOfUnitFractions',
      questionTypes: ['aHK', 'aHL', 'aHM', 'aHN', 'aHO', 'aHP2'],
      archivedQuestionTypes: ['aHP'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/FractionsA/1UnderstandTheDenominatorsOfUnitFractions" */ './1UnderstandTheDenominatorsOfUnitFractions'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'CompareAndOrderUnitFractions',
      questionTypes: ['aHQ', 'aHR', 'aHS', 'aHT2', 'aHU', 'aHV'],
      archivedQuestionTypes: ['aHT'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/FractionsA/2CompareAndOrderUnitFractions" */ './2CompareAndOrderUnitFractions'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'UnderstandTheNumeratorsOfNonUnitFractions',
      questionTypes: ['aHW', 'aHX', 'aHY', 'aHZ', 'aH0', 'aH12'],
      archivedQuestionTypes: ['aH1'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/FractionsA/3UnderstandTheNumeratorsOfNonUnitFractions" */ './3UnderstandTheNumeratorsOfNonUnitFractions'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'UnderstandTheWhole',
      questionTypes: ['aH2', 'aH3', 'aH4', 'aH5', 'aH62', 'aH7'],
      archivedQuestionTypes: ['aH6'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/FractionsA/4UnderstandTheWhole" */ './4UnderstandTheWhole'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'CompareAndOrderNonUnitFractions',
      questionTypes: ['aH8', 'aH9', 'aIa', 'aIb2', 'aIc2', 'aId'],
      archivedQuestionTypes: ['aIc', 'aIb'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/FractionsA/5CompareAndOrderNonUnitFractions" */ './5CompareAndOrderNonUnitFractions'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'FractionsAndScales',
      questionTypes: ['aIe', 'aIf', 'aIg', 'aIh2', 'aIi', 'aIj'],
      archivedQuestionTypes: ['aIh'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/FractionsA/6FractionsAndScales" */ './6FractionsAndScales'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'FractionsOnANumberLine',
      questionTypes: ['aIk', 'aIl', 'aIm', 'aIn', 'aIo', 'aIp'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/FractionsA/7FractionsOnANumberLine" */ './7FractionsOnANumberLine'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'CountInFractionsOnANumberLine',
      questionTypes: ['aIq', 'aIr', 'aIs', 'aIt', 'aIu', 'aIv2'],
      archivedQuestionTypes: ['aIv'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/FractionsA/8CountInFractionsOnANumberLine" */ './8CountInFractionsOnANumberLine'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'EquivalentFractionsOnANumberLine',
      questionTypes: ['aIw', 'aIx', 'aIy2', 'aIz', 'aIA2', 'aIB'],
      archivedQuestionTypes: ['aIy', 'aIA'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/FractionsA/9EquivalentFractionsOnANumberLine" */ './9EquivalentFractionsOnANumberLine'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'EquivalentFractionsAsBarModels',
      questionTypes: ['aIC2', 'aID', 'aIE', 'aIF', 'aIG', 'aIH'],
      archivedQuestionTypes: ['aIC'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/FractionsA/10EquivalentFractionsAsBarModels" */ './10EquivalentFractionsAsBarModels'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
