import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Decimals',
  field: 'Number',
  weeks: [6, 8],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'UseKnownFactsToAddAndSubtractDecimalsWithin1',
      questionTypes: ['aBm', 'aBn', 'aBo', 'aBp', 'aBq', 'aBr'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Decimals/1UseKnownFactsToAddAndSubtractDecimalsWithin1" */ './1UseKnownFactsToAddAndSubtractDecimalsWithin1'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'ComplementsTo1',
      questionTypes: ['aBs', 'aBt', 'aBu', 'aBv', 'aBw', 'aBx'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Decimals/2ComplementsTo1" */ './2ComplementsTo1'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'AddAndSubtractDecimalsAcross1',
      questionTypes: ['aBy', 'aBz', 'aBA', 'aBB', 'aBC', 'aBD'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Decimals/3AddAndSubtractDecimalsAcross1" */ './3AddAndSubtractDecimalsAcross1'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'AddDecimalsWithTheSameNumberOfDecimalPlaces',
      questionTypes: ['aBE', 'aBF2', 'aBG', 'aBH', 'aBI', 'aBJ'],
      archivedQuestionTypes: ['aBF'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Decimals/4AddDecimalsWithTheSameNumberOfDecimalPlaces" */ './4AddDecimalsWithTheSameNumberOfDecimalPlaces'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'SubtractDecimalsWithTheSameNumberOfDecimalPlaces',
      questionTypes: ['aBK', 'aBL', 'aBM', 'aBN2', 'aBO', 'aBP'],
      archivedQuestionTypes: ['aBN'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Decimals/5SubtractDecimalsWithTheSameNumberOfDecimalPlaces" */ './5SubtractDecimalsWithTheSameNumberOfDecimalPlaces'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'AddDecimalsWithDifferentNumbersOfDecimalPlaces',
      questionTypes: ['aBQ', 'aBR2', 'aBS', 'aBT', 'aBU', 'aBV'],
      archivedQuestionTypes: ['aBR'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Decimals/6AddDecimalsWithDifferentNumbersOfDecimalPlaces" */ './6AddDecimalsWithDifferentNumbersOfDecimalPlaces'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'SubtractDecimalsWithDifferentNumbersOfDecimalPlaces',
      questionTypes: ['aBW', 'aBX', 'aBY', 'aBZ', 'aB0', 'aB1'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Decimals/7SubtractDecimalsWithDifferentNumbersOfDecimalPlaces" */ './7SubtractDecimalsWithDifferentNumbersOfDecimalPlaces'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'EfficientStrategiesForAddingAndSubtractingDecimals',
      questionTypes: ['aB2', 'aB3', 'aB4', 'aB5', 'aB6', 'aB7'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Decimals/8EfficientStrategiesForAddingAndSubtractingDecimals" */ './8EfficientStrategiesForAddingAndSubtractingDecimals'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'DecimalSequences',
      questionTypes: ['aB8', 'aB9', 'aCa', 'aCb', 'aCc', 'aCd'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Decimals/9DecimalSequences" */ './9DecimalSequences'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'MultiplyBy10100And1000',
      questionTypes: ['aCe', 'aCf', 'aCg', 'aCh', 'aCi', 'aCj'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Decimals/10MultiplyBy10100And1000" */ './10MultiplyBy10100And1000'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'DivideBy10100And1000',
      questionTypes: ['aCk', 'aCl', 'aCm', 'aCn', 'aCo', 'aCp'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Decimals/11DivideBy10100And1000" */ './11DivideBy10100And1000'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'MultiplyAndDivideDecimalsMissingValues',
      questionTypes: ['aCq', 'aCr', 'aCs2', 'aCt2', 'aCu2', 'aCv'],
      archivedQuestionTypes: ['aCs', 'aCt', 'aCu'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Decimals/12MultiplyAndDivideDecimalsMissingValues" */ './12MultiplyAndDivideDecimalsMissingValues'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
