import { createContext, type PropsWithChildren, useContext, useId, useMemo } from 'react';
import { I18nManager, Platform, useWindowDimensions, View, StyleSheet } from 'react-native';
import { Portal } from '../components/portal';
import { type AnimatedRef, useAnimatedRef } from 'react-native-reanimated';
import {
  type EdgeInsets,
  useSafeAreaFrame,
  useSafeAreaInsets
} from 'react-native-safe-area-context';
import * as math from 'mathjs';

export type Dimens = { width: number; height: number };

// Some things give left/right, some give start/end and some give all. Some utilities to go between.
export type Insets = EdgeInsets & { start: number; end: number };
export function start({ left, right }: { left: number; right: number }): number {
  return I18nManager.isRTL ? right : left;
}
export function end({ left, right }: { left: number; right: number }): number {
  return I18nManager.isRTL ? left : right;
}
/** Augments object with start/end values. */
export function withRtl<T extends { left: number; right: number }>(
  quantities: T
): T & { start: number; end: number } {
  return { ...quantities, start: start(quantities), end: end(quantities) };
}
/** Updates a single direction on an object, automatically updating other dependent directions, returning new object */
export function updateRtl<T extends { left: number; right: number; start: number; end: number }>(
  quantities: T,
  direction: 'left' | 'right' | 'start' | 'end',
  newValue: number
) {
  const newQuantities = { ...quantities, [direction]: newValue };
  if (direction === 'left' || direction === 'right') {
    return withRtl(newQuantities);
  } else {
    newQuantities.left = I18nManager.isRTL ? newQuantities.end : newQuantities.start;
    newQuantities.right = I18nManager.isRTL ? newQuantities.start : newQuantities.end;
    return newQuantities;
  }
}

/**
 * For native, we use useSafeAreaFrame. This is roughly equivalent to useWindowDimensions, except the latter is
 * bugged on Android to not include the system bar even though our app extends into that system bar.
 * useSafeAreaFrame doesn't work for web, so just fall back to useWindowDimensions.
 */
const usePlatformDimensions = Platform.OS === 'web' ? useWindowDimensions : useSafeAreaFrame;

export type WindowLayout = {
  windowDimensions: Dimens;
  insets: Insets;
};

/**
 * Calculates various scaling constants. Serves as a way to get window size and insets. Use this instead of
 * {@link useWindowDimensions} or {@link useSafeAreaFrame} or {@link useSafeAreaInsets}.
 */
export function useWindowLayout(): WindowLayout {
  const windowDimensions: Dimens = usePlatformDimensions();
  const edgeInsets = useSafeAreaInsets();
  const insets = withRtl(edgeInsets);
  return { windowDimensions, insets };
}

/**
 * Calculates the largest rectangle of a given aspect ratio that _is contained in_ some given dimensions.
 * So, one dimension will equal the corresponding dimensions of the constrains, and the other will be smaller.
 *
 * This is similar to the `resizeMode: 'contain'` which is a style prop for `<Image>` components.
 *
 * @param constraints - the dimensions to contain the rectangle within
 * @param aspectRatio - the aspect ratio to use (width/height)
 */
export function containAspectRatio(constraints: Dimens, aspectRatio: number): Dimens {
  // Try first using parent's width and calculating a new height.
  const height = constraints.width / aspectRatio;

  if (height <= constraints.height) {
    // It is contained in the constraints
    return { width: constraints.width, height: height };
  } else {
    // Resulting rectangle bigger than parent - use parent's height instead.
    return { width: constraints.height * aspectRatio, height: constraints.height };
  }
}

export const QUESTION_WIDTH = 1280;
export const MINIMUM_QUESTION_HEIGHT = 720;
export const PDF_WIDTH = 2480;
export const PDF_HEIGHT = 3508;
export const PDF_QUESTION_WIDTH = 1980;

/** Provides the current scale factor, given by {@link ScaleContent}. */
export const ScaleFactorContext = createContext<number>(1);

/** Provides information about the nearest ScaleContent ancestor. */
export const ScaleContentContext = createContext<{
  /** An animated ref to the portal host's view, useful for measuring (even during animations). */
  ref?: AnimatedRef<View>;
  /** A unique ID, useful for scoping stuff to this scale content. */
  scaleContentId: string;
}>({ scaleContentId: 'no scale content' });

/**
 * Scales the children of this component by the given scale factor. (Note that its available dimensions that the
 * children think they have is scaled by the inverse of this - if we make the children smaller they think they have
 * more space to work with.)
 *
 * For example, suppose dimens = {x: 1600, y: 1000} and scaleFactor = 2. The children of this component will be scaled
 * _up_ by a factor of 2, meaning that they will think they have 800x500 to work with. Hence scaledDimens is 800x500.
 *
 * Also provides a {@link Portal.Host} to render components on top of all others, rather than using the top-level one.
 */
export function ScaleContent({
  dimens,
  scaleFactor,
  skipPortalHost = false,
  children
}: PropsWithChildren<{
  dimens: Dimens;
  scaleFactor: number;
  skipPortalHost?: boolean;
}>) {
  const previousScaleFactor = useContext(ScaleFactorContext);
  const previousScaleContext = useContext(ScaleContentContext);

  const scaledDimens = useMemo(
    () => ({
      width: dimens.width / scaleFactor,
      height: dimens.height / scaleFactor
    }),
    [dimens.height, dimens.width, scaleFactor]
  );

  const scaledViewRef = useAnimatedRef<View>();
  const id = useId();

  if (math.equal(scaleFactor, 1)) {
    // Scale factor was 1 (up to float errors). Don't do any scaling.
    return (
      <View style={dimens}>
        {previousScaleContext.ref !== undefined || skipPortalHost ? (
          // No need to wrap in Portal.Host as there is another one in the heirarchy, or we were requested not to.
          children
        ) : (
          <ScaleContentContext.Provider value={{ ref: scaledViewRef, scaleContentId: id }}>
            <Portal.Host ref={scaledViewRef}>{children}</Portal.Host>
          </ScaleContentContext.Provider>
        )}
      </View>
    );
  }

  return (
    <View style={dimens}>
      <View
        style={{
          ...scaledDimens,
          // These transforms simply scale the view whilst keeping its top-left corner aligned with the top-left corner
          // of the parent. The translations are necessary because normally things are scaled with the middle as the
          // fixed point, but we want to scale with the top-left as the fixed point.
          transform: [
            { translateX: -scaledDimens.width / 2 },
            { translateY: -scaledDimens.height / 2 },
            { scale: scaleFactor },
            { translateX: scaledDimens.width / 2 },
            { translateY: scaledDimens.height / 2 }
          ],
          overflow: 'hidden'
        }}
      >
        <ScaleFactorContext.Provider value={scaleFactor * previousScaleFactor}>
          {skipPortalHost ? (
            children
          ) : (
            <ScaleContentContext.Provider value={{ ref: scaledViewRef, scaleContentId: id }}>
              <Portal.Host ref={scaledViewRef}>{children}</Portal.Host>
            </ScaleContentContext.Provider>
          )}
        </ScaleFactorContext.Provider>
      </View>
    </View>
  );
}

/**
 * Get the minimum safe width, taking into account the question's scaleFactor and the current platform.
 * If you pass in a width, instead return either the input width or the minimum safe width, whichever is bigger.
 */
export function useMinimumSafeWidth(width?: number): number {
  const scaleFactor = useContext(ScaleFactorContext);
  const minSafeWidth =
    Platform.OS === 'web' ? 0.25 / scaleFactor : StyleSheet.hairlineWidth / scaleFactor;
  if (width !== undefined) {
    return Math.max(width, minSafeWidth);
  } else {
    return minSafeWidth;
  }
}
