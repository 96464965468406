import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'PositionAndDirection',
  field: 'Geometry',
  weeks: [4, 5],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'ReadAndPlotCoordinates',
      questionTypes: ['aAM', 'aAN', 'aAO', 'aAP', 'aAQ', 'aAR'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/PositionAndDirection/1ReadAndPlotCoordinates" */ './1ReadAndPlotCoordinates'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'ProblemSolvingWithCoordinates',
      questionTypes: ['aAS', 'aAT', 'aAU', 'aAV', 'aAW', 'aAX'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/PositionAndDirection/2ProblemSolvingWithCoordinates" */ './2ProblemSolvingWithCoordinates'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'Translation',
      questionTypes: ['aAY', 'aAZ', 'aA0', 'aA1', 'aA2', 'aA3'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/PositionAndDirection/3Translation" */ './3Translation'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'TranslationWithCoordinates',
      questionTypes: ['aA4', 'aA5', 'aA6', 'aA7', 'aA8', 'aA9'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/PositionAndDirection/4TranslationWithCoordinates" */ './4TranslationWithCoordinates'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'LinesOfSymmetry',
      questionTypes: ['aBa', 'aBb2', 'aBc2', 'aBd', 'aBe', 'aBf'],
      archivedQuestionTypes: ['aBb', 'aBc'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/PositionAndDirection/5LinesOfSymmetry" */ './5LinesOfSymmetry'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'ReflectionInHorizontalAndVerticalLines',
      questionTypes: ['aBg', 'aBh', 'aBi', 'aBj', 'aBk', 'aBl'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/PositionAndDirection/6ReflectionInHorizontalAndVerticalLines" */ './6ReflectionInHorizontalAndVerticalLines'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
