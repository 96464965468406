import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Area',
  field: 'Measurement',
  weeks: [8, 8],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'WhatIsArea',
      questionTypes: ['afi', 'afj', 'afk', 'afl2', 'afm', 'afn'],
      archivedQuestionTypes: ['afl'],
      module: () =>
        import(/* webpackChunkName: "Year4/Autumn/Area/1WhatIsArea" */ './1WhatIsArea').then(
          mod => mod.default
        )
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'CountSquares',
      questionTypes: ['afo', 'afp', 'afq', 'afr', 'afs', 'aft'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year4/Autumn/Area/2CountSquares" */ './2CountSquares').then(
          mod => mod.default
        )
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'MakeShapes',
      questionTypes: ['afu', 'afv', 'afw', 'afx', 'afy', 'afz'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year4/Autumn/Area/3MakeShapes" */ './3MakeShapes').then(
          mod => mod.default
        )
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'CompareAreas',
      questionTypes: ['afA', 'afB', 'afC', 'afD', 'afE', 'afF'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year4/Autumn/Area/4CompareAreas" */ './4CompareAreas').then(
          mod => mod.default
        )
    })
  ]
});
export default Block;
