import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'AdditionAndSubtraction',
  field: 'Number',
  weeks: [5, 7],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'AddAndSubtract1s10s100sAnd1000s',
      questionTypes: ['ahO', 'ahP', 'ahQ2', 'ahR', 'ahS', 'ahT'],
      archivedQuestionTypes: ['ahQ'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/AdditionAndSubtraction/1AddAndSubtract1s10s100sAnd1000s" */ './1AddAndSubtract1s10s100sAnd1000s'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'AddUpToTwo4DigitNumbersNoExchange',
      questionTypes: ['ahU', 'ahV', 'ahW', 'ahX', 'ahY', 'ahZ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/AdditionAndSubtraction/2AddUpToTwo4DigitNumbersNoExchange" */ './2AddUpToTwo4DigitNumbersNoExchange'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'AddTwo4DigitNumbersOneExchange',
      questionTypes: ['ah0', 'ah1', 'ah2', 'ah3', 'ah4', 'ah5'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/AdditionAndSubtraction/3AddTwo4DigitNumbersOneExchange" */ './3AddTwo4DigitNumbersOneExchange'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'AddTwo4DigitNumbersMoreThanOneExchange',
      questionTypes: ['ah6', 'ah7', 'ah8', 'ah9', 'aia', 'aib'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/AdditionAndSubtraction/4AddTwo4DigitNumbersMoreThanOneExchange" */ './4AddTwo4DigitNumbersMoreThanOneExchange'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'SubtractTwo4DigitNumbersNoExchange',
      questionTypes: ['aic', 'aid', 'aie', 'aif', 'aig', 'aih'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/AdditionAndSubtraction/5SubtractTwo4DigitNumbersNoExchange" */ './5SubtractTwo4DigitNumbersNoExchange'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'SubtractTwo4DigitNumbersOneExchange',
      questionTypes: ['aii', 'aij', 'aik', 'ail', 'aim', 'ain'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/AdditionAndSubtraction/6SubtractTwo4DigitNumbersOneExchange" */ './6SubtractTwo4DigitNumbersOneExchange'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'SubtractTwo4DigitNumbersMoreThanOneExchange',
      questionTypes: ['aio', 'aip', 'aiq', 'air', 'ais', 'ait'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/AdditionAndSubtraction/7SubtractTwo4DigitNumbersMoreThanOneExchange" */ './7SubtractTwo4DigitNumbersMoreThanOneExchange'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'EfficientSubtraction',
      questionTypes: ['aiu', 'aiv', 'aiw', 'aix', 'aiy', 'aiz'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/AdditionAndSubtraction/8EfficientSubtraction" */ './8EfficientSubtraction'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'EstimateAnswers',
      questionTypes: ['aiA', 'aiB', 'aiC', 'aiD', 'aiE', 'aiF'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/AdditionAndSubtraction/9EstimateAnswers" */ './9EstimateAnswers'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'CheckingStrategies',
      questionTypes: ['aiG', 'aiH', 'aiI', 'aiJ', 'aiK', 'aiL'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/AdditionAndSubtraction/10CheckingStrategies" */ './10CheckingStrategies'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
