import './polyfill/Intl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AppState, StyleSheet, Image, Platform } from 'react-native';
import { SystemBars } from 'react-native-edge-to-edge';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Navigation from 'pupil-app/src/navigation';
import { Provider as PaperProvider } from 'react-native-paper';
import { lightTheme } from 'common/theme';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { Roboto_400Regular, Roboto_500Medium } from '@expo-google-fonts/roboto';
import {
  LibreBaskerville_400Regular,
  LibreBaskerville_700Bold
} from '@expo-google-fonts/libre-baskerville';
import * as Localization from 'expo-localization';
import type { Locales } from './i18n/i18n-types';
import TypesafeI18n from './i18n/i18n-react';
import { isLocale } from './i18n/i18n-util';
import { loadLocaleAsync } from './i18n/i18n-util.async';
import type { Locales as LocalesCommon } from 'common/i18n/i18n-types';
import TypesafeI18nCommon from 'common/i18n/i18n-react';
import { isLocale as isLocaleCommon } from 'common/i18n/i18n-util';
import { loadLocaleAsync as loadLocaleAsyncCommon } from 'common/i18n/i18n-util.async';
import 'setimmediate'; // Is needed for "react-native-gesture-handler"
import { Portal } from 'common/components/portal';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import Animated, {
  Easing,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated';
import { RootSiblingParent } from 'react-native-root-siblings';
import { useNetworkStatus } from './hooks/useNetworkStatus';
import { updateQuizSession } from './network/quizSession';
import { useSubmitAllQueuedQuestions } from './storage/useQuestionQueueStore';
import { getPlayer } from 'common/utils/Audio';
import useIsMobileBrowserAndPortrait from './hooks/useIsMobileBrowserAndPortrait';
import showAlert from './utils/showAlert';
import * as Sentry from '@sentry/react-native';
import AppConfig from '../app.json';
import ENV from './ENV';
import useQuizSessionStore from './storage/useQuizSessionStore';
import { colors } from 'common/theme/colors';
import { KeyboardProvider } from 'react-native-keyboard-controller';

// Initialise Sentry Agent
Sentry.init({
  dsn: 'https://49a766992748c45e05c103da909772ea@o1092821.ingest.us.sentry.io/4507883527077888',
  environment: ENV.SENTRY_ENV,
  debug: ENV.SENTRY_ENV !== 'production',
  release: `infinity@${AppConfig.expo.version}`
});

// Don't hide the splash screen until we say so, as we might be loading fonts or other things.
SplashScreen.preventAutoHideAsync();
SplashScreen.setOptions({
  duration: 500,
  fade: true
});

// WIBNI: Sentry installation docs say to wrap the app in Sentry.wrap, but this was causing some log-spam, so we
// haven't done it just yet.
export default function App() {
  ////
  // Fonts
  ////

  const [fontsLoaded, fontError] = useFonts({
    'Biotif-Medium': require('common/assets/fonts/Biotif-Medium.ttf'),
    'Biotif-Regular': require('common/assets/fonts/Biotif-Regular.ttf'),
    'Biotif-Bold': require('common/assets/fonts/Biotif-Bold.ttf'),
    'Biotif-ExtraBold': require('common/assets/fonts/Biotif-ExtraBold.ttf'),
    'Biotif-Black': require('common/assets/fonts/Biotif-Black.ttf'),
    Roboto_400Regular,
    Roboto_500Medium,
    LibreBaskerville_400Regular,
    LibreBaskerville_700Bold,
    'White_Rose_Noto-Bold': require('common/assets/fonts/White_Rose_Noto_bold.ttf'),
    'White_Rose_Noto-Regular': require('common/assets/fonts/White_Rose_Noto_regular.ttf')
  });

  ////
  // Audio player
  ////

  // Warm up the audio player, so that it's ready when it's needed
  getPlayer();

  // Make a logo sound on native
  useEffect(() => {
    if (Platform.OS !== 'web') {
      const player = getPlayer();
      player.setSoundEnabled(true);
      player.playSound('logo');
    }
  }, []);

  ////
  // Locales
  ////

  const [locale, setLocale] = useState<(Locales & LocalesCommon) | null>(null);

  const updateLocaleIfNecessary = useCallback(async () => {
    // desiredLocale will fall-back to 'en' if none of the device/browser's locales are found in our supported Locales array
    const desiredLocale =
      Localization.locales.find(
        (x): x is Locales & LocalesCommon => isLocale(x) && isLocaleCommon(x)
      ) ?? 'en';

    if (locale !== desiredLocale) {
      await loadLocaleAsync(desiredLocale);
      await loadLocaleAsyncCommon(desiredLocale);
      setLocale(desiredLocale);
    }
  }, [locale]);

  // On Android, the locale can change without the App being restarted. Listen to AppState changes.
  useEffect(() => {
    const subscription = AppState.addEventListener('change', () => updateLocaleIfNecessary());
    return () => subscription.remove();
  }, [updateLocaleIfNecessary]);

  // Load the locale if needed
  updateLocaleIfNecessary();

  const appIsReady = (fontsLoaded || fontError) && locale !== null;

  ////
  // Send queued quiz results
  ////

  const submitAllQueuedQuestions = useSubmitAllQueuedQuestions(updateQuizSession);

  const { isConnected } = useNetworkStatus();
  useEffect(() => {
    if (isConnected) {
      submitAllQueuedQuestions();
    }
  }, [isConnected, submitAllQueuedQuestions]);

  ////
  // Clear unneeded data from the useQuizSessionStore, once on startup.
  ////

  const deleteExpiredCacheEntries = useQuizSessionStore(state => state.deleteExpiredCacheEntries);
  useEffect(() => {
    deleteExpiredCacheEntries();
  }, [deleteExpiredCacheEntries]);

  ////
  // Splash screen
  //
  // - On native, we simply hide the native splash screen once everything has loaded
  // - On web, we show a fake splash screen using <Image> from the beginning (minimum 2s), and hide once everything has
  //   loaded
  ////

  // [native] hide native splash when navigation container is ready (see <Navigation> below).

  // [web] set splash screen visible initially (except in E2E tests, where we skip it)
  const [showFakeSplashWeb, setShowFakeSplashWeb] = useState<boolean>(
    Platform.OS === 'web' && !ENV.IS_E2E
  );

  // show fake splash for at least 2 seconds
  const [fakeSplashWebShownForMinDuration, setFakeSplashWebShownForMinDuration] = useState(false);
  const onFakeSplashWebLoaded = useCallback(() => {
    setTimeout(() => setFakeSplashWebShownForMinDuration(true), 2000);
  }, []);

  // fade out over 0.5s
  const fakeSplashWebOpacity = useSharedValue(1);
  const fakeSplashWebStyle = useAnimatedStyle(() => ({
    opacity: fakeSplashWebOpacity.value
  }));
  useEffect(() => {
    if (fakeSplashWebShownForMinDuration && appIsReady) {
      fakeSplashWebOpacity.value = withTiming(
        0,
        { duration: 500, easing: Easing.linear },
        finished => {
          if (finished) runOnJS(setShowFakeSplashWeb)(false);
        }
      );
    }
  }, [appIsReady, fakeSplashWebOpacity, fakeSplashWebShownForMinDuration]);

  const fakeSplashWeb = showFakeSplashWeb && (
    <Animated.View style={[styles.splashContainer, fakeSplashWebStyle]}>
      <Image
        source={require('pupil-app/assets/AppSplashWeb.png')}
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          backgroundColor: colors.prussianBlue,
          zIndex: 99
        }}
        resizeMode="contain"
        fadeDuration={0}
        onLoadEnd={onFakeSplashWebLoaded}
      />
    </Animated.View>
  );

  ////
  // [web only] Show an alert if this is mobile and in portrait.
  ////

  const isMobileBrowserAndPortrait = useIsMobileBrowserAndPortrait();
  const hasShownMobileBrowserAndPortraitAlert = useRef(false);
  useEffect(() => {
    if (
      // Only show after the splash screen has hidden
      !showFakeSplashWeb &&
      isMobileBrowserAndPortrait &&
      !hasShownMobileBrowserAndPortraitAlert.current
    ) {
      hasShownMobileBrowserAndPortraitAlert.current = true;
      showAlert(
        'This app works best in landscape!\nPlease rotate your device - you may need to unlock device rotation.'
      );
    }
  }, [isMobileBrowserAndPortrait, showFakeSplashWeb]);

  ////
  // Main app JSX
  ////

  const theme = lightTheme;

  return (
    <>
      {fakeSplashWeb}
      {appIsReady && (
        <GestureHandlerRootView style={styles.rootView}>
          {/* Use i18n strings from common package */}
          <TypesafeI18nCommon locale={locale}>
            {/* Use i18n strings from this package */}
            <TypesafeI18n locale={locale}>
              <KeyboardProvider statusBarTranslucent navigationBarTranslucent preserveEdgeToEdge>
                {/* In future, we might not need this Provider as there is already one in PaperProvider. */}
                <SafeAreaProvider>
                  <PaperProvider theme={lightTheme}>
                    {/* Needed by react-native-root-toast. Does the same thing as Portal.Host below. */}
                    <RootSiblingParent>
                      {/* Needed by common */}
                      <Portal.Host>
                        <SystemBars style="light" />

                        {/* The majority of the content is here, in Navigation, via "screens". */}
                        <Navigation
                          theme={{
                            colors: {
                              background: 'white',
                              primary: theme.colors.primary,
                              card: theme.colors.background,
                              border: theme.colors.primary,
                              text: theme.colors.primary,
                              notification: theme.colors.background
                            },
                            dark: false
                          }}
                          onReady={SplashScreen.hide}
                        />
                      </Portal.Host>
                    </RootSiblingParent>
                  </PaperProvider>
                </SafeAreaProvider>
              </KeyboardProvider>
            </TypesafeI18n>
          </TypesafeI18nCommon>
        </GestureHandlerRootView>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  rootView: { flex: 1, backgroundColor: 'black' },
  splashContainer: {
    backgroundColor: '#002E63',
    zIndex: 2,
    position: 'absolute',
    width: '100%',
    height: '100%'
  }
});
