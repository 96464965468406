import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'AdditionAndSubtraction',
  field: 'Number',
  weeks: [5, 9],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'BondsTo10',
      questionTypes: ['bgy', 'bgz', 'bgA'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/1BondsTo10" */ './1BondsTo10'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'FactFamiliesAdditionSubtractionBondsWithin20',
      questionTypes: ['bgB', 'bgC', 'bgD'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/2FactFamiliesAdditionSubtractionBondsWithin20" */ './2FactFamiliesAdditionSubtractionBondsWithin20'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'RelatedFacts',
      questionTypes: ['bgE', 'bgF', 'bgG'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/3RelatedFacts" */ './3RelatedFacts'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'BondsTo100Tens',
      questionTypes: ['bgH', 'bgI', 'bgJ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/4BondsTo100Tens" */ './4BondsTo100Tens'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'AddAndSubtract1s',
      questionTypes: ['bgK', 'bgL', 'bgM'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/5AddAndSubtract1s" */ './5AddAndSubtract1s'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'AddByMaking10',
      questionTypes: ['bgN', 'bgO', 'bgP'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/6AddByMaking10" */ './6AddByMaking10'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'AddThree1DigitNumbers',
      questionTypes: ['bgQ', 'bgR', 'bgS'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/7AddThree1DigitNumbers" */ './7AddThree1DigitNumbers'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'AddToTheNext10',
      questionTypes: ['bgT', 'bgU', 'bgV'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/8AddToTheNext10" */ './8AddToTheNext10'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'AddAcrossA10',
      questionTypes: ['bgW', 'bgX', 'bgY'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/9AddAcrossA10" */ './9AddAcrossA10'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'SubtractAcross10',
      questionTypes: ['bgZ', 'bg0', 'bg1'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/10SubtractAcross10" */ './10SubtractAcross10'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'SubtractFromA10',
      questionTypes: ['bg2', 'bg3', 'bg4'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/11SubtractFromA10" */ './11SubtractFromA10'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'SubtractA1DigitNumberFromA2DigitNumberAcrossA10',
      questionTypes: ['bg5', 'bg6', 'bg7'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/12SubtractA1DigitNumberFromA2DigitNumberAcrossA10" */ './12SubtractA1DigitNumberFromA2DigitNumberAcrossA10'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'TenMoreTenLess',
      questionTypes: ['bg8', 'bg9', 'bha'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/13TenMoreTenLess" */ './13TenMoreTenLess'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'AddAndSubtract10s',
      questionTypes: ['bhb', 'bhc', 'bhd'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/14AddAndSubtract10s" */ './14AddAndSubtract10s'
        ).then(mod => mod.default)
    }),
    // Step 15
    newSmallStepContent({
      smallStep: 'AddTwo2DigitNumbersNotAcrossA10',
      questionTypes: ['bhe', 'bhf', 'bhg'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/15AddTwo2DigitNumbersNotAcrossA10" */ './15AddTwo2DigitNumbersNotAcrossA10'
        ).then(mod => mod.default)
    }),
    // Step 16
    newSmallStepContent({
      smallStep: 'AddTwo2DigitNumbersAcrossA10',
      questionTypes: ['bhh', 'bhi', 'bhj'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/16AddTwo2DigitNumbersAcrossA10" */ './16AddTwo2DigitNumbersAcrossA10'
        ).then(mod => mod.default)
    }),
    // Step 17
    newSmallStepContent({
      smallStep: 'SubtractTwo2DigitNumbersNotAcrossA10',
      questionTypes: ['bhk', 'bhl', 'bhm'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/17SubtractTwo2DigitNumbersNotAcrossA10" */ './17SubtractTwo2DigitNumbersNotAcrossA10'
        ).then(mod => mod.default)
    }),
    // Step 18
    newSmallStepContent({
      smallStep: 'SubtractTwo2DigitNumbersAcrossA10',
      questionTypes: ['bhn', 'bho', 'bhp'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/18SubtractTwo2DigitNumbersAcrossA10" */ './18SubtractTwo2DigitNumbersAcrossA10'
        ).then(mod => mod.default)
    }),
    // Step 19
    newSmallStepContent({
      smallStep: 'MixedAdditionAndSubtraction',
      questionTypes: ['bhq', 'bhr', 'bhs'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/19MixedAdditionAndSubtraction" */ './19MixedAdditionAndSubtraction'
        ).then(mod => mod.default)
    }),
    // Step 20
    newSmallStepContent({
      smallStep: 'CompareNumberSentences',
      questionTypes: ['bht', 'bhu', 'bhv'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/20CompareNumberSentences" */ './20CompareNumberSentences'
        ).then(mod => mod.default)
    }),
    // Step 21
    newSmallStepContent({
      smallStep: 'MissingNumberProblems',
      questionTypes: ['bhw', 'bhx', 'bhy'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/AdditionAndSubtraction/21MissingNumberProblems" */ './21MissingNumberProblems'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
