import { SystemBars } from 'react-native-edge-to-edge';
import { type RootStackProps } from '../navigation/types';
import { showErrorToast, showInfoToast } from '../components/Toast';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ImageBackground, View } from 'react-native';
import QuizAndResultsScreen from 'common/components/screens/QuizAndResultsScreen';
import useQuizSessionStore from '../storage/useQuizSessionStore';
import { updateQuizSession } from '../network/quizSession';
import useQuestionQueueStore, {
  useSubmitAllQueuedQuestions
} from '../storage/useQuestionQueueStore';
import { loadQuestion, type QuestionToken } from 'common/SchemeOfLearning';
import { useI18nContext } from '../i18n/i18n-react';
import { useI18nContext as useI18nContextCommon } from 'common/i18n/i18n-react';
import useBreakpoints from '../hooks/useBreakpoints';
import DebugModeContext from 'common/contexts/DebugModeContext';
import useLoginStore from '../storage/useLoginStore';
import { useNetworkStatus } from '../hooks/useNetworkStatus';
import Toast from 'react-native-root-toast';
import { popToTop } from '../navigation/actions';
import ENV from '../ENV';
import {
  combineResumeStatusWithLocalSessionCache,
  getResumeStatusFromQuizSession
} from '../utils/resumeQuizHelpers';
import Logger from '../utils/logger';
import Text from 'common/components/typography/Text';
import Spinner from 'common/components/molecules/Spinner';
import { createAssignedQuizSession, createNewQuizSession } from '../network/quizSession';
import { getPlayer } from 'common/utils/Audio';
import uuid from 'react-native-uuid';
import BaseScreen from 'common/components/screens/BaseScreen';

/** Combined Quiz and Results screen. */
export default function QuizScreen({ navigation }: RootStackProps<'Quiz'>) {
  const translate = useI18nContext().LL;

  const quizSession = useQuizSessionStore(state => state.quizSession);
  const setQuizSession = useQuizSessionStore(state => state.setQuizSession);
  const updateQueueItem = useQuestionQueueStore(state => state.updateQueueItem);
  const deleteQueueItem = useQuestionQueueStore(state => state.deleteQueueItem);
  const submitAllQueuedQuestions = useSubmitAllQueuedQuestions(updateQuizSession);
  const school = useLoginStore(state => state.school);

  const hasInfinityPlus = school?.hasInfinityPlus;

  const { resize } = useBreakpoints();

  const { isConnected } = useNetworkStatus();

  // Just throw if the quizSession isn't defined. This should be impossible since we only navigate to this screen
  // immediately after setting the quizSession.
  if (quizSession === undefined) {
    throw new Error('Quiz Session is undefined, this should not be possible');
  }

  const sessionCache = useQuizSessionStore(state => state.cache[quizSession?.id]);
  const updateCacheEntry = useQuizSessionStore(state => state.updateCacheEntry);

  /** Tokens for the quiz. These may be missing question parameters, in which case they will be generated locally. */
  const tokens = useMemo(
    () =>
      quizSession.questions.map(
        (question): QuestionToken =>
          quizSession.randomiseQuestionParameters || question.parameters === undefined
            ? question.uid
            : // question.parameters is stringified question parameters, as obtained from the server.
              // All question params are plain old javascript objects.
              [question.uid, JSON.parse(question.parameters) as Record<string, unknown>]
      ),
    [quizSession.questions, quizSession.randomiseQuestionParameters]
  );

  /** The state to resume the quiz from, based on the question results in the quiz session. */
  const resumeFrom: Parameters<typeof QuizAndResultsScreen>[0]['resumeFrom'] = useMemo(() => {
    const resumeStatus = getResumeStatusFromQuizSession(quizSession);
    if (resumeStatus && sessionCache) {
      return combineResumeStatusWithLocalSessionCache(resumeStatus, sessionCache);
    } else {
      return resumeStatus;
    }
  }, [quizSession, sessionCache]);

  const [toastInstance, setToastInstance] = useState<null | React.JSX.Element>(null);

  const removeToasts = useCallback(() => {
    if (toastInstance) {
      Toast.hide(toastInstance);
      setToastInstance(null);
    }
  }, [toastInstance]);

  useEffect(() => {
    if (isConnected && toastInstance) {
      Toast.hide(toastInstance);
      setToastInstance(null);
    }
  }, [isConnected, toastInstance]);

  const setAndShowToastInstance = useCallback(() => {
    // SetToast and save instance for removal
    const newToastInstance = showErrorToast({
      title: translate.errorModals.internetConnectionLost(),
      message: translate.errorModals.resultsFailedToSendEnsureAppIsConnectedToInternet(),
      persist: true
    });

    if (toastInstance) {
      // Remove last toast
      setTimeout(() => {
        Toast.hide(toastInstance);
      }, 500);
    }

    setToastInstance(newToastInstance);
  }, [toastInstance, translate.errorModals]);

  const onTokensInvalid = useCallback(
    (invalidTokens: QuestionToken[]) => {
      if (invalidTokens.length === tokens.length) {
        // All the questions were invalid! Show a toast and navigate back to home.
        showErrorToast({
          title: translate.errorModals.appNeedsToBeUpdated(),
          message: translate.errorModals.cannotLoadQuizQuestions(),
          extraDuration: 1000,
          resize: resize
        });

        navigation.dispatch(popToTop());
      } else {
        // Some, but not all of the questions were invalid. The QuizAndResultsScreen is just using the valid ones, so
        // we don't need to do anything other than show a toast.
        showInfoToast({
          title: translate.errorModals.appNeedsToBeUpdated(),
          message: translate.errorModals.xQuestionsCouldNotBeLoaded(invalidTokens.length),
          extraDuration: 1000,
          delay: 300,
          resize: resize
        });
      }
    },
    [navigation, resize, tokens.length, translate.errorModals]
  );

  /** Callback for when a question is answered by the user, correctly or incorrectly. */
  const onAnswer = useCallback(
    async (
      questionIndex: number,
      answer: string,
      isCorrect: boolean,
      timeTaken: number,
      attemptNumber: number,
      parameters: Record<string, unknown>
    ) => {
      // Skip over dev QR Codes
      if (!quizSession || quizSession.retryInfo.type === 'legacy') {
        return;
      }

      // Work out if this answer completes the quiz
      const isComplete =
        questionIndex === quizSession.questions.length - 1 && (isCorrect || attemptNumber === 3);

      // Send question results.
      // First, try to send results for this session on its own.
      const questionId = quizSession.questions[questionIndex].id;
      const questionResult = {
        question: questionId,
        answer,
        isCorrect,
        timeTaken,
        attemptNumber,
        parameters
      };
      const queueItem = updateQueueItem(quizSession.id, questionResult, isComplete || undefined);

      if (
        quizSession?.questions &&
        questionResult.question === quizSession.questions.at(-1)?.id &&
        (questionResult.isCorrect || questionResult.attemptNumber === 3)
      ) {
        // Once quiz is complete remove the questionResults from localStorage
        setQuizSession({ ...quizSession, questionResults: undefined });
      } else {
        setQuizSession({
          ...quizSession,
          questionResults: quizSession.questionResults
            ? [...quizSession.questionResults, questionResult]
            : [questionResult]
        });
      }

      const result = await updateQuizSession(quizSession.id, {
        questionResults: queueItem.questionAttempts,
        isComplete: queueItem.isComplete
      });

      if (typeof result !== 'string') {
        // Success
        deleteQueueItem(quizSession.id);
        removeToasts();

        // Successfully sent results for this quiz, so try sending all unsent queued attempts.
        // Don't show error toast if these fail.
        submitAllQueuedQuestions();
      } else {
        // Failure
        if (hasInfinityPlus && !toastInstance) {
          setAndShowToastInstance();
        }
      }
    },
    [
      deleteQueueItem,
      hasInfinityPlus,
      quizSession,
      removeToasts,
      setAndShowToastInstance,
      setQuizSession,
      submitAllQueuedQuestions,
      toastInstance,
      updateQueueItem
    ]
  );

  /** Callback for when the quiz ends. */
  const onQuizEnd = useCallback(() => {
    if (toastInstance) {
      // We were showing an error toast. Show it again if they dismissed it.
      setAndShowToastInstance();
    }
  }, [setAndShowToastInstance, toastInstance]);

  const [loading, setLoading] = useState<
    'loadingInitialQuiz' | 'loadingTryAgainQuiz' | 'notLoading'
  >('loadingInitialQuiz');

  // Once on first render, show loading spinner for at least 3 seconds.
  // During this time, load the questions in this quiz (will usually take much less than 3 seconds).
  useEffect(() => {
    getPlayer().playSound('loading');

    (async () => {
      const atLeast3Seconds = new Promise(res => setTimeout(res, 3000));
      await Promise.all(tokens.map(token => loadQuestion(token)));
      await atLeast3Seconds;
      setLoading('notLoading');
    })();
    // Don't re-run this effect if the tokens change - the tokens will never change to different questions.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** When the user clicks "try again", fetch a new quiz session. During this time, show the loading spinner. */
  const onRetryQuiz = useCallback(async () => {
    const handleError = (title: string, message: string) => {
      showErrorToast({ title, message, extraDuration: 1000, resize: resize });
      navigation.dispatch(popToTop());
    };

    setLoading('loadingTryAgainQuiz');
    getPlayer().playSound('loading');

    // Show this screen for minimum 3s. Start that timer now.
    const minWait = new Promise(r => setTimeout(r, 3000));

    if (quizSession.retryInfo.type === 'legacy') {
      // Can skip downloading a new quiz session. Just keep the current one but change the ID
      await minWait;

      // Make sure to change the quiz session ID, since that's what everything's based on.
      setQuizSession({ ...quizSession, questionResults: [], id: `import-${uuid.v4()}` });
      setLoading('notLoading');
      return;
    }

    // Start loading the quiz
    const quizSessionResult =
      quizSession.retryInfo.type === 'assigned'
        ? await createAssignedQuizSession(quizSession.retryInfo)
        : await createNewQuizSession(quizSession.retryInfo);

    if (typeof quizSessionResult === 'string') {
      // Creating quiz session failed - show a toast and navigate home
      switch (quizSessionResult) {
        case 'invalid response':
        case 'unknown error':
        case 'http error':
        case 'logged out':
          handleError(
            translate.errorModals.somethingWentWrong(),
            translate.errorModals.pleaseTryAgainLater()
          );
          return;
        case 'network error':
          handleError(
            translate.errorModals.internetConnectionLost(),
            translate.errorModals.thisAppRequiresAnInternetConnection()
          );
          return;
        case 'not found':
          handleError(
            translate.errorModals.quizNotFound(),
            translate.errorModals.speakToYourTeacher()
          );
          return;
        case 'quiz locked':
          handleError(
            translate.errorModals.thisQuizIsLocked(),
            translate.errorModals.speakToYourTeacher()
          );
          return;
        default:
          // Produces TS error and throws runtime error if we missed a case
          throw new Error(`Logic error: unreachable (${quizSessionResult satisfies never})`);
      }
    } else {
      // Create the audio handler with the correct settings
      getPlayer(quizSessionResult.quizSounds);

      // Go to the Quiz Screen, once the minimum time has passed
      await minWait;
      setQuizSession({
        ...quizSessionResult,
        questionResults: quizSessionResult.questionResults ?? []
      });
      setLoading('notLoading');
    }
  }, [navigation, quizSession, resize, setQuizSession, translate.errorModals]);

  return (
    <>
      <SystemBars style="light" />
      <ImageBackground
        source={require('pupil-app/assets/images/SpaceBackground.png')}
        resizeMode="cover"
        fadeDuration={0}
        style={{
          flex: 1
        }}
        testID="QUIZ_SCREEN"
      >
        {loading === 'loadingInitialQuiz' ? (
          <LoadingSubscreen
            quizName={quizSession.name}
            numberOfQuestions={quizSession.questions.length}
            questionsCompleted={resumeFrom?.currentQuestionIndex}
          />
        ) : loading === 'loadingTryAgainQuiz' ? (
          <LoadingSubscreen
            quizName={quizSession.name}
            numberOfQuestions={quizSession.questions.length}
          />
        ) : (
          <DebugModeContext.Provider value={ENV.IS_E2E}>
            <QuizAndResultsScreen
              skipLoading
              key={quizSession.id}
              quizName={quizSession.name}
              tokens={tokens}
              resumeFrom={resumeFrom}
              loadingTextColor="white"
              onTokensInvalid={onTokensInvalid}
              onAnswer={onAnswer}
              onQuizEnd={onQuizEnd}
              onExitQuiz={currentState => {
                if (currentState) {
                  updateCacheEntry(quizSession.id, currentState);
                }
                setQuizSession({ ...quizSession, questionResults: undefined });
                navigation.canGoBack() ? navigation.goBack() : navigation.replace('Home');
                removeToasts();
              }}
              onRetryQuiz={onRetryQuiz}
              onReturnToHome={() => {
                navigation.canGoBack() ? navigation.goBack() : navigation.replace('Home');
                removeToasts();
              }}
              onQuestionError={([uid, params], userAnswer, error) => {
                Logger.captureEvent('error', 'quizScreen', 'RENDER_ERROR', {
                  additionalMsg: uid,
                  eventData: {
                    quizSessionId: quizSession.id,
                    quizName: quizSession.name,
                    questionParams: JSON.stringify(params),
                    userAnswer: JSON.stringify(userAnswer),
                    errorMessage: error.message
                  }
                });
              }}
            />
          </DebugModeContext.Provider>
        )}
      </ImageBackground>
    </>
  );
}

/** Loading spinner with the quiz name and number of questions, to show when loading. */
function LoadingSubscreen({
  quizName,
  numberOfQuestions,
  questionsCompleted
}: {
  quizName: string;
  /** The total number of questions in a quiz. */
  numberOfQuestions: number;
  /** The number of questions which have already been completed. Leave undefined if not resuming. */
  questionsCompleted?: number;
}) {
  const translate = useI18nContextCommon().LL;

  return (
    <BaseScreen>
      <View key="loading" style={{ gap: 64, alignItems: 'center' }}>
        <View style={{ gap: 24 }}>
          <Spinner height={156} />
          <Text variant="WRN400" style={{ color: 'white', fontSize: 32, lineHeight: 48 }}>
            {translate.misc.loadingEllipsis()}
          </Text>
        </View>
        <View style={{ gap: 5, alignItems: 'center' }}>
          <Text
            variant="WRN700"
            style={{
              color: 'white',
              fontSize: 32,
              lineHeight: 48,
              textAlign: 'center'
            }}
          >
            {quizName}
          </Text>
          <Text variant="WRN400" style={{ color: 'white', fontSize: 32, lineHeight: 48 }}>
            {questionsCompleted !== undefined
              ? translate.misc.numberOfQuestionsFraction(questionsCompleted, numberOfQuestions)
              : translate.misc.numberOfQuestions(numberOfQuestions)}
          </Text>
        </View>
      </View>
    </BaseScreen>
  );
}
