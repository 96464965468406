import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Time',
  field: 'Measurement',
  weeks: [5, 6],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'YearsMonthsWeeksAndDays',
      questionTypes: ['axG', 'axH', 'axI', 'axJ', 'axK', 'axL2'],
      archivedQuestionTypes: ['axL'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Time/1YearsMonthsWeeksAndDays" */ './1YearsMonthsWeeksAndDays'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'HoursMinutesAndSeconds',
      questionTypes: ['axM2', 'axN', 'axO', 'axP', 'axQ', 'axR'],
      archivedQuestionTypes: ['axM'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Time/2HoursMinutesAndSeconds" */ './2HoursMinutesAndSeconds'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'ConvertBetweenAnalogueAndDigitalTimes',
      questionTypes: ['axS', 'axT', 'axU', 'axV', 'axW2', 'axX'],
      archivedQuestionTypes: ['axW'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Time/3ConvertBetweenAnalogueAndDigitalTimes" */ './3ConvertBetweenAnalogueAndDigitalTimes'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'ConvertToThe24HourClock',
      questionTypes: ['axY', 'axZ', 'ax0', 'ax1', 'ax2', 'ax3'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Time/4ConvertToThe24HourClock" */ './4ConvertToThe24HourClock'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'ConvertFromThe24HourClock',
      questionTypes: ['ax4', 'ax5', 'ax6', 'ax7', 'ax8', 'ax9'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Time/5ConvertFromThe24HourClock" */ './5ConvertFromThe24HourClock'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
