import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'PositionAndDirection',
  field: 'Geometry',
  weeks: [4, 4],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'TheFirstQuadrant',
      questionTypes: ['aE2', 'aE3', 'aE4', 'aE5', 'aE62', 'aE72'],
      archivedQuestionTypes: ['aE6', 'aE7'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Summer/PositionAndDirection/1TheFirstQuadrant" */ './1TheFirstQuadrant'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'ReadAndPlotPointsInFourQuadrants',
      questionTypes: ['aE8', 'aE9', 'aFa', 'aFb', 'aFc', 'aFd2'],
      archivedQuestionTypes: ['aFd'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Summer/PositionAndDirection/2ReadAndPlotPointsInFourQuadrants" */ './2ReadAndPlotPointsInFourQuadrants'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'SolveProblemsWithCoordinates',
      questionTypes: ['aFe2', 'aFf', 'aFg', 'aFh2', 'aFi3', 'aFj3'],
      archivedQuestionTypes: ['aFe', 'aFh', 'aFi', 'aFi2', 'aFj', 'aFj2'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Summer/PositionAndDirection/3SolveProblemsWithCoordinates" */ './3SolveProblemsWithCoordinates'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'Translations',
      questionTypes: ['aFk', 'aFl', 'aFm', 'aFn', 'aFo', 'aFp'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Summer/PositionAndDirection/4Translations" */ './4Translations'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'Reflections',
      questionTypes: ['aFq', 'aFr', 'aFs', 'aFt', 'aFu', 'aFv'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Summer/PositionAndDirection/5Reflections" */ './5Reflections'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
