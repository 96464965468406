import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'PlaceValueWithin20',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'CountWithin20',
      questionTypes: ['bbX', 'bbY', 'bbZ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin20/1CountWithin20" */ './1CountWithin20'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'Understand10',
      questionTypes: ['bb0', 'bb1', 'bb2'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin20/2Understand10" */ './2Understand10'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'Understand1112And13',
      questionTypes: ['bb3', 'bb4', 'bb5'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin20/3Understand1112And13" */ './3Understand1112And13'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'Understand1415And16',
      questionTypes: ['bb6', 'bb7', 'bb8'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin20/4Understand1415And16" */ './4Understand1415And16'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'Understand1718And19',
      questionTypes: ['bb9', 'bca', 'bcb'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin20/5Understand1718And19" */ './5Understand1718And19'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'Understand20',
      questionTypes: ['bcc', 'bcd', 'bce'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin20/6Understand20" */ './6Understand20'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'OneMoreAndOneLess',
      questionTypes: ['bcf', 'bcg', 'bch'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin20/7OneMoreAndOneLess" */ './7OneMoreAndOneLess'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'TheNumberLineTo20',
      questionTypes: ['bci', 'bcj', 'bck'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin20/8TheNumberLineTo20" */ './8TheNumberLineTo20'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'UseANumberLineTo20',
      questionTypes: ['bcl', 'bcm', 'bcn'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin20/9UseANumberLineTo20" */ './9UseANumberLineTo20'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'EstimateOnANumberLineTo20',
      questionTypes: ['bco', 'bcp', 'bcq'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin20/10EstimateOnANumberLineTo20" */ './10EstimateOnANumberLineTo20'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'CompareNumbersTo20',
      questionTypes: ['bcr', 'bcs', 'bct'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin20/11CompareNumbersTo20" */ './11CompareNumbersTo20'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'OrderNumbersTo20',
      questionTypes: ['bcu', 'bcv', 'bcw'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin20/12OrderNumbersTo20" */ './12OrderNumbersTo20'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
