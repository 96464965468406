import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'MultiplicationAndDivision',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'CountIn2s',
      questionTypes: ['bdT', 'bdU', 'bdV'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/MultiplicationAndDivision/1CountIn2s" */ './1CountIn2s'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'CountIn10s',
      questionTypes: ['bdW', 'bdX', 'bdY'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/MultiplicationAndDivision/2CountIn10s" */ './2CountIn10s'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'CountIn5s',
      questionTypes: ['bdZ', 'bd0', 'bd1'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/MultiplicationAndDivision/3CountIn5s" */ './3CountIn5s'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'RecogniseEqualGroups',
      questionTypes: ['bd2', 'bd3', 'bd4'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/MultiplicationAndDivision/4RecogniseEqualGroups" */ './4RecogniseEqualGroups'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'AddEqualGroups',
      questionTypes: ['bd5', 'bd6', 'bd7'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/MultiplicationAndDivision/5AddEqualGroups" */ './5AddEqualGroups'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'MakeArrays',
      questionTypes: ['bd8', 'bd9', 'bea'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/MultiplicationAndDivision/6MakeArrays" */ './6MakeArrays'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'MakeDoubles',
      questionTypes: ['beb', 'bec', 'bed'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/MultiplicationAndDivision/7MakeDoubles" */ './7MakeDoubles'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'MakeEqualGroupsGrouping',
      questionTypes: ['bee', 'bef', 'beg'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/MultiplicationAndDivision/8MakeEqualGroupsGrouping" */ './8MakeEqualGroupsGrouping'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'MakeEqualGroupsSharing',
      questionTypes: ['beh', 'bei', 'bej'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/MultiplicationAndDivision/9MakeEqualGroupsSharing" */ './9MakeEqualGroupsSharing'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
