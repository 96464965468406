import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'AdditionAndSubtraction',
  field: 'Number',
  weeks: [4, 6],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'AddByCountingOnWithin20',
      questionTypes: ['bcx', 'bcy', 'bcz'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/AdditionAndSubtraction/1AddByCountingOnWithin20" */ './1AddByCountingOnWithin20'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'AddOnesUsingNumberBonds',
      questionTypes: ['bcA', 'bcB', 'bcC'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/AdditionAndSubtraction/2AddOnesUsingNumberBonds" */ './2AddOnesUsingNumberBonds'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'FindAndMakeNumberBondsTo20',
      questionTypes: ['bcD', 'bcE', 'bcF'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/AdditionAndSubtraction/3FindAndMakeNumberBondsTo20" */ './3FindAndMakeNumberBondsTo20'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'Doubles',
      questionTypes: ['bcG', 'bcH', 'bcI'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/AdditionAndSubtraction/4Doubles" */ './4Doubles'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'NearDoubles',
      questionTypes: ['bcJ', 'bcK', 'bcL'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/AdditionAndSubtraction/5NearDoubles" */ './5NearDoubles'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'SubtractOnesUsingNumberBonds',
      questionTypes: ['bcM', 'bcN', 'bcO'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/AdditionAndSubtraction/6SubtractOnesUsingNumberBonds" */ './6SubtractOnesUsingNumberBonds'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'SubtractionCountingBack',
      questionTypes: ['bcP', 'bcQ', 'bcR'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/AdditionAndSubtraction/7SubtractionCountingBack" */ './7SubtractionCountingBack'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'SubtractionFindingTheDifference',
      questionTypes: ['bcS', 'bcT2', 'bcU'],
      archivedQuestionTypes: ['bcT'],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/AdditionAndSubtraction/8SubtractionFindingTheDifference" */ './8SubtractionFindingTheDifference'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'RelatedFacts',
      questionTypes: ['bcV2', 'bcW', 'bcX'],
      archivedQuestionTypes: ['bcV'],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/AdditionAndSubtraction/9RelatedFacts" */ './9RelatedFacts'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'MissingNumberProblems',
      questionTypes: ['bcY', 'bcZ', 'bc0'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/AdditionAndSubtraction/10MissingNumberProblems" */ './10MissingNumberProblems'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
