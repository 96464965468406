import * as React from 'react';
import { View, StyleSheet } from 'react-native';

import PortalManager from './PortalManager';
import { SafeAreaProvider } from 'react-native-safe-area-context';

export type Props = {
  children: React.ReactNode;
};

type Operation =
  | { type: 'mount'; key: number; children: React.ReactNode }
  | { type: 'update'; key: number; children: React.ReactNode }
  | { type: 'unmount'; key: number };

export type PortalMethods = {
  mount: (children: React.ReactNode) => number;
  update: (key: number, children: React.ReactNode) => void;
  unmount: (key: number) => void;
};

export const PortalContext = React.createContext<PortalMethods>({
  mount: () => {
    throw new Error('Must wrap in a PortalContext');
  },
  update: () => {
    throw new Error('Must wrap in a PortalContext');
  },
  unmount: () => {
    throw new Error('Must wrap in a PortalContext');
  }
});

/**
 * For documentation, @see PortalHostWithRef below.
 */
class PortalHost extends React.Component<Props & { innerRef?: React.Ref<View> }> {
  static displayName = 'Portal.Host';

  componentDidMount() {
    this.clearQueue();
  }

  private clearQueue = () => {
    const manager = this.manager;
    const queue = this.queue;

    while (queue.length && manager) {
      const action = queue.pop();
      if (action) {
        // eslint-disable-next-line default-case
        switch (action.type) {
          case 'mount':
            manager.mount(action.key, action.children);
            break;
          case 'update':
            manager.update(action.key, action.children);
            break;
          case 'unmount':
            manager.unmount(action.key);
            break;
        }
      }
    }
  };

  private setManager = (manager: PortalManager | undefined | null) => {
    this.manager = manager;
    this.clearQueue();
  };

  private mount = (children: React.ReactNode) => {
    const key = this.nextKey++;
    if (this.manager) {
      this.manager.mount(key, children);
    } else {
      this.queue.push({ type: 'mount', key, children });
    }

    return key;
  };

  private update = (key: number, children: React.ReactNode) => {
    if (this.manager) {
      this.manager.update(key, children);
    } else {
      const op: Operation = { type: 'mount', key, children };
      const index = this.queue.findIndex(
        o => o.type === 'mount' || (o.type === 'update' && o.key === key)
      );

      if (index > -1) {
        this.queue[index] = op;
      } else {
        this.queue.push(op as Operation);
      }
    }
  };

  private unmount = (key: number) => {
    if (this.manager) {
      this.manager.unmount(key);
    } else {
      this.queue.push({ type: 'unmount', key });
    }
  };

  private nextKey = 0;
  private queue: Operation[] = [];
  private manager: PortalManager | null | undefined;

  render() {
    return (
      <SafeAreaProvider>
        <PortalContext.Provider
          value={{
            mount: this.mount,
            update: this.update,
            unmount: this.unmount
          }}
        >
          {/* Need collapsable=false here to clip the elevations, otherwise they appear above Portal components */}
          <View
            style={styles.container}
            collapsable={false}
            pointerEvents="box-none"
            ref={this.props.innerRef}
          >
            {this.props.children}
          </View>
          <PortalManager ref={this.setManager} />
        </PortalContext.Provider>
      </SafeAreaProvider>
    );
  }
}

/**
 * Portal host renders all of its children `Portal` elements.
 * For example, you can wrap a screen in `Portal.Host` to render items above the screen.
 * If you're using the `Provider` component, it already includes `Portal.Host`.
 *
 * ## Usage
 * ```tsx
 * import * as React from 'react';
 * import Text from '../typography/Text';
 * import { Portal } from '';
 * import { Content } from './Content';
 *
 * export default function MyComponent() {
 *   return (
 *     <Portal.Host>
 *       <Content/>
 *     </Portal.Host>
 *   );
 * }
 * ```
 *
 * Here any `Portal` elements under `<Content />` are rendered alongside `<Content />` and will appear above
 * `<Content />` like a `Modal`.
 */
const PortalHostWithRef = React.forwardRef<View, Props>((props, ref) => (
  <PortalHost innerRef={ref} {...props} />
));

export default PortalHostWithRef;

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
});
