import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'LengthAndHeight',
  field: 'Measurement',
  weeks: [8, 9],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'MeasureInCentimetres',
      questionTypes: ['bjs', 'bjt', 'bju'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/LengthAndHeight/1MeasureInCentimetres" */ './1MeasureInCentimetres'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'MeasureInMetres',
      questionTypes: ['bjv', 'bjw', 'bjx'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/LengthAndHeight/2MeasureInMetres" */ './2MeasureInMetres'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'CompareLengthsAndHeights',
      questionTypes: ['bjy', 'bjz', 'bjA'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/LengthAndHeight/3CompareLengthsAndHeights" */ './3CompareLengthsAndHeights'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'OrderLengthsAndHeights',
      questionTypes: ['bjB', 'bjC', 'bjD'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/LengthAndHeight/4OrderLengthsAndHeights" */ './4OrderLengthsAndHeights'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'FourOperationsWithLengthsAndHeights',
      questionTypes: ['bjE', 'bjF', 'bjG'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/LengthAndHeight/5FourOperationsWithLengthsAndHeights" */ './5FourOperationsWithLengthsAndHeights'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
