import { SmallStepKey } from '../i18n/custom-types';
import { QuestionTypeContent } from './Question';
import { BlockId } from './Block';

export type SmallStepId = BlockId & { smallStep: SmallStepKey };

/**
 * All of the information we store about a small step.
 */
export interface SmallStepContent {
  smallStep: SmallStepKey;
  /**
   * List of UIDs which are part of this small step, in order.
   */
  questionTypes: string[];
  archivedQuestionTypes: string[];
  /** Module containing the actual questions, and their schemas. */
  module: () => Promise<QuestionTypeContent<Record<string, unknown>>[]>;
}

export function newSmallStepContent(params: {
  smallStep: SmallStepKey;
  questionTypes: string[];
  archivedQuestionTypes?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  module: () => Promise<QuestionTypeContent<any>[]>;
}): SmallStepContent {
  return {
    smallStep: params.smallStep,
    questionTypes: params.questionTypes,
    archivedQuestionTypes: params.archivedQuestionTypes ?? [],
    module: params.module
  };
}
