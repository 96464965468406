import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Time',
  field: 'Measurement',
  weeks: [10, 11],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'BeforeAndAfter',
      questionTypes: ['bfu', 'bfv', 'bfw'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/Time/1BeforeAndAfter" */ './1BeforeAndAfter'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'DaysOfTheWeek',
      questionTypes: ['bfx', 'bfy', 'bfz'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year1/Summer/Time/2DaysOfTheWeek" */ './2DaysOfTheWeek').then(
          mod => mod.default
        )
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'MonthsOfTheYear',
      questionTypes: ['bfA', 'bfB', 'bfC'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/Time/3MonthsOfTheYear" */ './3MonthsOfTheYear'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'HoursMinutesAndSeconds',
      questionTypes: ['bfD', 'bfE', 'bfF'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/Time/4HoursMinutesAndSeconds" */ './4HoursMinutesAndSeconds'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'TellTheTimeToTheHour',
      questionTypes: ['bfG', 'bfH', 'bfI'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/Time/5TellTheTimeToTheHour" */ './5TellTheTimeToTheHour'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'TellTheTimeToTheHalfHour',
      questionTypes: ['bfJ', 'bfK', 'bfL'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/Time/6TellTheTimeToTheHalfHour" */ './6TellTheTimeToTheHalfHour'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
