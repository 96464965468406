import { Pressable, View, StyleSheet } from 'react-native';
import Text from 'common/components/typography/Text';
import { Image } from 'expo-image';
import { colors } from 'common/theme/colors';

export default function HomeMenu({
  menuActive,
  stars,
  firstName,
  lastName
}: {
  menuActive: () => void;
  stars: number;
  firstName: string;
  lastName: string;
}) {
  return (
    <View testID="PUPIL_HOME_HOME_MENU" style={styles.menu}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: 16
        }}
      >
        <Pressable onPress={menuActive}>
          <Image
            source={require('pupil-app/assets/svg/Menu.svg')}
            style={{ width: 30, height: 30 }}
          />
        </Pressable>
        <Pressable onPress={menuActive}>
          <Text style={styles.menuText}>Menu</Text>
        </Pressable>
      </View>
      <View style={styles.menuProfile}>
        <Text variant="WRN700" style={styles.text}>
          {firstName} {lastName}
        </Text>
        <View style={styles.starsWrapper}>
          <Image
            source={require('../../../../packages/common/src/assets/svg/Star.svg')}
            style={{ width: 40, height: 40 }}
          />
          <Text
            variant="WRN700"
            style={[
              styles.text,
              {
                // Give container default width to prevent content shifting when loading stars
                minWidth: 40
              }
            ]}
          >
            {stars}
          </Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    color: colors.white,
    fontSize: 32
  },
  menu: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 48,
    paddingTop: 20
  },
  menuProfile: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 18
  },
  menuText: {
    fontSize: 32,
    color: colors.white,
    fontWeight: '700'
  },
  starsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10
  }
});
