import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'FractionsB',
  field: 'Number',
  weeks: [1, 2],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'AddFractions',
      questionTypes: ['asy', 'asz', 'asA', 'asB2', 'asC', 'asD'],
      archivedQuestionTypes: ['asB'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/FractionsB/1AddFractions" */ './1AddFractions'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'SubtractFractions',
      questionTypes: ['asE2', 'asF', 'asG', 'asH', 'asI', 'asJ'],
      archivedQuestionTypes: ['asE'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/FractionsB/2SubtractFractions" */ './2SubtractFractions'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'PartitionTheWhole',
      questionTypes: ['asK', 'asL', 'asM', 'asN', 'asO', 'asP2'],
      archivedQuestionTypes: ['asP'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/FractionsB/3PartitionTheWhole" */ './3PartitionTheWhole'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'UnitFractionsOfASetOfObjects',
      questionTypes: ['asQ', 'asR', 'asS', 'asT', 'asU', 'asV'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/FractionsB/4UnitFractionsOfASetOfObjects" */ './4UnitFractionsOfASetOfObjects'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'NonUnitFractionsOfASetOfObjects',
      questionTypes: ['asW', 'asX', 'asY', 'asZ', 'as0', 'as1'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/FractionsB/5NonUnitFractionsOfASetOfObjects" */ './5NonUnitFractionsOfASetOfObjects'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'ReasoningWithFractionsOfAnAmount',
      questionTypes: ['as22', 'as32', 'as42', 'as52', 'as6', 'as72'],
      archivedQuestionTypes: ['as2', 'as3', 'as4', 'as5', 'as7'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/FractionsB/6ReasoningWithFractionsOfAnAmount" */ './6ReasoningWithFractionsOfAnAmount'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
