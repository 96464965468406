import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'MultiplicationAndDivisionA',
  field: 'Number',
  weeks: [9, 12],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'MultiplicationEqualGroups',
      questionTypes: ['ajE', 'ajF', 'ajG', 'ajH', 'ajI', 'ajJ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/MultiplicationAndDivisionA/1MultiplicationEqualGroups" */ './1MultiplicationEqualGroups'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'UseArrays',
      questionTypes: ['ajK2', 'ajL', 'ajM', 'ajN', 'ajO', 'ajP'],
      archivedQuestionTypes: ['ajK'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/MultiplicationAndDivisionA/2UseArrays" */ './2UseArrays'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'MultiplesOf2',
      questionTypes: ['ajQ', 'ajR', 'ajS', 'ajT', 'ajU', 'ajV'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/MultiplicationAndDivisionA/3MultiplesOf2" */ './3MultiplesOf2'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'MultiplesOf5And10',
      questionTypes: ['ajW', 'anZ', 'ajX', 'ajY', 'ajZ', 'aj0'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/MultiplicationAndDivisionA/4MultiplesOf5And10" */ './4MultiplesOf5And10'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'SharingAndGrouping',
      questionTypes: ['aj1', 'aj2', 'aj3', 'aj4', 'aj5', 'aj6'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/MultiplicationAndDivisionA/5SharingAndGrouping" */ './5SharingAndGrouping'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'MultiplyBy3',
      questionTypes: ['aj7', 'aj8', 'aj9', 'aka', 'akb', 'akc'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/MultiplicationAndDivisionA/6MultiplyBy3" */ './6MultiplyBy3'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'DivideBy3',
      questionTypes: ['akd', 'ake', 'akf', 'akg', 'akh', 'aki'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/MultiplicationAndDivisionA/7DivideBy3" */ './7DivideBy3'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'The3TimesTable',
      questionTypes: ['akj', 'akk', 'akl', 'akm2', 'akn', 'ako'],
      archivedQuestionTypes: ['akm'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/MultiplicationAndDivisionA/8The3TimesTable" */ './8The3TimesTable'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'MultiplyBy4',
      questionTypes: ['akp', 'akq', 'akr', 'aks', 'akt', 'aku'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/MultiplicationAndDivisionA/9MultiplyBy4" */ './9MultiplyBy4'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'DivideBy4',
      questionTypes: ['akv', 'akw', 'akx', 'aky', 'akz', 'akA'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/MultiplicationAndDivisionA/10DivideBy4" */ './10DivideBy4'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'The4TimesTable',
      questionTypes: ['akB', 'akC', 'akD', 'akE', 'akF', 'akG'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/MultiplicationAndDivisionA/11The4TimesTable" */ './11The4TimesTable'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'MultiplyBy8',
      questionTypes: ['akH', 'akI', 'akJ', 'akK', 'akL', 'akM'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/MultiplicationAndDivisionA/12MultiplyBy8" */ './12MultiplyBy8'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'DivideBy8',
      questionTypes: ['akN', 'akO', 'akP2', 'akQ', 'akR', 'akS'],
      archivedQuestionTypes: ['akP'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/MultiplicationAndDivisionA/13DivideBy8" */ './13DivideBy8'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'The8TimesTable',
      questionTypes: ['akT', 'akU', 'akV', 'akW', 'akX', 'akY'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/MultiplicationAndDivisionA/14The8TimesTable" */ './14The8TimesTable'
        ).then(mod => mod.default)
    }),
    // Step 15
    newSmallStepContent({
      smallStep: 'The24And8TimesTables',
      questionTypes: ['akZ', 'ak0', 'ak1', 'ak2', 'ak32', 'ak4'],
      archivedQuestionTypes: ['ak3'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/MultiplicationAndDivisionA/15The24And8TimesTables" */ './15The24And8TimesTables'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
