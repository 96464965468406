import { StyleSheet } from 'react-native';
import { Image as ExpoImage } from 'expo-image';
import { type SvgImageProps } from './svgImageTypes';

export default function SvgImage({ source, width, height, fill, style }: SvgImageProps) {
  // On native, we use <ExpoImage> for .svg files since react-native's Image can't handle them.

  // On native, we were using contentFit="fill" for consistency with web, but it seems to be able to stretch SVG
  // images. So, we use "contain", since the issue which prompted us to use "fill" (SVG alignment) isn't a problem on
  // native.
  const contentFit = 'contain';

  if (fill) {
    return (
      <ExpoImage
        source={source}
        focusable={false}
        pointerEvents="none"
        contentFit={contentFit}
        // Make sure to throw away the width/height from style, in case it was added accidentally.
        style={[StyleSheet.absoluteFillObject, style, { width: undefined, height: undefined }]}
      />
    );
  } else {
    return (
      <ExpoImage
        source={source}
        focusable={false}
        pointerEvents="none"
        contentFit={contentFit}
        style={[style, { width, height }]}
      />
    );
  }
}
