import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'MultiplicationAndDivisionB',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'FactorPairs',
      questionTypes: ['aJM', 'aJN', 'aJO', 'aJP', 'aJQ', 'aJR2'],
      archivedQuestionTypes: ['aJR'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/MultiplicationAndDivisionB/1FactorPairs" */ './1FactorPairs'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'UseFactorPairs',
      questionTypes: ['aJS2', 'aJT2', 'aJU2', 'aJV2', 'aJW2', 'aJX2'],
      archivedQuestionTypes: ['aJS', 'aJT', 'aJU', 'aJV', 'aJW', 'aJX'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/MultiplicationAndDivisionB/2UseFactorPairs" */ './2UseFactorPairs'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'MultiplyBy10',
      questionTypes: ['aJY', 'aJZ2', 'aJ0', 'aJ1', 'aJ2', 'aJ3'],
      archivedQuestionTypes: ['aJZ'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/MultiplicationAndDivisionB/3MultiplyBy10" */ './3MultiplyBy10'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'MultiplyBy100',
      questionTypes: ['aJ4', 'aJ5', 'aJ6', 'aJ7', 'aJ8', 'aJ9'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/MultiplicationAndDivisionB/4MultiplyBy100" */ './4MultiplyBy100'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'DivideBy10',
      questionTypes: ['aKa', 'aKb', 'aKc', 'aKd', 'aKe', 'aKf'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/MultiplicationAndDivisionB/5DivideBy10" */ './5DivideBy10'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'DivideBy100',
      questionTypes: ['aKg', 'aKh2', 'aKi', 'aKj', 'aKk2', 'aKl'],
      archivedQuestionTypes: ['aKh', 'aKk'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/MultiplicationAndDivisionB/6DivideBy100" */ './6DivideBy100'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'RelatedFactsMultiplicationAndDivision',
      questionTypes: ['aKm', 'aKn', 'aKo', 'aKp', 'aKq2', 'aKr'],
      archivedQuestionTypes: ['aKq'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/MultiplicationAndDivisionB/7RelatedFactsMultiplicationAndDivision" */ './7RelatedFactsMultiplicationAndDivision'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'InformalWrittenMethodsForMultiplication',
      questionTypes: ['aKs2', 'aKt', 'aKu', 'aKv', 'aKw2', 'aKx'],
      archivedQuestionTypes: ['aKs', 'aKw'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/MultiplicationAndDivisionB/8InformalWrittenMethodsForMultiplication" */ './8InformalWrittenMethodsForMultiplication'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'MultiplyA2DigitNumberByA1DigitNumber',
      questionTypes: ['aKy', 'aKz2', 'aKA2', 'aKB', 'aKC', 'aKD'],
      archivedQuestionTypes: ['aKz', 'aKA'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/MultiplicationAndDivisionB/9MultiplyA2DigitNumberByA1DigitNumber" */ './9MultiplyA2DigitNumberByA1DigitNumber'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'MultiplyA3DigitNumberByA1DigitNumber',
      questionTypes: ['aKE', 'aKF', 'aKG', 'aKH', 'aKI', 'aKJ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/MultiplicationAndDivisionB/10MultiplyA3DigitNumberByA1DigitNumber" */ './10MultiplyA3DigitNumberByA1DigitNumber'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'DivideA2DigitNumberByA1DigitNumber1',
      questionTypes: ['aKK2', 'aKL2', 'aKM', 'aKO', 'aKP2', 'aKN2'],
      archivedQuestionTypes: ['aKK', 'aKL', 'aKP', 'aKN'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/MultiplicationAndDivisionB/11DivideA2DigitNumberByA1DigitNumber1" */ './11DivideA2DigitNumberByA1DigitNumber1'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'DivideA2DigitNumberByA1DigitNumber2',
      questionTypes: ['aKQ', 'aKR', 'aKS', 'aKT', 'aKU', 'aKV'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/MultiplicationAndDivisionB/12DivideA2DigitNumberByA1DigitNumber2" */ './12DivideA2DigitNumberByA1DigitNumber2'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'DivideA3DigitNumberByA1DigitNumber',
      questionTypes: ['aKW', 'aKX', 'aKY', 'aKZ', 'aK0', 'aK1'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/MultiplicationAndDivisionB/13DivideA3DigitNumberByA1DigitNumber" */ './13DivideA3DigitNumberByA1DigitNumber'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'CorrespondenceProblems',
      questionTypes: ['aK2', 'aK3', 'aK4', 'aK5', 'aK6', 'aK7'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/MultiplicationAndDivisionB/14CorrespondenceProblems" */ './14CorrespondenceProblems'
        ).then(mod => mod.default)
    }),
    // Step 15
    newSmallStepContent({
      smallStep: 'EfficientMultiplication',
      questionTypes: ['aK82', 'aK92', 'aLa2', 'aLb2', 'aLc2', 'aLd'],
      archivedQuestionTypes: ['aK8', 'aK9', 'aLa', 'aLb', 'aLc'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/MultiplicationAndDivisionB/15EfficientMultiplication" */ './15EfficientMultiplication'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
