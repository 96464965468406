import ModalCard from 'common/components/modals/ModalCard';
import { useTheme } from 'common/theme';
import Text from 'common/components/typography/Text';
import { View } from 'react-native';
import Button from 'common/components/atoms/Button';

export default function InactivityModal({
  onLogout,
  onStay,
  secondsRemaining
}: {
  onLogout: () => void;
  onStay: () => void;
  secondsRemaining: string;
}) {
  const theme = useTheme();

  return (
    <ModalCard
      containerStyle={{
        alignItems: 'center',
        justifyContent: 'space-between',
        rowGap: 8,
        height: 380,
        width: 708
      }}
      style={{ zIndex: 999 }}
      disableAnimations
    >
      <View style={{ alignItems: 'center' }}>
        <Text variant="WRN400">You will be logged out in:</Text>
        {/* Countdown */}
        <Text variant="WRN700">{`00:${secondsRemaining}`}</Text>
        {/* Button Row */}
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'flex-end', columnGap: 40 }}>
        {/* Logout Button */}
        <Button
          baseColor={theme.colors.background}
          onPress={onLogout}
          style={{ width: 270, height: 96 }}
        >
          Log out
        </Button>
        {/* Stay Button */}
        <Button
          baseColor={theme.colors.secondaryContainer}
          onPress={onStay}
          style={{ width: 270, height: 96 }}
        >
          Stay here
        </Button>
      </View>
    </ModalCard>
  );
}
