import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'LengthAndHeight',
  field: 'Measurement',
  weeks: [9, 10],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'CompareLengthsAndHeights',
      questionTypes: ['bdp', 'bdq', 'bdr'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/LengthAndHeight/1CompareLengthsAndHeights" */ './1CompareLengthsAndHeights'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'MeasureLengthUsingObjects',
      questionTypes: ['bds', 'bdt', 'bdu'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/LengthAndHeight/2MeasureLengthUsingObjects" */ './2MeasureLengthUsingObjects'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'MeasureLengthInCentimetres',
      questionTypes: ['bdv', 'bdw', 'bdx'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/LengthAndHeight/3MeasureLengthInCentimetres" */ './3MeasureLengthInCentimetres'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
