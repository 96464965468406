import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'PositionAndDirection',
  field: 'Geometry',
  weeks: [11, 12],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'DescribePositionUsingCoordinates',
      questionTypes: ['azk', 'azl', 'azm', 'azn', 'azo', 'azp'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/PositionAndDirection/1DescribePositionUsingCoordinates" */ './1DescribePositionUsingCoordinates'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'PlotCoordinates',
      questionTypes: ['azq', 'azr', 'azs', 'azt', 'azu', 'azv'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/PositionAndDirection/2PlotCoordinates" */ './2PlotCoordinates'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'Draw2DShapesOnAGrid',
      questionTypes: ['azw', 'azx', 'azy', 'azz', 'azA', 'azB'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/PositionAndDirection/3Draw2DShapesOnAGrid" */ './3Draw2DShapesOnAGrid'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'TranslateOnAGrid',
      questionTypes: ['azC', 'azD', 'azE', 'azF', 'azG', 'azH'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/PositionAndDirection/4TranslateOnAGrid" */ './4TranslateOnAGrid'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'DescribeTranslationOnAGrid',
      questionTypes: ['azI', 'azJ', 'azK', 'azL', 'azM', 'azN'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/PositionAndDirection/5DescribeTranslationOnAGrid" */ './5DescribeTranslationOnAGrid'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
