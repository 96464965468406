import Quiz from 'common/components/molecules/Quiz';
import QuizCard from 'common/components/screens/QuizCard';
import Text from 'common/components/typography/Text';
import { type SpecificQuestion } from 'common/SchemeOfLearning';
import { getPlayer } from 'common/utils/Audio';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FlatList, ScrollView, StyleSheet, View } from 'react-native';
import Spinner from 'common/components/molecules/Spinner';
import { WellDone } from 'common/components/screens/WellDone';
import { FullMarks } from 'common/components/screens/FullMarks';
import { colors } from 'common/theme/colors';
import { MINIMUM_QUESTION_HEIGHT, QUESTION_WIDTH } from 'common/theme/scaling';
import Button from 'common/components/atoms/Button';
import { AssetSvg } from 'common/assets/svg';
import { useI18nContext as useI18nContextCommon } from 'common/i18n/i18n-react';
import LinkText from 'common/components/atoms/LinkText';
import SocialMediaShareIcon from '../SocialMediaShareIcon';
import CopyToClipboardIcon from '../CopyToClipboardIcon';
import { getClipboardText, getShareText, SHARE_URL } from '../utils';

/**
 * Quiz and results sub-screen. Very similar to the QuizAndResultsScreen component from
 * packages/common, but modified slightly to use a custom results screen, and track time.
 *
 * Has its own sub-screens.
 */
export default function SocialMediaGameQuizSubScreen({
  quizName,
  tokens,
  onReturnToHome,
  onTryAgain
}: {
  quizName: string;
  tokens: SpecificQuestion[];
  onReturnToHome: () => void;
  onTryAgain: () => void;
}) {
  const player = getPlayer();

  const [subScreen, setSubScreen] = useState<'loading' | 'quiz' | 'welldone' | 'results'>(
    'loading'
  );

  const [results, setResults] = useState<Array<{
    stars: number;
  }> | null>(null);
  const fullMarks =
    (subScreen === 'results' || subScreen === 'welldone') && results!.every(it => it.stars === 3);

  // Keep track of the time - from when the loading screen ends to when they answer the last question the last time.
  // The reason we do this is to avoid incentivising skipping the final modal to get a good time.
  const startTime = useRef<number | null>(null);
  const endTime = useRef<number | null>(null);
  const attemptsOnLastQuestion = useRef(0);
  const onAnswer = useCallback(
    (questionIndex: number, _answer: unknown, isCorrect: boolean) => {
      if (questionIndex === tokens.length - 1) {
        attemptsOnLastQuestion.current += 1;
        if (isCorrect || attemptsOnLastQuestion.current === 3) {
          endTime.current = Date.now();
        }
      }
    },
    [tokens.length]
  );

  // Once only, show loading spinner for 3 seconds before showing Quiz
  useEffect(() => {
    if (subScreen === 'loading') {
      const timer = setTimeout(() => {
        startTime.current = Date.now();
        setSubScreen('quiz');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [subScreen]);

  // Play the correct audio
  useEffect(() => {
    if (subScreen === 'loading') {
      player.playSound('loading');
    } else if (subScreen === 'quiz') {
      player.stopSound();
      player.playSound('quizstart');
    } else if (subScreen === 'welldone') {
      fullMarks ? player.playSound('hundredpercent') : player.playSound('welldone');
    } else if (subScreen === 'results') {
      player.playSound('resultsscreen');
    }
  }, [fullMarks, player, subScreen]);

  const questions = useMemo(() => ({ mode: 'tokens' as const, tokens }), [tokens]);

  ////
  // Main sub screens
  ////

  const loadingSubScreen = (
    <View key="loading" style={{ gap: 64, justifyContent: 'center', alignItems: 'center' }}>
      <View style={{ gap: 24 }}>
        <Spinner height={156} />
        <Text variant="WRN400" style={{ color: 'white', fontSize: 32, lineHeight: 48 }}>
          Loading…
        </Text>
      </View>
      <View style={{ gap: 5, alignItems: 'center' }}>
        <Text
          variant="WRN700"
          style={{
            color: 'white',
            fontSize: 32,
            lineHeight: 48,
            textAlign: 'center'
          }}
        >
          {quizName}
        </Text>
        <Text variant="WRN400" style={{ color: 'white', fontSize: 32, lineHeight: 48 }}>
          {tokens.length} question{tokens.length === 1 ? '' : 's'}
        </Text>
      </View>
    </View>
  );

  const quizSubScreen = (
    <View key="quiz" style={{ alignItems: 'center', justifyContent: 'center' }}>
      <Quiz
        questions={questions}
        onExitQuiz={onReturnToHome}
        onFinishQuiz={quizResults => {
          setResults(quizResults);
          setSubScreen('welldone');
        }}
        onAnswer={onAnswer}
      />
    </View>
  );

  const welldoneSubScreen = (
    <View
      key="welldone"
      style={{ gap: 24, justifyContent: 'center', alignItems: 'center', zIndex: 9990 }}
    >
      {fullMarks ? (
        <FullMarks
          onAnimationFinish={
            () =>
              setTimeout(() => {
                setSubScreen('results');
              }, 1500) // 3.5 seconds - animation.length === 1500
          }
        />
      ) : (
        <WellDone onAnimationFinish={() => setSubScreen('results')} />
      )}
    </View>
  );

  const resultsSubScreen = (
    <View
      key="results"
      style={{ gap: 24, justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}
    >
      <Results
        quizName={quizName}
        results={results!}
        timeTakenMs={endTime.current! - startTime.current!}
        onTryAgainClicked={() => {
          player.stopSound();
          onTryAgain();
        }}
        onReturnToHomeClicked={() => {
          player.stopSound();
          onReturnToHome();
        }}
      />
    </View>
  );

  return (
    <>
      {(subScreen === 'welldone' || subScreen === 'results') && (
        // Card to go behind wellDone and results screens
        <QuizCard />
      )}

      {(() => {
        switch (subScreen) {
          case 'loading':
            return loadingSubScreen;
          case 'quiz':
            return quizSubScreen;
          case 'welldone':
            return welldoneSubScreen;
          case 'results':
            return resultsSubScreen;
        }
      })()}
    </>
  );
}

/**
 * Results sub-screen. Heavily inspired by the Results component from packages/common,
 * but modified to show time taken, as well as extra "Share" buttons.
 * Note: only supports quizzes of length <= 10
 */
function Results({
  quizName,
  results,
  timeTakenMs,
  onTryAgainClicked,
  onReturnToHomeClicked
}: {
  quizName: string;
  results: Array<{
    stars: number;
  }>;
  timeTakenMs: number;
  onTryAgainClicked: () => void;
  onReturnToHomeClicked: () => void;
}) {
  const translate = useI18nContextCommon().LL;

  // Transform question props to contain id
  const resultsWithIds = results.map((question, idx) => {
    return {
      questionNumber: idx + 1,
      stars: question.stars
    };
  });

  // Get stars total length and total score
  const starsTotalScore = results.reduce((acc, o) => acc + o.stars, 0);
  const starsTotalLength = results.length * 3;

  // Render ratings
  const renderRatings = () => {
    // Split the data into columns of 10
    const dataSplit = resultsWithIds.reduce(
      (acc, x) => {
        const lastArray = acc[acc.length - 1];
        if (lastArray.length < 10) {
          lastArray.push(x);
        } else {
          acc.push([x]);
        }
        return acc;
      },
      [[]] as { questionNumber: number; stars: number }[][]
    );

    return dataSplit.map((column, idx) => (
      <FlatList
        key={idx}
        data={column}
        scrollEnabled={false}
        renderItem={({ item }) => {
          const stars = [];

          const starsMissed = 3 - item.stars;

          // Render <AssetSvg name="Star" /> per amount of stars
          for (let i = 0; i < item.stars; i++) {
            stars.push(
              <View style={{ paddingLeft: 8 }} key={`star-${i}`}>
                <AssetSvg name={'Star'} height={38.2} width={40} />
              </View>
            );
          }

          // Render <AssetSvg name="Star_transparent" /> per amount of stars not collected
          for (let j = 0; j < starsMissed; j++) {
            stars.push(
              <View style={{ paddingLeft: 8 }} key={`star_transparent-${j}`}>
                <AssetSvg name={'GreyStar'} height={38.2} width={40} />
              </View>
            );
          }

          return (
            <View style={styles.rating}>
              <View style={styles.questionLabel}>
                <Text variant="WRN700" style={styles.ratingText}>
                  Q{item.questionNumber}
                </Text>
              </View>
              <View style={styles.ratingIcons}>{stars.map(star => star)}</View>
            </View>
          );
        }}
        ItemSeparatorComponent={() => <View style={styles.ratingSeperator} />}
      />
    ));
  };

  const timeTakenSeconds = (timeTakenMs / 1000).toFixed(2);
  const shareText = getShareText({ starsTotalScore, starsTotalLength, timeTakenSeconds });
  const clipboardText = getClipboardText({ starsTotalScore, starsTotalLength, timeTakenSeconds });

  return (
    <View style={styles.container}>
      {/* Left column */}
      <View style={[styles.column, styles.columnA]}>
        {/* Close icon */}
        <Button variant="circle" onPress={onReturnToHomeClicked} style={styles.iconClose}>
          <AssetSvg name="Close" width={24} height={24} />
        </Button>

        {/* Title */}
        <Text variant="WRN700" style={styles.title}>
          {translate.quiz.results()}
        </Text>

        {/* Quiz Details */}
        <View style={styles.quizDetails}>
          <Text variant="WRN400" style={styles.quizDetailsText} numberOfLines={1}>
            {quizName}
          </Text>
        </View>

        {/* Points */}
        <View style={styles.status}>
          <Text variant="WRN700" style={styles.statusLabel}>
            Total stars:
          </Text>
          <AssetSvg name="Star" width={45} height={45} />
          <Text variant="WRN700" style={styles.statusScore}>
            {starsTotalScore} / {starsTotalLength}
          </Text>
        </View>

        {/* Time */}
        <View style={styles.status}>
          <Text variant="WRN700" style={styles.statusLabel}>
            Total time:
          </Text>
          <AssetSvg name="Stopwatch" width={45} height={45} />
          <Text variant="WRN700" style={styles.statusScore}>
            {timeTakenSeconds}
          </Text>
        </View>

        {/* Sharing */}
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'column',
            rowGap: 5
          }}
        >
          <Text
            variant="WRN400"
            style={{ fontSize: 32, lineHeight: 48, marginRight: 20 }}
            numberOfLines={1}
          >
            Share your results:
          </Text>
          <View style={{ flexDirection: 'row', columnGap: 40 }}>
            <SocialMediaShareIcon site="facebook" link={SHARE_URL} width={48} greyscale />
            <SocialMediaShareIcon site="twitter" text={shareText} width={48} greyscale />
            <SocialMediaShareIcon site="linkedin" text={shareText} width={48} greyscale />
            <SocialMediaShareIcon site="whatsapp" text={shareText} width={48} greyscale />
            <CopyToClipboardIcon text={clipboardText} width={48} />
          </View>
        </View>

        {/* Actions */}
        <View style={{ flexDirection: 'row', columnGap: 40, justifyContent: 'center' }}>
          {/* Try again */}
          <Button
            onPress={onTryAgainClicked}
            style={[styles.button, styles.center]}
            baseColor={colors.seaGreenCrayola}
            onPressInColor={colors.seaGreenCrayolaPressIn}
          >
            <Text variant="ButtonLarge" style={styles.buttonText}>
              {translate.quiz.modals.tryAgain()}
            </Text>
            <AssetSvg name="ArrowRight" style={styles.iconArrow} width={48} height={49} />
          </Button>

          <LinkText action={onReturnToHomeClicked} style={[styles.linkText, styles.center]}>
            {translate.quiz.backToHome()}
          </LinkText>
        </View>
      </View>

      {/* Right column */}
      <ScrollView
        horizontal
        scrollEnabled={false}
        contentContainerStyle={{ flexGrow: 1 }}
        style={[styles.columnBWrapper, { flex: 0.5 }]}
      >
        <View style={[styles.column, styles.columnB]}>{renderRatings()}</View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderColor: colors.prussianBlue,
    borderRadius: 24,
    borderWidth: 3,
    display: 'flex',
    flexDirection: 'row',
    height: MINIMUM_QUESTION_HEIGHT - 2 * 16,
    width: QUESTION_WIDTH - 2 * 12,
    marginVertical: 16,
    marginHorizontal: 12
  },
  column: {
    flex: 1,
    paddingHorizontal: 35,
    columnGap: 40
  },
  columnA: {
    borderRightColor: colors.prussianBlue,
    borderRightWidth: 3,
    paddingTop: 32,
    paddingBottom: 32,
    justifyContent: 'space-between'
  },
  columnBWrapper: {
    backgroundColor: colors.greyMatter,
    borderBottomRightRadius: 24,
    borderTopRightRadius: 24,
    paddingTop: 16,
    paddingBottom: 16
  },
  columnB: {
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    paddingBottom: 24,
    textAlign: 'center'
  },
  quizDetails: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  quizDetailsText: { fontSize: 32, textAlign: 'center', lineHeight: 48 },
  status: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  statusLabel: {
    paddingRight: 16
  },
  statusScore: {
    paddingLeft: 16
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: 62,
    paddingLeft: 12,
    paddingRight: 12
  },
  ratingSeperator: {
    borderWidth: 1,
    borderColor: colors.grey
  },
  ratingText: {
    textAlign: 'center',
    fontSize: 26,
    width: 77,
    alignSelf: 'stretch',
    lineHeight: 49,
    paddingRight: 12
  },
  ratingIcons: {
    display: 'flex',
    flexDirection: 'row'
  },
  questionLabel: {
    minWidth: 64
  },
  button: {
    height: 96
  },
  buttonText: {
    paddingRight: 34
  },
  linkText: {
    fontFamily: 'Biotif-Medium',
    fontSize: 29,
    lineHeight: 43.5,
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  icon: {
    paddingBottom: 28
  },
  iconArrow: {
    position: 'absolute',
    right: 24
  },
  iconClose: {
    borderWidth: 0,
    left: 12,
    top: 12,
    position: 'absolute',
    width: 64,
    height: 64,
    zIndex: 100,
    // Override the defaults in button
    paddingTop: 0,
    paddingBottom: 0
  },
  center: {
    alignSelf: 'center'
  }
});
