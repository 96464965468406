import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Volume',
  field: 'Measurement',
  weeks: [12, 12],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'CubicCentimetres',
      questionTypes: ['aDA', 'aDB', 'aDC', 'aDD', 'aDE', 'aDF'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Volume/1CubicCentimetres" */ './1CubicCentimetres'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'CompareVolume',
      questionTypes: ['aDG', 'aDH', 'aDI', 'aDJ', 'aDK2', 'aDL'],
      archivedQuestionTypes: ['aDK'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Volume/2CompareVolume" */ './2CompareVolume'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'EstimateVolume',
      questionTypes: ['aDM', 'aDN', 'aDO2', 'aDR2', 'aDP', 'aDQ'],
      archivedQuestionTypes: ['aDO', 'aDR'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Volume/3EstimateVolume" */ './3EstimateVolume'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'EstimateCapacity',
      questionTypes: ['aDS2', 'aDT2', 'aDU', 'aDV', 'aDW2', 'aDX2'],
      archivedQuestionTypes: ['aDS', 'aDT', 'aDW', 'aDX'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Volume/4EstimateCapacity" */ './4EstimateCapacity'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
