import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Time',
  field: 'Measurement',
  weeks: [4, 6],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'OClockAndHalfPast',
      questionTypes: ['bkR', 'bkS', 'bkT'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Time/1OClockAndHalfPast" */ './1OClockAndHalfPast'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'QuarterPastAndQuarterTo',
      questionTypes: ['bkU', 'bkV', 'bkW'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Time/2QuarterPastAndQuarterTo" */ './2QuarterPastAndQuarterTo'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'TellTimePastTheHour',
      questionTypes: ['bkX', 'bkY', 'bkZ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Time/3TellTimePastTheHour" */ './3TellTimePastTheHour'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'TellTimeTimeToTheHour',
      questionTypes: ['bk0', 'bk1', 'bk2'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Time/4TellTimeTimeToTheHour" */ './4TellTimeTimeToTheHour'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'TellTheTimeTo5MinutesY2',
      questionTypes: ['bk3', 'bk4', 'bk5'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Time/5TellTheTimeTo5MinutesY2" */ './5TellTheTimeTo5MinutesY2'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'MinutesInAnHour',
      questionTypes: ['bk6', 'bk7', 'bk8'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Time/6MinutesInAnHour" */ './6MinutesInAnHour'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'HoursInADay',
      questionTypes: ['bk9', 'bla', 'blb'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year2/Summer/Time/7HoursInADay" */ './7HoursInADay').then(
          mod => mod.default
        )
    })
  ]
});
export default Block;
