import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'FractionsA',
  field: 'Number',
  weeks: [8, 9],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'EquivalentFractionsAndSimplifying',
      questionTypes: ['ap9', 'aqa', 'aqb', 'aqc', 'aqd', 'aqe'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsA/1EquivalentFractionsAndSimplifying" */ './1EquivalentFractionsAndSimplifying'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'EquivalentFractionsOnANumberLine',
      questionTypes: ['aqf', 'aqg', 'aqh', 'aqi', 'aqj', 'aqk'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsA/2EquivalentFractionsOnANumberLine" */ './2EquivalentFractionsOnANumberLine'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'CompareAndOrderDenominator',
      questionTypes: ['aql', 'aqm', 'aqn', 'aqo2', 'aqp', 'aqq'],
      archivedQuestionTypes: ['aqo'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsA/3CompareAndOrderDenominator" */ './3CompareAndOrderDenominator'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'CompareAndOrderNumerator',
      questionTypes: ['aqr', 'aqs', 'aqt', 'aqu', 'aqv', 'aqw'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsA/4CompareAndOrderNumerator" */ './4CompareAndOrderNumerator'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'AddAndSubtractSimpleFractions',
      questionTypes: ['aqx', 'aqy', 'aqz', 'aqA', 'aqB', 'aqC'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsA/5AddAndSubtractSimpleFractions" */ './5AddAndSubtractSimpleFractions'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'AddAndSubtractAnyTwoFractions',
      questionTypes: ['aqD', 'aqE', 'aqF', 'aqG', 'aqH', 'aqI'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsA/6AddAndSubtractAnyTwoFractions" */ './6AddAndSubtractAnyTwoFractions'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'AddMixedNumbers',
      questionTypes: ['aqJ', 'aqK', 'aqL', 'aqM', 'aqN', 'aqO'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsA/7AddMixedNumbers" */ './7AddMixedNumbers'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'SubtractMixedNumbers',
      questionTypes: ['aqP', 'aqQ', 'aqR', 'aqS', 'aqT', 'aqU'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsA/8SubtractMixedNumbers" */ './8SubtractMixedNumbers'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'MultiStepProblems',
      questionTypes: ['aqV2', 'aqW2', 'aqX', 'aqY', 'aqZ', 'aq0'],
      archivedQuestionTypes: ['aqV', 'aqW'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsA/9MultiStepProblems" */ './9MultiStepProblems'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
