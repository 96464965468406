import { View } from 'react-native';
import LottieView from 'lottie-react-native';
import { range } from '../../utils/collections';
import Text from '../typography/Text';
import { useEffect, useRef, useState } from 'react';
import { noop } from '../../utils/flowControl';
import { useI18nContext as commonUseI18nContext } from '../../i18n/i18n-react';
import ModalCard from './ModalCard';
import { AssetSvg } from '../../assets/svg';

/**
 * Modal which displays the message "Well done", shows an animation with up to 3 stars, and then self-dismisses after
 * 2.4 seconds total. There is no dismiss button, but clicking outside of the modal will dismiss once the animation
 * has finished.
 */
export default function WellDoneModal({
  stars,
  onDismiss = noop
}: {
  /** Integer number of stars awarded, from 0 to 3. */
  stars: number;
  /** Callback for when the Modal is dismissed.  */
  onDismiss?: () => void;
}) {
  const translate = commonUseI18nContext().LL;

  // Deny the user from dismissing the modal for the first 900ms.
  const [dismissable, setDismissable] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDismissable(true);
    }, 900);
    return () => clearTimeout(timeout);
  }, []);

  // Auto-dismiss the modal after 2400ms
  useEffect(() => {
    const timeout = setTimeout(() => {
      onDismiss();
    }, 2400);
    return () => clearTimeout(timeout);
  }, [onDismiss]);

  return (
    <ModalCard onDismiss={onDismiss} dismissable={dismissable}>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          rowGap: 24,
          alignSelf: 'stretch'
        }}
      >
        <View style={{ flexDirection: 'row', columnGap: 16 }}>
          {range(1, 3).map(i => (
            <AnimatedStar key={i} correct={i <= stars} />
          ))}
        </View>
        <View style={{ rowGap: 8 }}>
          <Text variant="WRN700">{translate.quiz.wellDoneExclamation()}</Text>
          <Text style={{ fontSize: 29, lineHeight: 44.5 }}>
            {translate.quiz.youGotXStars({ stars })}
          </Text>
        </View>
      </View>
    </ModalCard>
  );
}

/**
 * A grey or gold star, animated with lottie if gold.
 */
function AnimatedStar({
  correct
}: {
  /** Whether this star should be gold (true) or grey (false) */
  correct: boolean;
}) {
  const animation = useRef<LottieView>(null);

  // Play the animation after 100ms
  useEffect(() => {
    const timeout = setTimeout(() => {
      animation.current?.play();
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <View
      style={{
        width: 76,
        height: 72,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {/* Place the grey start (for incorrect) behind the animation (if any) */}
      <AssetSvg name={'GreyStar'} style={{ position: 'absolute', height: 72, width: 76 }} />
      {correct && (
        <LottieView
          ref={animation}
          loop={false}
          duration={800}
          hardwareAccelerationAndroid // Valid because we're not scaling the animation: the file defines it as 100x100
          style={{
            width: 100,
            height: 100,
            backgroundColor: 'transparent'
          }}
          source={require('../../assets/lottie/ActiveStar.json')}
        />
      )}
    </View>
  );
}
