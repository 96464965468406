import { useContext, useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Text from '../typography/Text';
import { useTheme } from '../../theme';
import { colors } from '../../theme/colors';
import DebugModeContext from '../../contexts/DebugModeContext';
import UidContext from '../../contexts/UidContext';
import IconButton from '../atoms/IconButton';
import { AssetSvg } from '../../assets/svg';

type Props = {
  /** Starts counting from 1. */
  questionNumber: number;
  /** Percentage between 0 and 100. */
  quizProgress: number;
  /** Integer. */
  quizStars: number;
  /** Callback for when the exit button is clicked. */
  onExitClicked?: () => void;
  /** Callback for when the back button is clicked. */
  onBackClicked?: () => void;
  /** Whether to show the back button. (Default: false) */
  showBackButton?: boolean;
  style?: StyleProp<ViewStyle>;
};

export default function ProgressContainer({
  questionNumber,
  quizProgress,
  quizStars,
  onExitClicked,
  onBackClicked,
  showBackButton = false,
  style
}: Props) {
  const styles = useStyles();

  return (
    <View style={[styles.container, style]}>
      <IconButton dataTestId="EXIT_QUIZ_ICON" iconWidth={23} onPress={onExitClicked}>
        <AssetSvg name="Close" />
      </IconButton>
      {showBackButton && (
        <IconButton
          iconWidth={40}
          onPress={onBackClicked}
          style={{ transform: [{ rotate: '180deg' }] }}
        >
          <AssetSvg name="ArrowRight" />
        </IconButton>
      )}
      <ProgressBar
        questionNumber={questionNumber}
        quizProgress={quizProgress}
        quizStars={quizStars}
      />
    </View>
  );
}

type ProgressBarProps = {
  /** Starts counting from 1. */
  questionNumber: number;
  /** Percentage between 0 and 100. */
  quizProgress: number;
  /** Integer. */
  quizStars: number;
};

/**
 * Progress bar to display along the top of a quiz question.
 */
function ProgressBar({ questionNumber, quizProgress, quizStars }: ProgressBarProps) {
  const styles = useStyles();
  const debugMode = useContext(DebugModeContext);
  const uid = useContext(UidContext);

  return (
    <View testID="PROGRESS_CONTAINER" style={[styles.column, styles.progressContainer]}>
      <View style={styles.label}>
        <Text style={styles.labelText} testID="questionNumber">
          Q{questionNumber.toLocaleString()}
        </Text>
      </View>
      {debugMode && (
        <Text testID="QUESTION_UID_LABEL" selectable={true}>
          {uid}
        </Text>
      )}
      <View style={styles.progress}>
        <View style={styles.progressBorder} />
        <View style={styles.progressBar}>
          <View style={styles.progressBarFixedStart} />
          <View style={styles.progressBarVariableRemainderContainer}>
            <View style={[styles.progressBarVariableRemainder, { width: `${quizProgress}%` }]} />
          </View>
        </View>
      </View>
      <View style={[styles.column, styles.score]}>
        <AssetSvg name="Star" height={40} width={38} />
        <Text style={[styles.labelText, styles.scoreLabel]}>{quizStars}</Text>
      </View>
    </View>
  );
}

function useStyles() {
  const theme = useTheme();

  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: 8,
          paddingTop: 11,
          paddingEnd: 32,
          paddingBottom: 0,
          paddingLeft: 12
        },
        column: {
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row'
        },
        progressContainer: {
          flex: 1
        },
        progress: {
          backgroundColor: theme.colors.absentItemBackground,
          flex: 1,
          flexDirection: 'row',
          height: 22,
          borderRadius: 32
        },
        progressBorder: {
          ...StyleSheet.absoluteFillObject,
          borderColor: theme.colors.surfaceContainerBorder,
          borderRadius: 32,
          borderWidth: 2
        },
        progressBar: {
          ...StyleSheet.absoluteFillObject,
          borderRadius: 32,
          flexDirection: 'row',
          overflow: 'hidden'
        },
        progressBarFixedStart: {
          width: 22,
          backgroundColor: colors.fieryRose
        },
        progressBarVariableRemainderContainer: {
          flex: 1,
          flexDirection: 'row'
        },
        progressBarVariableRemainder: {
          backgroundColor: colors.fieryRose
        },
        label: {
          alignItems: 'center',
          backgroundColor: theme.colors.primary,
          borderRadius: 8,
          height: 39,
          marginRight: 32,
          paddingLeft: 12,
          paddingRight: 12,
          width: 90
        },
        labelText: {
          color: theme.colors.onPrimary,
          fontFamily: 'White_Rose_Noto',
          fontWeight: '700',
          fontSize: 26
        },
        score: {
          marginLeft: 32
        },
        scoreLabel: {
          color: theme.colors.primary,
          paddingLeft: 12
        }
      }),
    [theme]
  );
}
