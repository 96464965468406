import { type StyleProp, type ViewStyle, type TextStyle, StyleSheet, View } from 'react-native';
import Text from '../components/typography/Text';
import { throwError } from '../utils/flowControl';
import MixedFraction from '../components/question/representations/MixedFraction';
import {
  type default as AST,
  type AllowedInSpanContext,
  isAllowedInSpanContext,
  type TextToken,
  type SpanToken,
  type GroupToken
} from './AST';
import { type MarkupAssetsType } from './MarkupAssets';
import { Fragment } from 'react';
import { Theme } from '../theme';

export type RenderOptions = {
  indexInParent?: number;
  inputBox?: (props: { index: number }) => JSX.Element;
  /**
   * Style to apply to the overall rendered text.
   * This is either applied to a <Text> (if all markup is text) or a <View>, which is flexDirection: 'row' and
   * flex-wrap enabled by default.
   */
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  textVariant?: keyof Theme['fonts'];
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionDividerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  displayMode?: 'digital' | 'pdf' | 'markscheme';
  /**
   * Whether to automatically group together tags which are not separated by whitespace, so that they can't line-break
   * together. Default: true.
   */
  autoGroupAdjacentTags?: boolean;
};

/**
 * Render a markup AST.
 *
 * Ideally we would use inline-block to render everything inline with the text, using text-wrap to wrap ends of lines.
 * However, this is poorly supported in react-native (inline-block isn't a thing and putting non-text components inside
 * a text component does not behave predictably), so we can't.
 *
 * Instead, we have two modes of operation:
 *
 * 1. If everything is just text (i.e. "allowed in Span context"), such as bold/italic text or linebreaks, then we can
 * use text-wrap.
 *
 *    - So we just put everything in a parent <Text> element.
 *
 * 2. Otherwise, we cannot do that. So, we need to hack around it to give the illusion of text appearing in line with
 * the other elements, with line-wrap points appearing between words.
 *
 *    - In this case, we split up all the words in the text and treat them as separate elements. Once we have all of
 *      those elements we put them in a parent <View> element, with flex-wrap.
 *
 *    - <g> tags group together some elements so they are rendered together. If narrower than a line, they are
 *      guaranteed to appear on the same line.
 *
 *    - We **automatically** add <g> tags around any elements not separated by whitespace! So you don't have to!
 *      (This can be disabled with the autoGroupAdjacentTags render option.)
 */
export default function renderMarkup(
  tokens: AST[],
  options: RenderOptions = {},
  assets: MarkupAssetsType = {}
) {
  const { style, textStyle, textVariant, autoGroupAdjacentTags = true, displayMode } = options;

  // Case (1): the markup contains only text children, just render them using text-wrap in a single <Text> component.
  if (tokens.every(isAllowedInSpanContext)) {
    return (
      <Text
        style={[displayMode === 'digital' ? styles.text : styles.pdfText, style, textStyle]}
        variant={textVariant}
      >
        {tokens.map((child, index) =>
          renderToken(
            child.type === 'br'
              ? // Map linebreaks to simply newline characters.
                { type: 'text', value: '\n' }
              : child,
            index,
            options,
            assets
          )
        )}
      </Text>
    );
  }

  // Case (2): the markup contains other elements, and we need to use flex-wrap instead.
  // Example: we start with "1 + 2 = <ans /><br><frac n='1' d='2'/>"

  // Split up any text into individual words, and make sure they're placed in a <Text>, by wrapping
  // them in a span node.
  // Example: ["1 ", "+ ", "2 ", "= ", <ans />, <br>, <frac n='1' d='2'/>]
  tokens = tokens.flatMap(child =>
    !isAllowedInSpanContext(child)
      ? [child]
      : splitAllowedInSpanContext(child)
          // Place any top-level text tokens in a top-level span token, so it gets wrapped in a <Text> when we come to
          // render it.
          .map<AST>(token =>
            token.type === 'text' ? ({ type: 'span', children: [token] } as SpanToken) : token
          )
  );

  if (autoGroupAdjacentTags) {
    // Combine together any tokens which are not separated by whitespace, such as "£<ans/>" into a <g> tag.
    const tokensGrouped: AST[] = [];

    // Process all the tokens until we've done them all
    while (tokens.length !== 0) {
      // Collect the largest group with the next element in.
      const group = [tokens.shift()!];
      while (
        // there are tokens left, and
        tokens.length !== 0 &&
        // the last token in the group (so far) can link on its right
        links(group[group.length - 1], 'next') &&
        // the next token we've not processsed yet can link on its left
        links(tokens[0], 'prev')
      ) {
        // link the tokens by adding the new one to the group
        group.push(tokens.shift()!);
      }

      if (group.length === 1) {
        // No group - just push the token
        tokensGrouped.push(group[0]);
      } else {
        // We found a group. Wrap in group token.
        tokensGrouped.push({ type: 'group', children: group } as GroupToken);
      }
    }

    tokens = tokensGrouped;
  }

  if (tokens.length === 1 && tokens[0].type === 'group') {
    // Groups do nothing if there's a single group token! Unwrap again to avoid infinite recursion!
    tokens = tokens[0].children;
  }

  // Finally, we can render these tokens.
  return (
    <View style={[styles.flexWrap, style]}>
      {tokens.map((token, index) => renderToken(token, index, options, assets))}
    </View>
  );
}

/** Split a text node on whitespace into many text nodes. */
function splitText(token: TextToken): TextToken[] {
  return token.value
    .split(/(\S+?\s+)/)
    .filter(part => part !== '')
    .map(part => ({ type: 'text', value: part }) as TextToken);
}

/** Split a text, linebreak or span node into many span nodes. */
function splitAllowedInSpanContext(token: AllowedInSpanContext): AllowedInSpanContext[] {
  switch (token.type) {
    case 'text':
      return splitText(token);
    case 'br':
      return [token];
    case 'span':
      return token.children.flatMap(splitAllowedInSpanContext).map(
        child =>
          ({
            type: 'span',
            children: [child],
            styleModifiers: token.styleModifiers
          }) as SpanToken
      );
  }
}

/** Whether this token can link to adjacent tokens. I.e. whether it doesn't start/end with whitespace. */
function links(token: AST, direction: 'next' | 'prev'): boolean {
  switch (token.type) {
    case 'text':
      // Text can link if that end isn't whitespace.
      return !(direction === 'prev' ? /^\s/ : /\s$/).test(token.value);
    case 'span':
      // Span can link if it's first/last child can.
      return token.children.length === 0
        ? true
        : links(token.children[direction === 'prev' ? 0 : token.children.length - 1], direction);
    case 'br':
      return false;
    case 'ans':
      return true;
    case 'asset':
      return true;
    case 'frac':
      return true;
    case 'group':
      return true;
  }
}

function renderToken(
  token: AST,
  key = 0,
  options: RenderOptions,
  assets: MarkupAssetsType
): string | JSX.Element {
  const {
    inputBox = () => throwError(`Input box required but is not specified in options"`),
    textStyle,
    textVariant,
    fractionContainerStyle,
    fractionDividerStyle,
    fractionTextStyle,
    displayMode
  } = options;

  switch (token.type) {
    case 'span':
      return (
        <Text
          key={`span_${key}`}
          style={[
            displayMode === 'digital' ? styles.text : styles.pdfText,
            textStyle,
            token.styleModifiers === 'bold'
              ? styles.bold
              : token.styleModifiers === 'italic'
              ? styles.italic
              : null
          ]}
          variant={textVariant}
        >
          {token.children.map((child, index) => renderToken(child, index, options, assets))}
        </Text>
      );
    case 'text':
      return token.value;
    case 'br':
      return <View key={`br_${key}`} style={styles.linebreak} />;
    case 'ans':
      return (
        <View key={`ans_${key}`} style={styles.nonTextSpacing}>
          {inputBox({ index: token.index })}
        </View>
      );
    case 'frac':
      return (
        <View key={`frac_${key}`} style={styles.nonTextSpacing}>
          <MixedFraction
            key={`frac_${key}`}
            integer={
              token.whole !== undefined
                ? renderFractionProp(token.whole, 0, options, assets)
                : undefined
            }
            numerator={renderFractionProp(token.numerator, 1, options, assets)}
            denominator={renderFractionProp(token.denominator, 2, options, assets)}
            textStyle={fractionTextStyle}
            containerStyle={fractionContainerStyle}
            dividerStyle={fractionDividerStyle}
          />
        </View>
      );
    case 'asset':
      return <View key={`asset_${key}`}>{assets.elements?.[token.name]}</View>;
    case 'group':
      return (
        <Fragment key={`group_${key}`}>
          {renderMarkup(
            token.children,
            { ...options, style: { maxWidth: '100%', flexWrap: 'nowrap' } }, // Don't use flex-wrap in a group
            assets
          )}
        </Fragment>
      );
  }
}

function renderFractionProp(
  tokens: AllowedInSpanContext[] | [AST],
  key = 0,
  options: RenderOptions,
  assets: MarkupAssetsType
): string | JSX.Element {
  const {
    inputBox = () => throwError(`Input box required but is not specified in options"`),
    fractionTextStyle
  } = options;

  if (tokens.length === 1) {
    if (tokens[0].type === 'text') {
      // There's just one text node, return as a string
      return tokens[0].value;
    } else if (tokens[0].type === 'ans') {
      // There's just one ans node, return it without any margin
      return inputBox({ index: tokens[0].index });
    } else {
      // There's just one non-text node, render it.
      return renderToken(tokens[0], key, options, assets);
    }
  } else {
    // Many nodes. These should all be allowed in span context. Wrap them in a <Text>
    return (
      <Text key={key} style={fractionTextStyle}>
        {tokens.map((token, index) =>
          renderToken(
            token.type === 'br'
              ? // Map linebreaks to simply newline characters.
                { type: 'text', value: '\n' }
              : token,
            index,
            options,
            assets
          )
        )}
      </Text>
    );
  }
}

const styles = StyleSheet.create({
  flexWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  text: {
    fontSize: 32,
    lineHeight: 48
  },
  pdfText: {
    fontSize: 50,
    lineHeight: 75
  },
  nonTextSpacing: {
    marginVertical: 8,
    marginHorizontal: 4
  },
  bold: {
    fontWeight: 'bold'
  },
  italic: {
    fontStyle: 'italic'
  },
  linebreak: {
    height: 0,
    flexBasis: '100%'
  }
});
