import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Fractions',
  field: 'Number',
  weeks: [4, 5],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'RecogniseAHalfOfAnObjectOrAShape',
      questionTypes: ['bek', 'bel', 'bem'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/Fractions/1RecogniseAHalfOfAnObjectOrAShape" */ './1RecogniseAHalfOfAnObjectOrAShape'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'FindAHalfOfAnObjectOrAShape',
      questionTypes: ['ben', 'beo', 'bep'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/Fractions/2FindAHalfOfAnObjectOrAShape" */ './2FindAHalfOfAnObjectOrAShape'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'RecogniseAHalfOfAQuantity',
      questionTypes: ['beq', 'ber', 'bes'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/Fractions/3RecogniseAHalfOfAQuantity" */ './3RecogniseAHalfOfAQuantity'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'FindAHalfOfAQuantity',
      questionTypes: ['bet', 'beu', 'bev'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/Fractions/4FindAHalfOfAQuantity" */ './4FindAHalfOfAQuantity'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'RecogniseAQuarterOfAnObjectOrAShape',
      questionTypes: ['bew', 'bex', 'bey'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/Fractions/5RecogniseAQuarterOfAnObjectOrAShape" */ './5RecogniseAQuarterOfAnObjectOrAShape'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'FindAQuarterOfAnObjectOrAShape',
      questionTypes: ['bez', 'beA', 'beB'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/Fractions/6FindAQuarterOfAnObjectOrAShape" */ './6FindAQuarterOfAnObjectOrAShape'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'RecogniseAQuarterOfAQuantity',
      questionTypes: ['beC', 'beD', 'beE'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/Fractions/7RecogniseAQuarterOfAQuantity" */ './7RecogniseAQuarterOfAQuantity'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'FindAQuarterOfAQuantity',
      questionTypes: ['beF', 'beG', 'beH'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/Fractions/8FindAQuarterOfAQuantity" */ './8FindAQuarterOfAQuantity'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
