import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Shape',
  field: 'Geometry',
  weeks: [1, 3],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'UnderstandAndUseDegrees',
      questionTypes: ['azO', 'azP', 'azQ', 'azR', 'azS', 'azT'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Shape/1UnderstandAndUseDegrees" */ './1UnderstandAndUseDegrees'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'ClassifyAngles',
      questionTypes: ['azU', 'azV', 'azW', 'azX', 'azY', 'azZ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Shape/2ClassifyAngles" */ './2ClassifyAngles'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'EstimateAngles',
      questionTypes: ['az0', 'az1', 'az2', 'az32', 'az4', 'az5'],
      archivedQuestionTypes: ['az3'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Shape/3EstimateAngles" */ './3EstimateAngles'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'MeasureAnglesUpTo180',
      questionTypes: ['az6', 'az7', 'az8', 'az9', 'aAa', 'aAb'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Shape/4MeasureAnglesUpTo180" */ './4MeasureAnglesUpTo180'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'DrawLinesAndAnglesAccurately',
      questionTypes: ['aAc', 'aAd', 'aAe2', 'aAf', 'aAg', 'aAh'],
      archivedQuestionTypes: ['aAe'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Shape/5DrawLinesAndAnglesAccurately" */ './5DrawLinesAndAnglesAccurately'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'CalculateAnglesAroundAPoint',
      questionTypes: ['aAi2', 'aAj', 'aAk', 'aAl', 'aAm', 'aAn'],
      archivedQuestionTypes: ['aAi'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Shape/6CalculateAnglesAroundAPoint" */ './6CalculateAnglesAroundAPoint'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'CalculateAnglesOnAStraightLine',
      questionTypes: ['aAo', 'aAp', 'aAq', 'aAr', 'aAs', 'aAt'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Shape/7CalculateAnglesOnAStraightLine" */ './7CalculateAnglesOnAStraightLine'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'LengthsAndAnglesInShapes',
      questionTypes: ['aAu2', 'aAv2', 'aAw2', 'aAx2', 'aAy2', 'aAz'],
      archivedQuestionTypes: ['aAu', 'aAv', 'aAw', 'aAx', 'aAy'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Shape/8LengthsAndAnglesInShapes" */ './8LengthsAndAnglesInShapes'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'RegularAndIrregularPolygons',
      questionTypes: ['aAA', 'aAB', 'aAC', 'aAD', 'aAE', 'aAF'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/Shape/9RegularAndIrregularPolygons" */ './9RegularAndIrregularPolygons'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'Num3DShapes',
      questionTypes: ['aAG2', 'aAH2', 'aAI', 'aAJ2', 'aAK', 'aAL'],
      archivedQuestionTypes: ['aAG', 'aAH', 'aAJ'],
      module: () =>
        import(/* webpackChunkName: "Year5/Summer/Shape/10Num3DShapes" */ './10Num3DShapes').then(
          mod => mod.default
        )
    })
  ]
});
export default Block;
