import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'DecimalsB',
  field: 'Number',
  weeks: [1, 2],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'MakeAWholeWithTenths',
      questionTypes: ['awk', 'awl', 'awm', 'awn', 'awo2', 'awp2'],
      archivedQuestionTypes: ['awo', 'awp'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/DecimalsB/1MakeAWholeWithTenths" */ './1MakeAWholeWithTenths'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'MakeAWholeWithHundredths',
      questionTypes: ['awq', 'awr', 'aws', 'awt', 'awu', 'awv'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/DecimalsB/2MakeAWholeWithHundredths" */ './2MakeAWholeWithHundredths'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'PartitionDecimals',
      questionTypes: ['aww', 'awx', 'awy', 'awz', 'awA', 'awB'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/DecimalsB/3PartitionDecimals" */ './3PartitionDecimals'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'FlexiblyPartitionDecimals',
      questionTypes: ['awC', 'awD', 'awE', 'awF', 'awG', 'awH'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/DecimalsB/4FlexiblyPartitionDecimals" */ './4FlexiblyPartitionDecimals'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'CompareDecimals',
      questionTypes: ['awI', 'awJ', 'awK', 'awL', 'awM', 'awN'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/DecimalsB/5CompareDecimals" */ './5CompareDecimals'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'OrderDecimals',
      questionTypes: ['awO', 'awP2', 'awQ2', 'awR2', 'awS2', 'awT2'],
      archivedQuestionTypes: ['awP', 'awQ', 'awR', 'awS', 'awT'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/DecimalsB/6OrderDecimals" */ './6OrderDecimals'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'RoundToTheNearestWholeNumber',
      questionTypes: ['awU', 'awV', 'awW', 'awX', 'awY', 'awZ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/DecimalsB/7RoundToTheNearestWholeNumber" */ './7RoundToTheNearestWholeNumber'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'HalvesAndQuartersAsDecimals',
      questionTypes: ['aw02', 'aw1', 'aw22', 'aw3', 'aw4', 'aw5'],
      archivedQuestionTypes: ['aw0', 'aw2'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/DecimalsB/8HalvesAndQuartersAsDecimals" */ './8HalvesAndQuartersAsDecimals'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
