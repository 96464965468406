import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'PlaceValue',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'RepresentNumbersTo100',
      questionTypes: ['aaa', 'aab', 'aac', 'aad2', 'aae', 'aaf'],
      archivedQuestionTypes: ['aad'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/PlaceValue/1RepresentNumbersTo100" */ './1RepresentNumbersTo100'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'PartitionNumbersTo100',
      questionTypes: ['aag', 'aah', 'aai', 'aaj', 'aak', 'aal'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/PlaceValue/2PartitionNumbersTo100" */ './2PartitionNumbersTo100'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'NumberLineTo100',
      questionTypes: ['aam2', 'aan2', 'aao', 'aap', 'aaq', 'aar'],
      archivedQuestionTypes: ['aam', 'aan'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/PlaceValue/3NumberLineTo100" */ './3NumberLineTo100'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'Hundreds',
      questionTypes: ['aas', 'aat', 'aau', 'aav', 'aaw', 'aax'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year3/Autumn/PlaceValue/4Hundreds" */ './4Hundreds').then(
          mod => mod.default
        )
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'RepresentNumbersTo1000',
      questionTypes: ['aay', 'aaz', 'aaA', 'aaB2', 'aaC', 'aaD'],
      archivedQuestionTypes: ['aaB'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/PlaceValue/5RepresentNumbersTo1000" */ './5RepresentNumbersTo1000'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'PartitionNumbersTo1000',
      questionTypes: ['aaE', 'aaF', 'aaG', 'aaH', 'aaI', 'aaJ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/PlaceValue/6PartitionNumbersTo1000" */ './6PartitionNumbersTo1000'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'FlexiblePartitioningOfNumbersTo1000',
      questionTypes: ['aaK', 'aaL', 'aaM', 'aaN', 'aaO', 'aaP'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/PlaceValue/7FlexiblePartitioningOfNumbersTo1000" */ './7FlexiblePartitioningOfNumbersTo1000'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'HundredsTensAndOnes',
      questionTypes: ['aaQ', 'aaR', 'aaS', 'aaT', 'aaU', 'aaV'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/PlaceValue/8HundredsTensAndOnes" */ './8HundredsTensAndOnes'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'Find1To100MoreOrLess',
      questionTypes: ['aaW', 'aaX', 'aaY', 'aaZ', 'aa0', 'aa1'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/PlaceValue/9Find1To100MoreOrLess" */ './9Find1To100MoreOrLess'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'NumberLineTo1000',
      questionTypes: ['aa2', 'aa3', 'aa42', 'aa5', 'aa6', 'aa7'],
      archivedQuestionTypes: ['aa4'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/PlaceValue/10NumberLineTo1000" */ './10NumberLineTo1000'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'EstimateOnANumberLineTo1000',
      questionTypes: ['aa8', 'aa9', 'aba', 'abb', 'abc', 'abd'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/PlaceValue/11EstimateOnANumberLineTo1000" */ './11EstimateOnANumberLineTo1000'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'CompareNumbersTo1000',
      questionTypes: ['abe', 'abf', 'abg', 'abh', 'abi', 'abj'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/PlaceValue/12CompareNumbersTo1000" */ './12CompareNumbersTo1000'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'OrderNumbersTo1000',
      questionTypes: ['abk', 'abl', 'abm', 'abn', 'abo', 'abp'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/PlaceValue/13OrderNumbersTo1000" */ './13OrderNumbersTo1000'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'CountIn50s',
      questionTypes: ['abq', 'abr', 'abs', 'abt', 'abu', 'abv'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Autumn/PlaceValue/14CountIn50s" */ './14CountIn50s'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
