import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Time',
  field: 'Measurement',
  weeks: [5, 7],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'RomanNumeralsTo12',
      questionTypes: ['atC', 'atD', 'atE2', 'atF', 'atG', 'atH'],
      archivedQuestionTypes: ['atE'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Time/1RomanNumeralsTo12" */ './1RomanNumeralsTo12'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'TellTheTimeTo5Minutes',
      questionTypes: ['atI', 'atJ', 'atK', 'atL', 'atM', 'atN'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Time/2TellTheTimeTo5Minutes" */ './2TellTheTimeTo5Minutes'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'TellTheTimeToTheMinute',
      questionTypes: ['atO', 'atP', 'atQ', 'atR', 'atS', 'atT'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Time/3TellTheTimeToTheMinute" */ './3TellTheTimeToTheMinute'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'ReadTimeOnADigitalClock',
      questionTypes: ['atU', 'atV', 'atW', 'atX', 'atY', 'atZ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Time/4ReadTimeOnADigitalClock" */ './4ReadTimeOnADigitalClock'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'UseAMAndPM',
      questionTypes: ['at02', 'at1', 'at2', 'at32', 'at42', 'at52'],
      archivedQuestionTypes: ['at0', 'at3', 'at4', 'at5'],
      module: () =>
        import(/* webpackChunkName: "Year3/Summer/Time/5UseAMAndPM" */ './5UseAMAndPM').then(
          mod => mod.default
        )
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'YearsMonthsAndDays',
      questionTypes: ['at6', 'at7', 'at8', 'at9', 'aua2', 'aub'],
      archivedQuestionTypes: ['aua'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Time/6YearsMonthsAndDays" */ './6YearsMonthsAndDays'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'DaysAndHours',
      questionTypes: ['auc', 'aud', 'aue', 'auf', 'aug', 'auh2'],
      archivedQuestionTypes: ['auh'],
      module: () =>
        import(/* webpackChunkName: "Year3/Summer/Time/7DaysAndHours" */ './7DaysAndHours').then(
          mod => mod.default
        )
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'HoursAndMinutesUseStartAndEndTimes',
      questionTypes: ['aui', 'auj', 'auk', 'aul', 'aum2', 'aun'],
      archivedQuestionTypes: ['aum'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Time/8HoursAndMinutesUseStartAndEndTimes" */ './8HoursAndMinutesUseStartAndEndTimes'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'HoursAndMinutesUseDurations',
      questionTypes: ['auo', 'aup2', 'auq', 'aur', 'aus', 'aut'],
      archivedQuestionTypes: ['aup'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Time/9HoursAndMinutesUseDurations" */ './9HoursAndMinutesUseDurations'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'MinutesAndSeconds',
      questionTypes: ['auu', 'auv', 'auw', 'aux', 'auy', 'auz'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Time/10MinutesAndSeconds" */ './10MinutesAndSeconds'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'UnitsOfTime',
      questionTypes: ['auA', 'auB', 'auC', 'auD2', 'auE', 'auF'],
      archivedQuestionTypes: ['auD'],
      module: () =>
        import(/* webpackChunkName: "Year3/Summer/Time/11UnitsOfTime" */ './11UnitsOfTime').then(
          mod => mod.default
        )
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'SolveProblemsWithTime',
      questionTypes: ['auG', 'auH', 'auI', 'auJ', 'auK', 'auL'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Time/12SolveProblemsWithTime" */ './12SolveProblemsWithTime'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
