import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'LengthAndPerimeter',
  field: 'Measurement',
  weeks: [4, 6],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'MeasureInMetresAndCentimetres',
      questionTypes: ['aGA', 'aGB', 'aGC', 'aGD', 'aGE', 'aGF2'],
      archivedQuestionTypes: ['aGF'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/LengthAndPerimeter/1MeasureInMetresAndCentimetres" */ './1MeasureInMetresAndCentimetres'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'MeasureInMillimetres',
      questionTypes: ['aGG', 'aGH', 'aGI', 'aGJ', 'aGK2', 'aGL'],
      archivedQuestionTypes: ['aGK'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/LengthAndPerimeter/2MeasureInMillimetres" */ './2MeasureInMillimetres'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'MeasureInCentimetresAndMillimetres',
      questionTypes: ['aGM', 'aGN', 'aGO', 'aGP2', 'aGQ2', 'aGR2'],
      archivedQuestionTypes: ['aGP', 'aGQ', 'aGR'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/LengthAndPerimeter/3MeasureInCentimetresAndMillimetres" */ './3MeasureInCentimetresAndMillimetres'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'MetresCentimetresAndMillimetres',
      questionTypes: ['aGS2', 'aGT', 'aGU', 'aGV', 'aGW', 'aGX'],
      archivedQuestionTypes: ['aGS'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/LengthAndPerimeter/4MetresCentimetresAndMillimetres" */ './4MetresCentimetresAndMillimetres'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'EquivalentLengthsMetresAndCentimetres',
      questionTypes: ['aGY', 'aGZ', 'aG0', 'aG1', 'aG22', 'aG32'],
      archivedQuestionTypes: ['aG2', 'aG3'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/LengthAndPerimeter/5EquivalentLengthsMetresAndCentimetres" */ './5EquivalentLengthsMetresAndCentimetres'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'EquivalentLengthsCentimetresAndMillimetres',
      questionTypes: ['aG4', 'aG5', 'aG6', 'aG7', 'aG8', 'aG9'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/LengthAndPerimeter/6EquivalentLengthsCentimetresAndMillimetres" */ './6EquivalentLengthsCentimetresAndMillimetres'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'CompareLengths',
      questionTypes: ['aHa2', 'aHb', 'aHc', 'aHd', 'aHe', 'aHf'],
      archivedQuestionTypes: ['aHa'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/LengthAndPerimeter/7CompareLengths" */ './7CompareLengths'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'AddLengths',
      questionTypes: ['aHg', 'aHh2', 'aHi2', 'aHj2', 'aHk', 'aHl'],
      archivedQuestionTypes: ['aHh', 'aHi', 'aHj'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/LengthAndPerimeter/8AddLengths" */ './8AddLengths'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'SubtractLengths',
      questionTypes: ['aHm', 'aHn', 'aHo2', 'aHp2', 'aHq2', 'aHr2'],
      archivedQuestionTypes: ['aHo', 'aHp', 'aHq', 'aHr'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/LengthAndPerimeter/9SubtractLengths" */ './9SubtractLengths'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'WhatIsPerimeter',
      questionTypes: ['aHs', 'aHt', 'aHu', 'aHv', 'aHw', 'aHx'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/LengthAndPerimeter/10WhatIsPerimeter" */ './10WhatIsPerimeter'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'MeasurePerimeter',
      questionTypes: ['aHy', 'aHz2', 'aHA2', 'aHB', 'aHC', 'aHD'],
      archivedQuestionTypes: ['aHz', 'aHA'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/LengthAndPerimeter/11MeasurePerimeter" */ './11MeasurePerimeter'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'CalculatePerimeter',
      questionTypes: ['aHJ2', 'aHE', 'aHF3', 'aHG2', 'aHH', 'aHI'],
      archivedQuestionTypes: ['aHJ', 'aHF', 'aHG', 'aHF2'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/LengthAndPerimeter/12CalculatePerimeter" */ './12CalculatePerimeter'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
