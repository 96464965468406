import { Surface, TouchableRipple } from 'react-native-paper';
import useBreakpoints from '../hooks/useBreakpoints';
import Text from 'common/components/typography/Text';
import { useI18nContext } from '../i18n/i18n-react';
import { View } from 'react-native';

/**
 * Custom button to change school. Simply displays the school name, with the word "Change" underlined in bold below.
 * The whole thing acts like a button - not just the word "Change".
 *
 * Ideally we would just use react-native-paper's `<Button>` (which uses `<Surface>` and `<TouchableRipple>`), but
 * this doesn't support multi-line text, so instead we use {@link Surface} and {@link TouchableRipple} directly.
 */
export default function ChangeSchoolButton({
  schoolName,
  onPress
}: {
  schoolName: string;
  onPress: () => void;
}) {
  const translate = useI18nContext().LL;
  const resize = useBreakpoints().choose({ mobile: 0.7, tablet: 1 });

  return (
    <Surface elevation={0} style={{ height: 70 * resize, borderRadius: 999 }}>
      <TouchableRipple
        borderless
        onPress={onPress}
        style={{
          height: '100%',
          justifyContent: 'center',
          borderRadius: 999,
          paddingHorizontal: 30 * resize
        }}
      >
        <View style={{ alignItems: 'flex-end' }}>
          <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            variant="WRN400"
            style={{
              fontSize: 21.67 * resize,
              lineHeight: 32.5 * resize
            }}
          >
            {schoolName}
          </Text>
          <Text
            numberOfLines={1}
            variant="WRN700"
            style={{
              fontSize: 21.67 * resize,
              lineHeight: 32.5 * resize,
              textDecorationLine: 'underline',
              fontWeight: 'bold'
            }}
          >
            {translate.change()}
          </Text>
        </View>
      </TouchableRipple>
    </Surface>
  );
}
