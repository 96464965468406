import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'PlaceValue',
  field: 'Number',
  weeks: [7, 8],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'CountFrom50To100',
      questionTypes: ['beX', 'beY', 'beZ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/PlaceValue/1CountFrom50To100" */ './1CountFrom50To100'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'TensTo100',
      questionTypes: ['be0', 'be1', 'be2'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year1/Summer/PlaceValue/2TensTo100" */ './2TensTo100').then(
          mod => mod.default
        )
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'PartitionIntoTensAndOnes',
      questionTypes: ['be3', 'be4', 'be5'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/PlaceValue/3PartitionIntoTensAndOnes" */ './3PartitionIntoTensAndOnes'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'TheNumberLineTo100',
      questionTypes: ['be6', 'be7', 'be8'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/PlaceValue/4TheNumberLineTo100" */ './4TheNumberLineTo100'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'OneMoreOneLess',
      questionTypes: ['be9', 'bfa', 'bfb'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/PlaceValue/5OneMoreOneLess" */ './5OneMoreOneLess'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'CompareNumbersWithTheSameNumberOfTens',
      questionTypes: ['bfc', 'bfd', 'bfe'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/PlaceValue/6CompareNumbersWithTheSameNumberOfTens" */ './6CompareNumbersWithTheSameNumberOfTens'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'CompareAnyTwoNumbers',
      questionTypes: ['bff', 'bfg', 'bfh'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/PlaceValue/7CompareAnyTwoNumbers" */ './7CompareAnyTwoNumbers'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
