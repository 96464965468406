import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Statistics',
  weeks: [10, 11],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'InterpretPictograms',
      questionTypes: ['avK', 'avL', 'avM', 'avN', 'avO', 'avP'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Statistics/1InterpretPictograms" */ './1InterpretPictograms'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'DrawPictograms',
      questionTypes: ['avQ', 'avR', 'avS', 'avT', 'avU', 'avV'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Statistics/2DrawPictograms" */ './2DrawPictograms'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'InterpretBarCharts',
      questionTypes: ['avW', 'avX', 'avY', 'avZ', 'av0', 'av1'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Statistics/3InterpretBarCharts" */ './3InterpretBarCharts'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'DrawBarCharts',
      questionTypes: ['av2', 'av3', 'av4', 'av5', 'av6', 'av7'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Statistics/4DrawBarCharts" */ './4DrawBarCharts'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'CollectAndRepresentData',
      questionTypes: ['av82', 'av92', 'awa2', 'awb', 'awc', 'awd'],
      archivedQuestionTypes: ['av8', 'av9', 'awa'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Statistics/5CollectAndRepresentData" */ './5CollectAndRepresentData'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'TwoWayTables',
      questionTypes: ['awe', 'awf', 'awg', 'awh', 'awi', 'awj'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Statistics/6TwoWayTables" */ './6TwoWayTables'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
