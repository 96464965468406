import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Statistics',
  weeks: [7, 8],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'MakeTallyCharts',
      questionTypes: ['blc', 'bld', 'ble'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Statistics/1MakeTallyCharts" */ './1MakeTallyCharts'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'Tables',
      questionTypes: ['blf', 'blg', 'blh'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year2/Summer/Statistics/2Tables" */ './2Tables').then(
          mod => mod.default
        )
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'BlockDiagrams',
      questionTypes: ['bli', 'blj', 'blk'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Statistics/3BlockDiagrams" */ './3BlockDiagrams'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'DrawPictograms1To1',
      questionTypes: ['bll', 'blm', 'bln'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Statistics/4DrawPictograms1To1" */ './4DrawPictograms1To1'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'InterpretPictograms1To1',
      questionTypes: ['blo', 'blp', 'blq'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Statistics/5InterpretPictograms1To1" */ './5InterpretPictograms1To1'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'DrawPictograms25And10',
      questionTypes: ['blr', 'bls', 'blt'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Statistics/6DrawPictograms25And10" */ './6DrawPictograms25And10'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'InterpretPictograms25And10',
      questionTypes: ['blu', 'blv', 'blw'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Summer/Statistics/7InterpretPictograms25And10" */ './7InterpretPictograms25And10'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
