import { ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { colors } from '../../theme/colors';
import { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { noop } from '../../utils/flowControl';
import { AnimatedPressable } from './animatedWrappings';

type Props = {
  onPress?: () => void;
  /** Default: false */
  disabled?: boolean;
  children: ReactNode;
  baseColor?: string;
  onPressInColor?: string;
  /** Default: 64. */
  width?: number;
  /**
   * Default: Math.floor(width * 0.8 * Math.SQRT1_2),
   * i.e. as big as possible whilst avoiding the rounded corners with a little padding.
   */
  iconWidth?: number;
  style?: StyleProp<ViewStyle>;
  dataTestId?: string;
};

/**
 * Borderless button showing a single icon.
 */
export default function IconButton({
  onPress = noop,
  disabled = false,
  children = null,
  baseColor = 'white',
  onPressInColor = colors.grey300PressIn,
  width = 64,
  iconWidth,
  style,
  dataTestId
}: Props) {
  iconWidth = iconWidth ?? Math.floor(width * 0.8 * Math.SQRT1_2);
  const styles = useStyles(width, iconWidth);

  const animatedButtonColor = useSharedValue(baseColor);
  const animatedButtonColorStyle = useAnimatedStyle(
    () => ({
      backgroundColor: animatedButtonColor.value
    }),
    [animatedButtonColor]
  );

  return (
    <AnimatedPressable
      accessible={true}
      accessibilityRole="button"
      disabled={disabled}
      onPress={onPress}
      testID={dataTestId}
      onPressIn={() => {
        animatedButtonColor.value = withTiming(onPressInColor, { duration: 50 });
      }}
      onPressOut={() => {
        animatedButtonColor.value = withTiming(baseColor, { duration: 200 });
      }}
      style={[styles.button, style, animatedButtonColorStyle, { opacity: disabled ? 0.5 : 1 }]}
    >
      <View style={styles.iconContainer}>{children}</View>
    </AnimatedPressable>
  );
}

function useStyles(width: number, iconWidth: number) {
  return StyleSheet.create({
    button: {
      width: width,
      height: width,
      borderRadius: 999,
      alignItems: 'center',
      justifyContent: 'center'
    },
    iconContainer: {
      width: iconWidth,
      height: iconWidth,
      alignItems: 'center',
      justifyContent: 'center'
    }
  });
}
