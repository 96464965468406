import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Statistics',
  weeks: [11, 12],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'DrawLineGraphs',
      questionTypes: ['aSi', 'aSj', 'aSk', 'aSl', 'aSm', 'aSn'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/Statistics/1DrawLineGraphs" */ './1DrawLineGraphs'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'ReadAndInterpretLineGraphs',
      questionTypes: ['aSo', 'aSp', 'aSq', 'aSr', 'aSs', 'aSt'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/Statistics/2ReadAndInterpretLineGraphs" */ './2ReadAndInterpretLineGraphs'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'ReadAndInterpretTables',
      questionTypes: ['aSu', 'aSv', 'aSw', 'aSx', 'aSy', 'aSz'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/Statistics/3ReadAndInterpretTables" */ './3ReadAndInterpretTables'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'TwoWayTables',
      questionTypes: ['aSA', 'aSB', 'aSC', 'aSD2', 'aSE', 'aSF'],
      archivedQuestionTypes: ['aSD'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/Statistics/4TwoWayTables" */ './4TwoWayTables'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'ReadAndInterpretTimetables',
      questionTypes: ['aSG', 'aSH', 'aSI', 'aSJ', 'aSK2', 'aSL2'],
      archivedQuestionTypes: ['aSK', 'aSL'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Spring/Statistics/5ReadAndInterpretTimetables" */ './5ReadAndInterpretTimetables'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
