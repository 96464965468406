import { SystemBars } from 'react-native-edge-to-edge';
import { useCallback, useState } from 'react';
import { StyleSheet, View, Keyboard } from 'react-native';
import { RootStackProps } from '../navigation/types';
import { countRange } from 'common/utils/collections';
import FilledButton from '../components/FilledButton';
import Spinner from 'common/components/molecules/Spinner';
import { useHeaderHeight } from '@react-navigation/elements';
import useBreakpoints from '../hooks/useBreakpoints';
import useKeyboard from '../hooks/useKeyboard';
import useScreenDimensions from '../hooks/useScreenDimensions';
import { resolveFont } from 'common/theme/fonts';
import { colors } from 'common/theme/colors';
import { Image } from 'expo-image';
import { useI18nContext } from '../i18n/i18n-react';
import { type LocalizedString } from 'typesafe-i18n';
import { validatePupilAccessCode } from '../network/pupilAccessCode';
import TextInputRow from '../components/TextInputRow';
import { getPlayer } from 'common/utils/Audio';
import { createNewQuizSession } from '../network/quizSession';
import useQuizSessionStore from '../storage/useQuizSessionStore';
import { resetTo } from '../navigation/actions';
import Text from 'common/components/typography/Text';
import CantLoginButton from '../components/CantLoginButton';
import { Portal } from 'common/components/portal';
import CantLoginModal from '../components/CantLoginModal';
import useLoginStore from '../storage/useLoginStore';
import { showInfoToast } from '../components/Toast';
import {
  MINIMUM_QUESTION_HEIGHT,
  QUESTION_WIDTH,
  ScaleContent,
  containAspectRatio,
  useWindowLayout
} from 'common/theme/scaling';

const CODE_LENGTH = 3;

export default function EnterPupilAccessCode({
  navigation,
  route: { params }
}: RootStackProps<'EnterPupilAccessCode'>) {
  const translate = useI18nContext().LL;

  const [code, setCode] = useState<string[]>(countRange(CODE_LENGTH).map(() => ''));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{
    displayString: LocalizedString;
    /** Whether the error is because the code is invalid (rather than due to some other error) */
    isInvalid?: boolean;
  } | null>(null);
  const [modalActive, setModalActive] = useState(false);
  const { resize, type } = useBreakpoints();

  const schoolCode = useLoginStore(state => state.school!.code);
  const setLoggedInUser = useLoginStore(state => state.setLoggedInUser);
  const setQuizSession = useQuizSessionStore(state => state.setQuizSession);

  const onSuccessLaunchQuizPin = params?.onSuccessLaunchQuizPin;
  const onSuccessGoToEnterQuizPIN = params?.onSuccessGoToEnterQuizPIN;

  /**
   * Create a quiz session from quiz share, and navigate to QuizScreen if successful.
   *
   * This is only invoked from this screen when we've come from a scanned QR code.
   */
  const handleQuizCreation = useCallback(
    async (quizPin: string) => {
      setLoading(true);
      const response = await createNewQuizSession({
        learningGroupShareCode: schoolCode, // School code
        quizVersionShareShareCode: quizPin // Quiz code
      });

      if (typeof response === 'string') {
        // Creating quiz session failed, show an error message and hide the loading spinner
        setLoading(false);

        switch (response) {
          case 'invalid response':
          case 'unknown error':
          case 'http error':
            setError({
              displayString: translate.enterCodeOrPINScreen.somethingWentWrongError(),
              isInvalid: false
            });
            return;
          case 'network error':
            setError({
              displayString: translate.enterCodeOrPINScreen.internetConnectionLostError(),
              isInvalid: false
            });
            return;
          case 'not found':
            setError({
              displayString: translate.enterCodeOrPINScreen.quizNotFoundError(),
              isInvalid: false
            });
            return;
          case 'quiz locked':
            setError({
              displayString: translate.enterCodeOrPINScreen.quizLockedError(),
              isInvalid: false
            });
            return;
          default:
            // Produces TS error and throws runtime error if we missed a case
            throw new Error(`Logic error: unreachable (${response satisfies never})`);
        }
      } else {
        // Success
        getPlayer(response.quizSounds);
        setQuizSession({ ...response, questionResults: response.questionResults ?? [] });
        setLoading(false);

        // Get login info in user directly from store for the most up-to-date information. Using the hook to get this
        // information in a React-y way would only give us the state when the last react render occurred, which might
        // be too late
        const { loggedInUser, school } = useLoginStore.getState();

        if (school?.hasInfinityPlus && !loggedInUser) {
          showInfoToast({
            title: translate.infoModals.notLoggedIn(),
            message: translate.infoModals.resultsNotSaved(),
            extraDuration: 1000,
            resize: resize
          });
        }

        if (loggedInUser) {
          navigation.dispatch(resetTo({ name: 'PupilHome' }, { name: 'Quiz' }));
        } else {
          navigation.replace('Quiz');
        }
      }
    },
    [
      navigation,
      resize,
      schoolCode,
      setQuizSession,
      translate.enterCodeOrPINScreen,
      translate.infoModals
    ]
  );

  const screenDimensions = useScreenDimensions();
  const keyboard = useKeyboard();
  const headerHeight = useHeaderHeight();

  const onContinueClicked = useCallback(async () => {
    const pupilCode = code.join('-').toUpperCase();

    // Local validation - check it's exactly 9 letters with dashes after each 3
    if (!/^[A-Z]{3}-[A-Z]{3}-[A-Z]{3}$/.test(pupilCode)) {
      setError({
        displayString: translate.enterCodeOrPINScreen.invalidPupilAccessCode(),
        isInvalid: true
      });
      return;
    }

    // API validation - check the pupil exists
    setLoading(true);
    const response = await validatePupilAccessCode(pupilCode, schoolCode);
    if (typeof response === 'string') {
      // API validation failed, show an error message and hide the loading spinner
      setLoading(false);

      switch (response) {
        case 'invalid response':
        case 'unknown error':
        case 'network error':
          setError({ displayString: translate.enterCodeOrPINScreen.internetConnectionLostError() });
          return;
        case 'not found':
          setError({
            displayString: translate.enterCodeOrPINScreen.invalidPupilAccessCode(),
            isInvalid: true
          });
          return;
        case 'http error':
          setError({
            displayString: translate.enterCodeOrPINScreen.somethingWentWrongError(),
            isInvalid: true
          });
          return;
        default:
          // Produces TS error and throws runtime error if we missed a case
          throw new Error(`Logic error: unreachable (${response satisfies never})`);
      }
    } else {
      // Successfully logged in - update store
      setLoggedInUser({
        authTokens: {
          token: response.token,
          refreshToken: response.refreshToken
        },
        profile: {
          firstName: response.firstName,
          lastName: response.lastName,
          studentId: response.studentId
        }
      });

      setLoading(false);

      if (onSuccessGoToEnterQuizPIN) {
        // User was redirected here because they needed to login after entering a school code
        navigation.dispatch(resetTo({ name: 'PupilHome' }, { name: 'EnterQuizPIN' }));
      } else if (onSuccessLaunchQuizPin) {
        // User was redirected here because they needed to login after scanning a QR code, and they are now logged in
        handleQuizCreation(onSuccessLaunchQuizPin);
      } else {
        // Navigate to pupil homepage
        navigation.dispatch(resetTo({ name: 'PupilHome' }));
      }
    }
  }, [
    code,
    schoolCode,
    translate.enterCodeOrPINScreen,
    setLoggedInUser,
    onSuccessGoToEnterQuizPIN,
    onSuccessLaunchQuizPin,
    handleQuizCreation,
    navigation
  ]);

  // Available height, after subtracting the keyboard and header (assumes app is full screen)
  const availableHeight =
    screenDimensions.height - headerHeight - (keyboard.keyboardShown ? keyboard.keyboardHeight : 0);

  // When keyboard is showing, there might not be enough space to show the continue button
  const showShorterLayoutBecauseKeyboard = keyboard.keyboardShown && availableHeight < 300 * resize;

  const loadingSpinner = (
    <View
      style={[
        StyleSheet.absoluteFill,
        { backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', gap: 24 }
      ]}
    >
      <Spinner height={showShorterLayoutBecauseKeyboard ? 100 * resize : 156 * resize} />
      {type !== 'mobile' && (
        <Text
          style={resolveFont({
            fontFamily: 'White_Rose_Noto',
            fontWeight: '400',
            fontSize: 32,
            lineHeight: 48,
            color: colors.prussianBlue
          })}
        >
          {translate.loadingEllipsis()}
        </Text>
      )}
    </View>
  );

  const textInputRow = (
    <TextInputRow
      maxInputLength={3}
      shape="l-l-l"
      code={code}
      setCode={action => {
        setCode(action);
        // Whenever the user changes the code, hide any error messages
        setError(null);
      }}
      onFinalEnter={onContinueClicked}
      // Highlight the input boxes as errored if it was the code itself being invalid that caused the error
      error={error?.isInvalid}
      autoFocus
    />
  );

  const errorText = (
    <View testID="ERROR_MESSAGE" style={{ flexDirection: 'row', alignItems: 'center', gap: 12 }}>
      {error !== null && (
        <Image
          source={require('pupil-app/assets/svg/InfoIcon.svg')}
          style={{ width: 27 * resize, height: 28 * resize }}
        />
      )}
      <Text
        style={resolveFont({
          fontFamily: 'White_Rose_Noto',
          fontWeight: '700',
          fontSize: 21.67 * resize,
          lineHeight: 32.5 * resize,
          color: colors.danger
        })}
      >
        {error?.displayString}
      </Text>
    </View>
  );

  const continueButton = (
    <FilledButton
      icon={() => (
        <Image
          source={require('pupil-app/assets/svg/RightArrow.svg')}
          style={{ width: 48 * resize, height: 48 * resize }}
        />
      )}
      iconOnRight
      onPress={onContinueClicked}
      buttonWidth={320 * resize}
    >
      Continue
    </FilledButton>
  );

  ////
  // Auto-scaling for modal
  ////
  const { windowDimensions, insets } = useWindowLayout();

  const screenSafeWidth = windowDimensions.width - insets.left - insets.right;
  const screenSafeHeight = windowDimensions.height - insets.top - insets.bottom;
  const modalActualDimens = containAspectRatio(
    { width: screenSafeWidth, height: screenSafeHeight },
    QUESTION_WIDTH / MINIMUM_QUESTION_HEIGHT
  );
  const modalScaleFactor = modalActualDimens.width / QUESTION_WIDTH;

  const modal = modalActive && (
    <ScaleContent dimens={windowDimensions} scaleFactor={modalScaleFactor} skipPortalHost>
      <CantLoginModal
        onDismiss={() => setModalActive(false)}
        onConfirm={() => {
          if (onSuccessGoToEnterQuizPIN) {
            // User was redirected here because they needed to login after entering a school code
            // But now they've given up on logging in
            navigation.replace('EnterQuizPIN');
          } else if (onSuccessLaunchQuizPin) {
            // User was redirected here because they needed to login after scanning a QR code
            // But now they've given up on logging in
            handleQuizCreation(onSuccessLaunchQuizPin);
          } else {
            // Shouldn't be possible for the modal to show and neither of these be the case
            console.warn('CantLoginModal active when not redirected.');
          }
          setModalActive(false);
        }}
      />
    </ScaleContent>
  );

  return (
    <>
      <SystemBars style="dark" />
      {showShorterLayoutBecauseKeyboard ? (
        <View
          style={{
            flex: 1,
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginBottom: keyboard.keyboardHeight,
            minWidth: Math.min(760, screenDimensions.width)
          }}
        >
          {textInputRow}
          <View
            style={{
              alignSelf: 'stretch',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 20 * resize
            }}
          >
            {errorText}
            {(onSuccessGoToEnterQuizPIN || onSuccessLaunchQuizPin) && (
              <CantLoginButton
                onPress={() => {
                  Keyboard.dismiss();
                  setModalActive(true);
                }}
              />
            )}
          </View>
          {loading && loadingSpinner}
        </View>
      ) : (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 32 * resize,
            minWidth: Math.min(760, screenDimensions.width)
          }}
        >
          {textInputRow}
          {error !== null && errorText}
          {continueButton}
          {/* Can't login button */}
          {(onSuccessGoToEnterQuizPIN || onSuccessLaunchQuizPin) && (
            <CantLoginButton onPress={() => setModalActive(true)} />
          )}
          {loading && loadingSpinner}
        </View>
      )}
      <Portal>{modal}</Portal>
    </>
  );
}
