import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'AreaPerimeterAndVolume',
  field: 'Measurement',
  weeks: [9, 10],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'ShapesSameArea',
      questionTypes: ['aWs', 'aWt', 'aWu', 'aWv', 'aWw', 'aWx'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/AreaPerimeterAndVolume/1ShapesSameArea" */ './1ShapesSameArea'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'AreaAndPerimeter',
      questionTypes: ['aWy2', 'aWz2', 'aWA', 'aWB', 'aWC', 'aWD'],
      archivedQuestionTypes: ['aWy', 'aWz'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/AreaPerimeterAndVolume/2AreaAndPerimeter" */ './2AreaAndPerimeter'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'AreaOfATriangleCountingSquares',
      questionTypes: ['aWE', 'aWF', 'aWG', 'aWH2', 'aWI', 'aWJ'],
      archivedQuestionTypes: ['aWH'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/AreaPerimeterAndVolume/3AreaOfATriangleCountingSquares" */ './3AreaOfATriangleCountingSquares'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'AreaOfARightAngledTriangle',
      questionTypes: ['aWK', 'aWL', 'aWM', 'aWN', 'aWO', 'aWP2'],
      archivedQuestionTypes: ['aWP'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/AreaPerimeterAndVolume/4AreaOfARightAngledTriangle" */ './4AreaOfARightAngledTriangle'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'AreaOfAnyTriangle',
      questionTypes: ['aWQ2', 'aWR2', 'aWS2', 'aWU', 'aWT', 'aWV'],
      archivedQuestionTypes: ['aWQ', 'aWR', 'aWS'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/AreaPerimeterAndVolume/5AreaOfAnyTriangle" */ './5AreaOfAnyTriangle'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'AreaOfAParallelogram',
      questionTypes: ['aWW', 'aWX', 'aWY2', 'aWZ', 'aW0', 'aW12'],
      archivedQuestionTypes: ['aWY', 'aW1'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/AreaPerimeterAndVolume/6AreaOfAParallelogram" */ './6AreaOfAParallelogram'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'VolumeCountingCubes',
      questionTypes: ['aW2', 'aW32', 'aW4', 'aW52', 'aW6', 'aW72'],
      archivedQuestionTypes: ['aW3', 'aW5', 'aW7'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/AreaPerimeterAndVolume/7VolumeCountingCubes" */ './7VolumeCountingCubes'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'VolumeOfACuboid',
      questionTypes: ['aW8', 'aW92', 'aXa', 'aXb2', 'aXc2', 'aXd'],
      archivedQuestionTypes: ['aW9', 'aXb', 'aXc'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/AreaPerimeterAndVolume/8VolumeOfACuboid" */ './8VolumeOfACuboid'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
