import { useCallback, useContext } from 'react';
import useScreenDimensions from './useScreenDimensions';
import { Platform } from 'react-native';
import { ScaleContentContext } from 'common/theme/scaling';

const devices = ['mobile', 'tablet'] as const;
type Device = 'mobile' | 'tablet';
type ChooseFunction = <T>(options: [T, T] | { mobile: T; tablet: T }) => T;

export default function useBreakpoints(): { type: Device; choose: ChooseFunction; resize: number } {
  const screenDimensions = useScreenDimensions();
  // For some reason on native sometimes the width and height get reversed. Since we lock to landscape, we just care about the smaller dimension.
  const screenHeight =
    Platform.OS === 'web'
      ? screenDimensions.height
      : Math.min(screenDimensions.height, screenDimensions.width);

  let device: Device;
  if (screenHeight <= 480) {
    device = 'mobile';
  } else {
    device = 'tablet';
  }

  const choose = useCallback<ChooseFunction>(
    options => (Array.isArray(options) ? options[devices.indexOf(device)] : options[device]),
    [device]
  );

  /**
   * We have two ways we currently handle screen scaling in the app.
   * 1. discrete form factors, such as mobile and tablet, with a `resize` number
   * 2. automatically using a scaling transform to fit the largest 16:9 rectangle in the screen.
   *
   * The hook we're in, useBreakpoints, is to help power option 1. However, if we're already using option 2, then we
   * don't want to use both, so just set resize to 1 (no change) in that case.
   */
  const usesAutoScaling = useContext(ScaleContentContext).ref !== undefined;

  const resize = usesAutoScaling ? 1 : choose({ mobile: 0.7, tablet: 1 });

  return { type: device, choose, resize };
}
