import { useMemo } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { useTheme } from '../../theme';
import { colors } from '../../theme/colors';
import { ElementOrRenderFunction, resolveElementOrRenderFunction } from '../../utils/react';
import Text from '../typography/Text';
import { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { AnimatedPressable } from './animatedWrappings';

type Props = {
  onPress?: () => void;
  onLongPress?: () => void;
  /** Default: false */
  disabled?: boolean;
  children?: string | ElementOrRenderFunction;
  variant?: 'circle' | 'oval';
  baseColor?: string;
  onPressInColor?: string;
  style?: StyleProp<ViewStyle>;
  testId?: string;
};

/**
 * Rounded button.
 */
export default function Button({
  onPress,
  onLongPress,
  disabled = false,
  children = null,
  variant = 'oval',
  baseColor = 'white',
  onPressInColor = colors.grey300PressIn,
  style,
  testId
}: Props) {
  const styles = useStyles(variant);

  const animatedButtonColor = useSharedValue(baseColor);
  const animatedButtonColorStyle = useAnimatedStyle(
    () => ({
      backgroundColor: animatedButtonColor.value
    }),
    [animatedButtonColor]
  );

  return (
    <AnimatedPressable
      accessible={true}
      accessibilityRole="button"
      disabled={disabled}
      // Note: deliberately not passed in like `onPress={onPress}`, since we want to avoid passing in the event.
      // Even though onPress's function signature requires no arguments, it might still be a function which takes
      // optional arguments, and could then accidentally treat the event GestureResponderEvent as its argument.
      onPress={() => onPress && onPress()}
      onLongPress={() => onLongPress && onLongPress()}
      testID={testId}
      onPressIn={() => {
        animatedButtonColor.value = withTiming(onPressInColor, { duration: 50 });
      }}
      onPressOut={() => {
        animatedButtonColor.value = withTiming(baseColor, { duration: 200 });
      }}
      style={[styles.button, style, animatedButtonColorStyle]}
    >
      {typeof children === 'string' ? (
        <Text variant="ButtonLarge">{children}</Text>
      ) : (
        resolveElementOrRenderFunction(children)
      )}
    </AnimatedPressable>
  );
}

Button.OVAL_WIDTH = 320 as const;
Button.CIRCLE_WIDTH = 96 as const;
Button.HEIGHT = 96 as const;

function useStyles(variant: 'circle' | 'oval') {
  const theme = useTheme();

  return useMemo(
    () =>
      StyleSheet.create({
        button: {
          paddingTop: 16,
          paddingBottom: 20,
          width: variant === 'circle' ? Button.CIRCLE_WIDTH : Button.OVAL_WIDTH,
          height: Button.HEIGHT,
          borderRadius: theme.buttonBorderRadius,
          borderWidth: theme.buttonBorderWidth,
          borderColor: theme.colors.primary,
          alignItems: 'center',
          justifyContent: 'center'
        }
      }),
    [theme, variant]
  );
}
