import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'AdditionSubtractionMultiplicationAndDivision',
  field: 'Number',
  weeks: [3, 7],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'AddAndSubtractIntegers',
      questionTypes: ['ajy', 'ajz', 'ajA2', 'ajB', 'ajC', 'ajD'],
      archivedQuestionTypes: ['ajA'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/1AddAndSubtractIntegers" */ './1AddAndSubtractIntegers'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'CommonFactors',
      questionTypes: ['anj', 'ank', 'anl', 'anm', 'ann', 'ano'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/2CommonFactors" */ './2CommonFactors'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'CommonMultiples',
      questionTypes: ['anp', 'anq', 'anr', 'ans2', 'ant', 'anu'],
      archivedQuestionTypes: ['ans'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/3CommonMultiples" */ './3CommonMultiples'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'RulesOfDivisibility',
      questionTypes: ['anv', 'anw', 'anx', 'any', 'anz', 'anA'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/4RulesOfDivisibility" */ './4RulesOfDivisibility'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'PrimesTo100',
      questionTypes: ['anB', 'anC', 'anD', 'anE', 'anF', 'anG'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/5PrimesTo100" */ './5PrimesTo100'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'SquareAndCubeNumbers',
      questionTypes: ['anH', 'anI', 'anJ2', 'anK', 'anL', 'anM'],
      archivedQuestionTypes: ['anJ'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/6SquareAndCubeNumbers" */ './6SquareAndCubeNumbers'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'MultiplyUpToA4DigitNumberByA2DigitNumber',
      questionTypes: ['aou', 'aov2', 'aow', 'aox2', 'aoy', 'aoz'],
      archivedQuestionTypes: ['aov', 'aox'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/7MultiplyUpToA4DigitNumberByA2DigitNumber" */ './7MultiplyUpToA4DigitNumberByA2DigitNumber'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'SolveProblemsWithMultiplication',
      questionTypes: ['aoA', 'aoB', 'aoC', 'aoD', 'aoE', 'aoF'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/8SolveProblemsWithMultiplication" */ './8SolveProblemsWithMultiplication'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'ShortDivision',
      questionTypes: ['aoG', 'aoH', 'aoI', 'aoJ', 'aoK', 'aoL'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/9ShortDivision" */ './9ShortDivision'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'DivisionUsingFactors',
      questionTypes: ['aoM', 'aoN', 'aoO', 'aoP', 'aoQ', 'aoR'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/10DivisionUsingFactors" */ './10DivisionUsingFactors'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'IntroductionToLongDivision',
      questionTypes: ['apK', 'arH', 'arI2', 'arJ', 'arK', 'arL'],
      archivedQuestionTypes: ['arI'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/11IntroductionToLongDivision" */ './11IntroductionToLongDivision'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'LongDivisionWithRemainders',
      questionTypes: ['arM', 'arN', 'arO', 'arP', 'arQ', 'arR'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/12LongDivisionWithRemainders" */ './12LongDivisionWithRemainders'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'SolveProblemsWithDivision',
      questionTypes: ['arS', 'arT', 'arU', 'arV', 'arW', 'arX'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/13SolveProblemsWithDivision" */ './13SolveProblemsWithDivision'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'SolveMultiStepProblems',
      questionTypes: ['arY', 'arZ', 'ar02', 'ar1', 'ar2', 'ar3'],
      archivedQuestionTypes: ['ar0'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/14SolveMultiStepProblems" */ './14SolveMultiStepProblems'
        ).then(mod => mod.default)
    }),
    // Step 15
    newSmallStepContent({
      smallStep: 'OrderOfOperations',
      questionTypes: ['anN', 'anO', 'anP', 'anQ', 'anR', 'anS'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/15OrderOfOperations" */ './15OrderOfOperations'
        ).then(mod => mod.default)
    }),
    // Step 16
    newSmallStepContent({
      smallStep: 'MentalCalculationsAndEstimation',
      questionTypes: ['anT', 'anU', 'anV', 'anW', 'anX', 'anY'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/16MentalCalculationsAndEstimation" */ './16MentalCalculationsAndEstimation'
        ).then(mod => mod.default)
    }),
    // Step 17
    newSmallStepContent({
      smallStep: 'ReasonFromKnownFacts',
      questionTypes: ['ar4', 'ar5', 'ar6', 'ar7', 'ar8', 'ar9'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/AdditionSubtractionMultiplicationAndDivision/17ReasonFromKnownFacts" */ './17ReasonFromKnownFacts'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
