import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Money',
  field: 'Measurement',
  weeks: [9, 9],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'Unitising',
      questionTypes: ['bfi', 'bfj', 'bfk'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year1/Summer/Money/1Unitising" */ './1Unitising').then(
          mod => mod.default
        )
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'RecogniseCoins',
      questionTypes: ['bfl', 'bfm', 'bfn'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/Money/2RecogniseCoins" */ './2RecogniseCoins'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'RecogniseNotes',
      questionTypes: ['bfo', 'bfp', 'bfq'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/Money/3RecogniseNotes" */ './3RecogniseNotes'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'CountInCoins',
      questionTypes: ['bfr', 'bfs', 'bft'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year1/Summer/Money/4CountInCoins" */ './4CountInCoins').then(
          mod => mod.default
        )
    })
  ]
});
export default Block;
