import { NavigationContainer, Theme, useNavigationState } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import type { RootStackParamList } from './types';
import HomeScreen from '../screens/HomeScreen';
import QuizScreen from '../screens/QuizScreen';
import { linking } from './linking';
import ScanQRScreen from '../screens/ScanQRScreen';
import EnterSchoolCodeScreen from '../screens/EnterSchoolCodeScreen';
import EnterQuizPINScreen from '../screens/EnterQuizPINScreen';
import EnterPupilAccessCode from '../screens/EnterPupilAccessCode';
import { resolveFont } from 'common/theme/fonts';
import useLoginStore from '../storage/useLoginStore';
import ImportQuizScreen from '../screens/ImportQuizScreen';
import useBreakpoints from '../hooks/useBreakpoints';
import useQuizSessionStore from '../storage/useQuizSessionStore';
import CustomHeader from '../components/CustomHeader';
import { useI18nContext } from '../i18n/i18n-react';
import ChangeSchoolButton from '../components/ChangeSchoolButton';
import PupilHomeScreen from '../screens/PupilHomeScreen';
import PupilMenuScreen from '../screens/PupilMenuScreen';
import { Platform, View } from 'react-native';
import { Image } from 'expo-image';
import SocialMediaGameScreen from '../socialMediaGame/screens/SocialMediaGameScreen';
import { LogoutWrapper } from '../components/LogoutWrapper';
import Text from 'common/components/typography/Text';
import { colors } from 'common/theme/colors';

const RootStack = createNativeStackNavigator<RootStackParamList>();

export default function Navigation({ theme, onReady }: { theme: Theme; onReady?: () => void }) {
  const translate = useI18nContext().LL;

  const school = useLoginStore(state => state.school);
  const clearSchool = useLoginStore(state => state.clearSchool);
  const quizSession = useQuizSessionStore(state => state.quizSession);
  const clearQuizSession = useQuizSessionStore(state => state.clearQuizSession);
  const loggedInUser = useLoginStore(state => state.loggedInUser);

  const resize = useBreakpoints().choose({ mobile: 0.7, tablet: 1 });

  return (
    <NavigationContainer theme={theme} linking={linking} onReady={onReady}>
      <LogoutWrapper>
        <RootStack.Navigator
          initialRouteName={
            // If a user is not logged but has a quizSession with results, resume the quiz
            !loggedInUser && quizSession?.questionResults
              ? 'Quiz'
              : loggedInUser
              ? 'PupilHome'
              : 'Home'
          }
          screenOptions={{
            gestureEnabled: false,
            headerTitleStyle: resolveFont({
              fontFamily: 'White_Rose_Noto',
              fontWeight: '700',
              fontSize: 40 * resize,
              lineHeight: 60 * resize
            }),
            headerTitleAlign: 'center'
          }}
        >
          <RootStack.Screen
            name="Home"
            component={HomeScreen}
            options={{ title: translate.titles.infinity(), headerShown: false }}
          />

          <RootStack.Screen
            name="EnterSchoolCode"
            component={EnterSchoolCodeScreen}
            options={{
              title: translate.titles.enterSchoolCode(),
              // use custom headers as figmas require more height
              header: ({ navigation }) => (
                <CustomHeader
                  title={translate.titles.enterSchoolCode()}
                  onPress={() => navigation.goBack()}
                />
              )
            }}
          />

          <RootStack.Screen
            name="EnterQuizPIN"
            component={EnterQuizPINScreen}
            options={() => ({
              title: translate.titles.enterQuizPin(),
              header: ({ navigation }) => (
                <CustomHeader
                  title={translate.titles.enterQuizPin()}
                  onPress={() => navigation.goBack()}
                  rightButton={
                    school !== undefined && !loggedInUser ? (
                      <ChangeSchoolButton
                        onPress={() => {
                          clearSchool();
                          clearQuizSession();
                          navigation.replace('EnterSchoolCode', { nextScreen: 'EnterQuizPIN' });
                        }}
                        schoolName={school.name}
                      />
                    ) : undefined
                  }
                />
              )
            })}
          />

          <RootStack.Screen
            name="ScanQR"
            component={ScanQRScreen}
            options={{
              // use custom headers as figmas require more height
              header: ({ navigation }) => (
                <CustomHeader
                  title={translate.titles.scanQrCode()}
                  onPress={() => navigation.goBack()}
                  color="white"
                  backgroundColor="black"
                />
              ),
              title: translate.titles.scanQrCode()
            }}
          />

          <RootStack.Screen
            name="Quiz"
            component={QuizScreen}
            options={() => ({
              title: quizSession?.name,
              headerShown: false,
              animation: 'fade'
            })}
          />

          <RootStack.Group screenOptions={{ presentation: 'modal' }}>
            <RootStack.Screen
              name="ImportQuiz"
              component={ImportQuizScreen}
              options={{ title: translate.titles.loadingQuiz(), headerShown: false }}
            />
          </RootStack.Group>

          <RootStack.Screen
            name="EnterPupilAccessCode"
            component={EnterPupilAccessCode}
            options={{
              title: translate.titles.enterPupilAccessCode(),
              header: ({ navigation, route }) => (
                <CustomHeader
                  title={translate.titles.enterPupilAccessCode()}
                  onPress={() => {
                    navigation.goBack();
                  }}
                  rightButton={
                    school !== undefined ? (
                      <ChangeSchoolButton
                        onPress={() => {
                          clearSchool();
                          clearQuizSession();
                          if (
                            (route.params as RootStackParamList['EnterPupilAccessCode'])
                              ?.onSuccessGoToEnterQuizPIN
                          ) {
                            // We're on EnterPupilAccessCode because the user was trying to enter a Quiz PIN and got
                            // redirected.
                            navigation.replace('EnterSchoolCode', {
                              nextScreen: 'EnterQuizPIN'
                            });
                          } else {
                            navigation.replace('EnterSchoolCode', {
                              nextScreen: 'EnterPupilAccessCode'
                            });
                          }
                        }}
                        schoolName={school.name}
                      />
                    ) : undefined
                  }
                />
              )
            }}
          />

          <RootStack.Screen
            name="PupilHome"
            component={PupilHomeScreen}
            options={{ title: translate.titles.infinity(), headerShown: false }}
          />

          <RootStack.Screen
            name="PupilMenu"
            component={PupilMenuScreen}
            options={{
              title: translate.titles.menu(),
              header: ({ navigation }) => (
                <CustomHeader
                  title={translate.titles.menu()}
                  color={colors.white}
                  backgroundColor={colors.prussianBlue}
                  borderBottomColor={colors.greys700}
                  onPress={() => navigation.goBack()}
                  leftButton={
                    <View style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                      <Image
                        source={require('pupil-app/assets/svg/Close.svg')}
                        style={{ width: 23 * resize, height: 23 * resize }}
                      />
                      <Text
                        variant="WRN700"
                        style={{
                          color: colors.white,
                          fontSize: 30 * resize,
                          lineHeight: 38 * resize
                        }}
                      >
                        {translate.misc.close()}
                      </Text>
                    </View>
                  }
                />
              )
            }}
          />

          {/* Screens for the social-media game (web-only) */}
          {Platform.OS === 'web' && (
            <RootStack.Screen
              name="SocialMediaGame"
              component={SocialMediaGameScreen}
              options={{ headerShown: false, title: 'Infinity - have a go!' }}
            />
          )}
        </RootStack.Navigator>
      </LogoutWrapper>

      {__DEV__ && <LogNavigationState />}
    </NavigationContainer>
  );
}

/** In development, this is used to log out the current navigation stack. */
function LogNavigationState() {
  console.log(
    '[Debug] Navigation stack: ' +
      JSON.stringify(useNavigationState(state => state?.routes?.map(it => it.key ?? it.name)))
  );
  return null;
}
