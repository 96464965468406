import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Money',
  field: 'Measurement',
  weeks: [3, 4],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'WriteMoneyUsingDecimals',
      questionTypes: ['aw6', 'aw7', 'aw8', 'aw9', 'axa', 'axb'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Money/1WriteMoneyUsingDecimals" */ './1WriteMoneyUsingDecimals'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'ConvertBetweenPoundsAndPence',
      questionTypes: ['axc2', 'axd2', 'axe2', 'axf', 'axg', 'axh'],
      archivedQuestionTypes: ['axc', 'axd', 'axe'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Money/2ConvertBetweenPoundsAndPence" */ './2ConvertBetweenPoundsAndPence'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'CompareAmountsOfMoney',
      questionTypes: ['axi', 'axj', 'axk', 'axl', 'axm', 'axn'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Money/3CompareAmountsOfMoney" */ './3CompareAmountsOfMoney'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'EstimateWithMoney',
      questionTypes: ['axo', 'axp', 'axq', 'axr2', 'axs', 'axt'],
      archivedQuestionTypes: ['axr'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Money/4EstimateWithMoney" */ './4EstimateWithMoney'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'CalculateWithMoney',
      questionTypes: ['axu', 'axv', 'axw', 'axx2', 'axy', 'axz'],
      archivedQuestionTypes: ['axx'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Money/5CalculateWithMoney" */ './5CalculateWithMoney'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'SolveProblemsWithMoney',
      questionTypes: ['axA', 'axB', 'axC', 'axD', 'axE', 'axF2'],
      archivedQuestionTypes: ['axF'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Summer/Money/6SolveProblemsWithMoney" */ './6SolveProblemsWithMoney'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
