import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Fractions',
  field: 'Number',
  weeks: [6, 9],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'UnderstandTheWhole',
      questionTypes: ['aL6', 'aL7', 'aL82', 'aL9', 'aMa2', 'aMb'],
      archivedQuestionTypes: ['aL8', 'aMa'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/Fractions/1UnderstandTheWhole" */ './1UnderstandTheWhole'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'CountBeyond1',
      questionTypes: ['aMc', 'aMd', 'aMe3', 'aMf2', 'aMg', 'aMh'],
      archivedQuestionTypes: ['aMf', 'aMe', 'aMe2'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/Fractions/2CountBeyond1" */ './2CountBeyond1'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'PartitionAMixedNumber',
      questionTypes: ['aMi', 'aMj', 'aMk', 'aMl2', 'aMm2', 'aMn'],
      archivedQuestionTypes: ['aMl', 'aMm'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/Fractions/3PartitionAMixedNumber" */ './3PartitionAMixedNumber'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'NumberLinesWithMixedNumbers',
      questionTypes: ['aMo', 'aMp', 'aMq', 'aMr', 'aMs', 'aMt'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/Fractions/4NumberLinesWithMixedNumbers" */ './4NumberLinesWithMixedNumbers'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'CompareAndOrderMixedNumbers',
      questionTypes: ['aMu2', 'aMv2', 'aMw2', 'aMx2', 'aMy2', 'aMz2'],
      archivedQuestionTypes: ['aMu', 'aMv', 'aMw', 'aMx', 'aMy', 'aMz'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/Fractions/5CompareAndOrderMixedNumbers" */ './5CompareAndOrderMixedNumbers'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'UnderstandImproperFractions',
      questionTypes: ['aMA2', 'aMB', 'aMC2', 'aMD2', 'aME', 'aMF'],
      archivedQuestionTypes: ['aMA', 'aMC', 'aMD'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/Fractions/6UnderstandImproperFractions" */ './6UnderstandImproperFractions'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'ConvertMixedNumbersToImproperFractions',
      questionTypes: ['aMG2', 'aMH', 'aMI', 'aMJ', 'aMK', 'aML2'],
      archivedQuestionTypes: ['aMG', 'aML'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/Fractions/7ConvertMixedNumbersToImproperFractions" */ './7ConvertMixedNumbersToImproperFractions'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'ConvertImproperFractionsToMixedNumbers',
      questionTypes: ['aMM', 'aMN', 'aMO2', 'aMP', 'aMQ', 'aMR'],
      archivedQuestionTypes: ['aMO'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/Fractions/8ConvertImproperFractionsToMixedNumbers" */ './8ConvertImproperFractionsToMixedNumbers'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'EquivalentFractionsOnANumberLine',
      questionTypes: ['aMS2', 'aMT', 'aMU2', 'aMV2', 'aMW', 'aMX'],
      archivedQuestionTypes: ['aMS', 'aMU', 'aMV'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/Fractions/9EquivalentFractionsOnANumberLine" */ './9EquivalentFractionsOnANumberLine'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'EquivalentFractionFamilies',
      questionTypes: ['aMY2', 'aMZ2', 'aM02', 'aM12', 'aM2', 'aM3'],
      archivedQuestionTypes: ['aMY', 'aMZ', 'aM0', 'aM1'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/Fractions/10EquivalentFractionFamilies" */ './10EquivalentFractionFamilies'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'AddTwoOrMoreFractions',
      questionTypes: ['aM4', 'aM5', 'aM6', 'aM72', 'aM82', 'aM92'],
      archivedQuestionTypes: ['aM7', 'aM8', 'aM9'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/Fractions/11AddTwoOrMoreFractions" */ './11AddTwoOrMoreFractions'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'AddFractionsAndMixedNumbers',
      questionTypes: ['aNa2', 'aNb', 'aNc', 'aNd', 'aNe', 'aNf'],
      archivedQuestionTypes: ['aNa'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/Fractions/12AddFractionsAndMixedNumbers" */ './12AddFractionsAndMixedNumbers'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'SubtractTwoFractions',
      questionTypes: ['aNg', 'aNh', 'aNi', 'aNj', 'aNk2', 'aNl2'],
      archivedQuestionTypes: ['aNk', 'aNl'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/Fractions/13SubtractTwoFractions" */ './13SubtractTwoFractions'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'SubtractFromWholeAmounts',
      questionTypes: ['aNm', 'aNn', 'aNo', 'aNp2', 'aNq', 'aNr'],
      archivedQuestionTypes: ['aNp'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/Fractions/14SubtractFromWholeAmounts" */ './14SubtractFromWholeAmounts'
        ).then(mod => mod.default)
    }),
    // Step 15
    newSmallStepContent({
      smallStep: 'SubtractFromMixedNumbers',
      questionTypes: ['aNs', 'aNt2', 'aNu', 'aNv2', 'aNw', 'aNx2'],
      archivedQuestionTypes: ['aNt', 'aNv', 'aNx'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/Fractions/15SubtractFromMixedNumbers" */ './15SubtractFromMixedNumbers'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
