import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'MultiplicationAndDivisionB',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'MultiplesOf10',
      questionTypes: ['aFw', 'aFx', 'aFy', 'aFz', 'aFA', 'aFB'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MultiplicationAndDivisionB/1MultiplesOf10" */ './1MultiplesOf10'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'RelatedCalculations',
      questionTypes: ['aFC', 'aFD2', 'aFE', 'aFF', 'aFG', 'aFH2'],
      archivedQuestionTypes: ['aFD', 'aFH'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MultiplicationAndDivisionB/2RelatedCalculations" */ './2RelatedCalculations'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'ReasoningAboutMultiplication',
      questionTypes: ['aFI', 'aFJ', 'aFK', 'aFL', 'aFM', 'aFN2'],
      archivedQuestionTypes: ['aFN'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MultiplicationAndDivisionB/3ReasoningAboutMultiplication" */ './3ReasoningAboutMultiplication'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'MultiplyA2DigitNumberByA1DigitNumberNoExchange',
      questionTypes: ['aFO2', 'aFP', 'aFQ2', 'aFR2', 'aFS', 'aFT'],
      archivedQuestionTypes: ['aFO', 'aFQ', 'aFR'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MultiplicationAndDivisionB/4MultiplyA2DigitNumberByA1DigitNumberNoExchange" */ './4MultiplyA2DigitNumberByA1DigitNumberNoExchange'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'MultiplyA2DigitNumberByA1DigitNumberWithExchange',
      questionTypes: ['aFU', 'aFV', 'aFY', 'aFW', 'aFX', 'aFZ2'],
      archivedQuestionTypes: ['aFZ'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MultiplicationAndDivisionB/5MultiplyA2DigitNumberByA1DigitNumberWithExchange" */ './5MultiplyA2DigitNumberByA1DigitNumberWithExchange'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'LinkMultiplicationAndDivision',
      questionTypes: ['aF0', 'aF1', 'aF2', 'aF3', 'aF42', 'aF52'],
      archivedQuestionTypes: ['aF4', 'aF5'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MultiplicationAndDivisionB/6LinkMultiplicationAndDivision" */ './6LinkMultiplicationAndDivision'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'DivideA2DigitNumberByA1DigitNumberNoExchange',
      questionTypes: ['aF6', 'aF7', 'aF8', 'aF92', 'aGa2', 'aGb3'],
      archivedQuestionTypes: ['aF9', 'aGa', 'aGb', 'aGb2'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MultiplicationAndDivisionB/7DivideA2DigitNumberByA1DigitNumberNoExchange" */ './7DivideA2DigitNumberByA1DigitNumberNoExchange'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'DivideA2DigitNumberByA1DigitNumberFlexiblePartitioning',
      questionTypes: ['aGc2', 'aGd2', 'aGe2', 'aGf2', 'aGg2', 'aGh'],
      archivedQuestionTypes: ['aGc', 'aGd', 'aGe', 'aGf', 'aGg'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MultiplicationAndDivisionB/8DivideA2DigitNumberByA1DigitNumberFlexiblePartitioning" */ './8DivideA2DigitNumberByA1DigitNumberFlexiblePartitioning'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'DivideA2DigitNumberByA1DigitNumberWithRemainders',
      questionTypes: ['aGi', 'aGj', 'aGk2', 'aGl', 'aGm2', 'aGn2'],
      archivedQuestionTypes: ['aGk', 'aGm', 'aGn'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MultiplicationAndDivisionB/9DivideA2DigitNumberByA1DigitNumberWithRemainders" */ './9DivideA2DigitNumberByA1DigitNumberWithRemainders'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'Scaling',
      questionTypes: ['aGo', 'aGp', 'aGq', 'aGr', 'aGs', 'aGt'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MultiplicationAndDivisionB/10Scaling" */ './10Scaling'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'HowManyWays',
      questionTypes: ['aGu', 'aGv', 'aGw', 'aGx', 'aGy', 'aGz'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MultiplicationAndDivisionB/11HowManyWays" */ './11HowManyWays'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
