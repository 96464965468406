import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'PlaceValue',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'RomanNumeralsTo1000',
      questionTypes: ['ada', 'adb', 'adc', 'add', 'ade', 'adf'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/PlaceValue/1RomanNumeralsTo1000" */ './1RomanNumeralsTo1000'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'NumbersTo10000',
      questionTypes: ['adg', 'adh', 'adi', 'adj', 'adk', 'adl'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/PlaceValue/2NumbersTo10000" */ './2NumbersTo10000'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'NumbersTo100000',
      questionTypes: ['adm', 'adn', 'ado', 'adp', 'adq', 'adr'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/PlaceValue/3NumbersTo100000" */ './3NumbersTo100000'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'NumbersTo1000000',
      questionTypes: ['ads', 'adt', 'adu', 'adv', 'adw', 'adx'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/PlaceValue/4NumbersTo1000000" */ './4NumbersTo1000000'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'ReadAndWriteNumbersTo1000000',
      questionTypes: ['ady', 'adz', 'adA', 'adB', 'adC', 'adD'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/PlaceValue/5ReadAndWriteNumbersTo1000000" */ './5ReadAndWriteNumbersTo1000000'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'PowersOf10',
      questionTypes: ['adE', 'adF', 'adG', 'adH', 'adI2', 'adJ'],
      archivedQuestionTypes: ['adI'],
      module: () =>
        import(/* webpackChunkName: "Year5/Autumn/PlaceValue/6PowersOf10" */ './6PowersOf10').then(
          mod => mod.default
        )
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'To100000MoreOrLess',
      questionTypes: ['adK', 'adL2', 'adM', 'adN', 'adO', 'adP'],
      archivedQuestionTypes: ['adL'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/PlaceValue/7To100000MoreOrLess" */ './7To100000MoreOrLess'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'PartitionNumbersTo1000000',
      questionTypes: ['adQ3', 'adR2', 'adS', 'adT2', 'adU', 'adV'],
      archivedQuestionTypes: ['adQ', 'adQ2', 'adR', 'adT'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/PlaceValue/8PartitionNumbersTo1000000" */ './8PartitionNumbersTo1000000'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'NumberLineTo1000000',
      questionTypes: ['adW', 'adX', 'adY', 'adZ', 'ad0', 'ad1'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/PlaceValue/9NumberLineTo1000000" */ './9NumberLineTo1000000'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'CompareAndOrderNumbersTo100000',
      questionTypes: ['ad2', 'ad3', 'ad4', 'ad5', 'ad6', 'ad7'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/PlaceValue/10CompareAndOrderNumbersTo100000" */ './10CompareAndOrderNumbersTo100000'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'CompareAndOrderNumbersTo1000000',
      questionTypes: ['ad8', 'ad9', 'aea', 'aeb', 'aec', 'aed'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/PlaceValue/11CompareAndOrderNumbersTo1000000" */ './11CompareAndOrderNumbersTo1000000'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'RoundToTheNearest10100Or1000',
      questionTypes: ['aee', 'aef', 'aeg', 'aeh', 'aei', 'aej'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/PlaceValue/12RoundToTheNearest10100Or1000" */ './12RoundToTheNearest10100Or1000'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'RoundWithin100000',
      questionTypes: ['aek', 'ael', 'aem', 'aen', 'aeo', 'aep'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/PlaceValue/13RoundWithin100000" */ './13RoundWithin100000'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'RoundWithin1000000',
      questionTypes: ['aeq', 'aer', 'aes', 'aet', 'aeu', 'aev'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/PlaceValue/14RoundWithin1000000" */ './14RoundWithin1000000'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
