import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'MassAndVolume',
  field: 'Measurement',
  weeks: [11, 12],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'HeavierAndLighter',
      questionTypes: ['bdy2', 'bdz2', 'bdA2'],
      archivedQuestionTypes: ['bdy', 'bdz', 'bdA'],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/MassAndVolume/1HeavierAndLighter" */ './1HeavierAndLighter'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'MeasureMass',
      questionTypes: ['bdB', 'bdC', 'bdD'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/MassAndVolume/2MeasureMass" */ './2MeasureMass'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'CompareMass',
      questionTypes: ['bdE', 'bdF', 'bdG'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/MassAndVolume/3CompareMass" */ './3CompareMass'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'FullAndEmpty',
      questionTypes: ['bdH', 'bdI', 'bdJ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/MassAndVolume/4FullAndEmpty" */ './4FullAndEmpty'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'CompareVolume',
      questionTypes: ['bdK', 'bdL', 'bdM'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/MassAndVolume/5CompareVolume" */ './5CompareVolume'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'MeasureCapacity',
      questionTypes: ['bdN', 'bdO', 'bdP'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/MassAndVolume/6MeasureCapacity" */ './6MeasureCapacity'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'CompareCapacity',
      questionTypes: ['bdQ', 'bdR', 'bdS'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/MassAndVolume/7CompareCapacity" */ './7CompareCapacity'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
