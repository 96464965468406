import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Money',
  field: 'Measurement',
  weeks: [3, 4],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'PoundsAndPence',
      questionTypes: ['as8', 'as9', 'ata', 'atb', 'atc', 'atd2'],
      archivedQuestionTypes: ['atd'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Money/1PoundsAndPence" */ './1PoundsAndPence'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'ConvertPoundsAndPence',
      questionTypes: ['ate', 'atf', 'atg', 'ath', 'ati', 'atj'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Summer/Money/2ConvertPoundsAndPence" */ './2ConvertPoundsAndPence'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'AddMoney',
      questionTypes: ['atk', 'atl2', 'atm', 'atn', 'ato', 'atp'],
      archivedQuestionTypes: ['atl'],
      module: () =>
        import(/* webpackChunkName: "Year3/Summer/Money/3AddMoney" */ './3AddMoney').then(
          mod => mod.default
        )
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'SubtractMoney',
      questionTypes: ['atq', 'atr', 'ats', 'att', 'atu', 'atv2'],
      archivedQuestionTypes: ['atv'],
      module: () =>
        import(/* webpackChunkName: "Year3/Summer/Money/4SubtractMoney" */ './4SubtractMoney').then(
          mod => mod.default
        )
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'FindChange',
      questionTypes: ['atw', 'atx', 'aty', 'atz', 'atA2', 'atB'],
      archivedQuestionTypes: ['atA'],
      module: () =>
        import(/* webpackChunkName: "Year3/Summer/Money/5FindChange" */ './5FindChange').then(
          mod => mod.default
        )
    })
  ]
});
export default Block;
