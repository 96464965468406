import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'PlaceValue',
  field: 'Number',
  weeks: [1, 2],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'NumbersTo1000000',
      questionTypes: ['aew', 'aex', 'aey', 'aez', 'aeA', 'aeB'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/PlaceValue/1NumbersTo1000000" */ './1NumbersTo1000000'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'NumbersTo10000000',
      questionTypes: ['aeC', 'aeD', 'aeE', 'aeF', 'aeG', 'aeH'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/PlaceValue/2NumbersTo10000000" */ './2NumbersTo10000000'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'ReadAndWriteNumbersTo10000000',
      questionTypes: ['aeI', 'aeJ', 'aeK', 'aeL', 'aeM', 'aeN'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/PlaceValue/3ReadAndWriteNumbersTo10000000" */ './3ReadAndWriteNumbersTo10000000'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'PowersOf10',
      questionTypes: ['aeO', 'aeP', 'aeQ', 'aeR', 'aeS', 'aeT'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year6/Autumn/PlaceValue/4PowersOf10" */ './4PowersOf10').then(
          mod => mod.default
        )
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'NumberLineTo10000000',
      questionTypes: ['aeU', 'aeV', 'aeW', 'aeX', 'aeY', 'aeZ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/PlaceValue/5NumberLineTo10000000" */ './5NumberLineTo10000000'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'CompareAndOrderAnyIntegers',
      questionTypes: ['ae0', 'ae1', 'ae2', 'ae3', 'ae4', 'ae5'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/PlaceValue/6CompareAndOrderAnyIntegers" */ './6CompareAndOrderAnyIntegers'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'RoundAnyInteger',
      questionTypes: ['ae6', 'ae7', 'ae8', 'ae9', 'afa', 'afb'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/PlaceValue/7RoundAnyInteger" */ './7RoundAnyInteger'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'NegativeNumbers',
      questionTypes: ['afc2', 'afd', 'afe', 'aff', 'afg', 'afh'],
      archivedQuestionTypes: ['afc'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/PlaceValue/8NegativeNumbers" */ './8NegativeNumbers'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
