import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'AdditionAndSubtraction',
  field: 'Number',
  weeks: [4, 5],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'MentalStrategies',
      questionTypes: ['aiM', 'aiN', 'aiO', 'aiP', 'aiQ', 'aiR'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/AdditionAndSubtraction/1MentalStrategies" */ './1MentalStrategies'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'AddWholeNumbersWithMoreThanFourDigits',
      questionTypes: ['aiS', 'aiT2', 'aiU', 'aiV', 'aiW', 'aiX'],
      archivedQuestionTypes: ['aiT'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/AdditionAndSubtraction/2AddWholeNumbersWithMoreThanFourDigits" */ './2AddWholeNumbersWithMoreThanFourDigits'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'SubtractWholeNumbersWithMoreThanFourDigits',
      questionTypes: ['aiY', 'aiZ2', 'ai0', 'ai1', 'ai2', 'ai3'],
      archivedQuestionTypes: ['aiZ'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/AdditionAndSubtraction/3SubtractWholeNumbersWithMoreThanFourDigits" */ './3SubtractWholeNumbersWithMoreThanFourDigits'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'RoundToCheckAnswers',
      questionTypes: ['ai4', 'ai5', 'ai6', 'ai7', 'ai82', 'ai9'],
      archivedQuestionTypes: ['ai8'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/AdditionAndSubtraction/4RoundToCheckAnswers" */ './4RoundToCheckAnswers'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'InverseOperationsAdditionAndSubtraction',
      questionTypes: ['aja', 'ajb', 'ajc', 'ajd', 'aje', 'ajf'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/AdditionAndSubtraction/5InverseOperationsAdditionAndSubtraction" */ './5InverseOperationsAdditionAndSubtraction'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'MultiStepAdditionAndSubtractionProblems',
      questionTypes: ['ajg', 'ajh', 'aji', 'ajj', 'ajk', 'ajl'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/AdditionAndSubtraction/6MultiStepAdditionAndSubtractionProblems" */ './6MultiStepAdditionAndSubtractionProblems'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'CompareCalculations',
      questionTypes: ['ajm', 'ajn', 'ajo', 'ajp', 'ajq', 'ajr'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/AdditionAndSubtraction/7CompareCalculations" */ './7CompareCalculations'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'FindMissingNumbers',
      questionTypes: ['ajs', 'ajt', 'ajv', 'aju', 'ajw', 'ajx'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/AdditionAndSubtraction/8FindMissingNumbers" */ './8FindMissingNumbers'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
