import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'ConvertingUnits',
  field: 'Measurement',
  weeks: [12, 12],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'MetricMeasures',
      questionTypes: ['an0', 'an1', 'an2', 'an3', 'an4', 'an5'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/ConvertingUnits/1MetricMeasures" */ './1MetricMeasures'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'ConvertMetricMeasures',
      questionTypes: ['an6', 'an7', 'an8', 'an92', 'aoa', 'aob2'],
      archivedQuestionTypes: ['an9', 'aob'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/ConvertingUnits/2ConvertMetricMeasures" */ './2ConvertMetricMeasures'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'CalculateWithMetricMeasures',
      questionTypes: ['aoc', 'aod', 'aoe', 'aof', 'aog', 'aoh'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/ConvertingUnits/3CalculateWithMetricMeasures" */ './3CalculateWithMetricMeasures'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'MilesAndKilometres',
      questionTypes: ['aoi', 'aoj', 'aok', 'aol', 'aom', 'aon'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/ConvertingUnits/4MilesAndKilometres" */ './4MilesAndKilometres'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'ImperialMeasures',
      questionTypes: ['aoo', 'aop2', 'aoq2', 'aor2', 'aos', 'aot'],
      archivedQuestionTypes: ['aop', 'aoq', 'aor'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/ConvertingUnits/5ImperialMeasures" */ './5ImperialMeasures'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
