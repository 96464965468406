import { SystemBars } from 'react-native-edge-to-edge';
import { useCallback, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { RootStackProps } from '../navigation/types';
import { countRange } from 'common/utils/collections';
import FilledButton from '../components/FilledButton';
import Spinner from 'common/components/molecules/Spinner';
import useLoginStore from '../storage/useLoginStore';
import { useHeaderHeight } from '@react-navigation/elements';
import useBreakpoints from '../hooks/useBreakpoints';
import useKeyboard from '../hooks/useKeyboard';
import useScreenDimensions from '../hooks/useScreenDimensions';
import { resolveFont } from 'common/theme/fonts';
import { colors } from 'common/theme/colors';
import useQuizSessionStore from '../storage/useQuizSessionStore';
import { Image } from 'expo-image';
import { getPlayer } from 'common/utils/Audio';
import { useI18nContext } from '../i18n/i18n-react';
import { type LocalizedString } from 'typesafe-i18n';
import { createNewQuizSession } from '../network/quizSession';
import TextInputRow from '../components/TextInputRow';
import { showInfoToast } from '../components/Toast';

const CODE_LENGTH = 6;

export default function EnterQuizPINScreen({ navigation }: RootStackProps<'EnterQuizPIN'>) {
  const translate = useI18nContext().LL;
  const [pin, setPin] = useState<string[]>(countRange(CODE_LENGTH).map(() => ''));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{
    displayString: LocalizedString;
    /** Whether the error is because the code is invalid (rather than due to some other error) */
    isInvalid?: boolean;
  } | null>(null);

  const learningGroupShareCode = useLoginStore(state => state.school)!.code;
  const setQuizSession = useQuizSessionStore(state => state.setQuizSession);

  const screenDimensions = useScreenDimensions();
  const { resize, type } = useBreakpoints();
  const keyboard = useKeyboard();
  const headerHeight = useHeaderHeight();

  const onContinueClicked = useCallback(async () => {
    const quizVersionShareShareCode = pin.join('');

    // Local validation - check it's exactly 6 numbers
    if (!/^[0-9]{6}$/.test(quizVersionShareShareCode)) {
      setError({
        displayString: translate.enterCodeOrPINScreen.invalidQuizPinError(),
        isInvalid: true
      });
      return;
    }

    // API validation - create a quiz session
    setLoading(true);
    const response = await createNewQuizSession({
      learningGroupShareCode,
      quizVersionShareShareCode
    });

    if (typeof response === 'string') {
      // Creating quiz session failed, show an error message and hide the loading spinner
      setLoading(false);

      switch (response) {
        case 'invalid response':
        case 'unknown error':
        case 'http error':
          setError({
            displayString: translate.enterCodeOrPINScreen.somethingWentWrongError()
          });
          return;
        case 'network error':
          setError({ displayString: translate.enterCodeOrPINScreen.internetConnectionLostError() });
          return;
        case 'not found':
          setError({
            displayString: translate.enterCodeOrPINScreen.quizNotFoundError(),
            isInvalid: true
          });
          return;
        case 'quiz locked':
          setError({
            displayString: translate.enterCodeOrPINScreen.quizLockedError()
          });
          return;
        default:
          // Produces TS error and throws runtime error if we missed a case
          throw new Error(`Logic error: unreachable (${response satisfies never})`);
      }
    } else {
      // Success - navigate to next screen
      getPlayer(response.quizSounds);
      setQuizSession({ ...response, questionResults: response.questionResults ?? [] });

      // Get login info in user directly from store for the most up-to-date information. Using the hook to get this
      // information in a React-y way would only give us the state when the last react render occurred, which might
      // be too late
      const { loggedInUser, school } = useLoginStore.getState();

      if (school?.hasInfinityPlus && !loggedInUser) {
        showInfoToast({
          title: translate.infoModals.notLoggedIn(),
          message: translate.infoModals.resultsNotSaved(),
          extraDuration: 1000,
          resize: resize
        });
      }

      navigation.replace('Quiz');
    }
  }, [
    learningGroupShareCode,
    navigation,
    pin,
    resize,
    setQuizSession,
    translate.enterCodeOrPINScreen,
    translate.infoModals
  ]);

  // Available height, after subtracting the keyboard and header (assumes app is full screen)
  const availableHeight =
    screenDimensions.height - headerHeight - (keyboard.keyboardShown ? keyboard.keyboardHeight : 0);

  // When keyboard is showing, there might not be enough space to show the continue button
  const showShorterLayoutBecauseKeyboard = keyboard.keyboardShown && availableHeight < 300 * resize;

  const loadingSpinner = (
    <View
      style={[
        StyleSheet.absoluteFill,
        { backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', gap: 24 }
      ]}
    >
      <Spinner height={showShorterLayoutBecauseKeyboard ? 100 * resize : 156 * resize} />
      {type !== 'mobile' && (
        <Text
          style={resolveFont({
            fontFamily: 'White_Rose_Noto',
            fontWeight: '400',
            fontSize: 32,
            lineHeight: 48,
            color: colors.prussianBlue
          })}
        >
          {translate.loadingEllipsis()}
        </Text>
      )}
    </View>
  );

  const textInputRow = (
    <TextInputRow
      shape="nn-nn-nn"
      code={pin}
      setCode={action => {
        setPin(action);
        // Whenever the user changes the code, hide any error messages
        setError(null);
      }}
      onFinalEnter={onContinueClicked}
      // Highlight the input boxes as errored if it was the code itself being invalid that caused the error
      error={error?.isInvalid}
      autoFocus
    />
  );

  const errorText = (
    <View style={{ flexDirection: 'row', alignItems: 'center', gap: 12 }}>
      {error !== null && (
        <Image
          source={require('pupil-app/assets/svg/InfoIcon.svg')}
          style={{ width: 27 * resize, height: 28 * resize }}
        />
      )}
      <Text
        testID="ERROR_MESSAGE"
        style={resolveFont({
          fontFamily: 'White_Rose_Noto',
          fontWeight: '700',
          fontSize: 21.67 * resize,
          lineHeight: 32.5 * resize,
          color: colors.danger
        })}
      >
        {error?.displayString}
      </Text>
    </View>
  );

  const continueButton = (
    <FilledButton
      icon={() => (
        <Image
          source={require('pupil-app/assets/svg/RightArrow.svg')}
          style={{ width: 48 * resize, height: 48 * resize }}
        />
      )}
      iconOnRight
      onPress={onContinueClicked}
      buttonWidth={320 * resize}
    >
      Continue
    </FilledButton>
  );

  return (
    <>
      <SystemBars style="dark" />
      {showShorterLayoutBecauseKeyboard ? (
        <View
          style={{
            flex: 1,
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginBottom: keyboard.keyboardHeight,
            minWidth: 760
          }}
        >
          {textInputRow}
          {errorText}
          {loading && loadingSpinner}
        </View>
      ) : (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 32 * resize,
            minWidth: 760
          }}
        >
          {textInputRow}
          {error !== null && errorText}
          {continueButton}
          {loading && loadingSpinner}
        </View>
      )}
    </>
  );
}
