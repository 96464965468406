import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'MultiplicationAndDivisionA',
  field: 'Number',
  weeks: [6, 8],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'Multiples',
      questionTypes: ['aml2', 'amm', 'amn', 'amo', 'amp', 'amq'],
      archivedQuestionTypes: ['aml'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/MultiplicationAndDivisionA/1Multiples" */ './1Multiples'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'CommonMultiples',
      questionTypes: ['amr', 'ams2', 'amt', 'amu2', 'amv', 'amw'],
      archivedQuestionTypes: ['ams', 'amu'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/MultiplicationAndDivisionA/2CommonMultiples" */ './2CommonMultiples'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'Factors',
      questionTypes: ['amx', 'amy', 'amz', 'amA', 'amB', 'amC'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/MultiplicationAndDivisionA/3Factors" */ './3Factors'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'CommonFactors',
      questionTypes: ['amD', 'amE', 'amF', 'amG', 'amH', 'amI'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/MultiplicationAndDivisionA/4CommonFactors" */ './4CommonFactors'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'PrimeNumbers',
      questionTypes: ['amJ', 'amK', 'amL', 'amM', 'amN2', 'amO'],
      archivedQuestionTypes: ['amN'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/MultiplicationAndDivisionA/5PrimeNumbers" */ './5PrimeNumbers'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'SquareNumbers',
      questionTypes: ['amP2', 'amQ2', 'amR', 'amS2', 'amT', 'amU'],
      archivedQuestionTypes: ['amP', 'amQ', 'amS'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/MultiplicationAndDivisionA/6SquareNumbers" */ './6SquareNumbers'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'CubeNumbers',
      questionTypes: ['amV', 'amW', 'amX', 'amY2', 'amZ2', 'am0'],
      archivedQuestionTypes: ['amY', 'amZ'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/MultiplicationAndDivisionA/7CubeNumbers" */ './7CubeNumbers'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'MultiplyBy10100And1000',
      questionTypes: ['am1', 'am2', 'am3', 'am4', 'am52', 'am62'],
      archivedQuestionTypes: ['am5', 'am6'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/MultiplicationAndDivisionA/8MultiplyBy10100And1000" */ './8MultiplyBy10100And1000'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'DivideBy10100And1000',
      questionTypes: ['am7', 'am8', 'am9', 'ana', 'anb2', 'anc'],
      archivedQuestionTypes: ['anb'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/MultiplicationAndDivisionA/9DivideBy10100And1000" */ './9DivideBy10100And1000'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'MultiplesOf10100And1000',
      questionTypes: ['and', 'ane', 'anf2', 'ang', 'anh', 'ani'],
      archivedQuestionTypes: ['anf'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Autumn/MultiplicationAndDivisionA/10MultiplesOf10100And1000" */ './10MultiplesOf10100And1000'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
