import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'LengthAndPerimeter',
  field: 'Measurement',
  weeks: [4, 5],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'MeasureInKilometresAndMetres',
      questionTypes: ['aLe', 'aLf2', 'aLg', 'aLh', 'aLi2', 'aLj'],
      archivedQuestionTypes: ['aLf', 'aLi'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/LengthAndPerimeter/1MeasureInKilometresAndMetres" */ './1MeasureInKilometresAndMetres'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'EquivalentLengthsKilometresAndMetres',
      questionTypes: ['aLk', 'aLl', 'aLm2', 'aLn2', 'aLo2', 'aLp2'],
      archivedQuestionTypes: ['aLm', 'aLn', 'aLo', 'aLp'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/LengthAndPerimeter/2EquivalentLengthsKilometresAndMetres" */ './2EquivalentLengthsKilometresAndMetres'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'PerimeterOnAGrid',
      questionTypes: ['aLq', 'aLr', 'aLs', 'aLt', 'aLu', 'aLv'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/LengthAndPerimeter/3PerimeterOnAGrid" */ './3PerimeterOnAGrid'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'PerimeterOfARectangle',
      questionTypes: ['aLw2', 'aLx', 'aLy', 'aLz', 'aLA', 'aLB'],
      archivedQuestionTypes: ['aLw'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/LengthAndPerimeter/4PerimeterOfARectangle" */ './4PerimeterOfARectangle'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'PerimeterOfRectilinearShapes',
      questionTypes: ['aLC', 'aLD', 'aLE', 'aLF', 'aLG', 'aLH2'],
      archivedQuestionTypes: ['aLH'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/LengthAndPerimeter/5PerimeterOfRectilinearShapes" */ './5PerimeterOfRectilinearShapes'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'FindMissingLengthsInRectilinearShapes',
      questionTypes: ['aLI', 'aLJ2', 'aLK2', 'aLL2', 'aLM', 'aLN'],
      archivedQuestionTypes: ['aLJ', 'aLK', 'aLL'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/LengthAndPerimeter/6FindMissingLengthsInRectilinearShapes" */ './6FindMissingLengthsInRectilinearShapes'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'CalculateThePerimeterOfRectilinearShapes',
      questionTypes: ['aLO2', 'aLP2', 'aLQ', 'aLR', 'aLS', 'aLT'],
      archivedQuestionTypes: ['aLO', 'aLP'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/LengthAndPerimeter/7CalculateThePerimeterOfRectilinearShapes" */ './7CalculateThePerimeterOfRectilinearShapes'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'PerimeterOfRegularPolygons',
      questionTypes: ['aLU', 'aLV', 'aLW', 'aLX', 'aLY', 'aLZ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/LengthAndPerimeter/8PerimeterOfRegularPolygons" */ './8PerimeterOfRegularPolygons'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'PerimeterOfPolygons',
      questionTypes: ['aL0', 'aL1', 'aL2', 'aL3', 'aL4', 'aL5'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/LengthAndPerimeter/9PerimeterOfPolygons" */ './9PerimeterOfPolygons'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
