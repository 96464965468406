import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'MassCapacityAndTemperature',
  field: 'Measurement',
  weeks: [10, 12],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'CompareMass',
      questionTypes: ['bjH2', 'bjI2', 'bjJ2'],
      archivedQuestionTypes: ['bjH', 'bjI', 'bjJ'],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MassCapacityAndTemperature/1CompareMass" */ './1CompareMass'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'MeasureInGrams',
      questionTypes: ['bjK', 'bjL', 'bjM'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MassCapacityAndTemperature/2MeasureInGrams" */ './2MeasureInGrams'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'MeasureInKilograms',
      questionTypes: ['bjN', 'bjO', 'bjP'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MassCapacityAndTemperature/3MeasureInKilograms" */ './3MeasureInKilograms'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'FourOperationsWithMass',
      questionTypes: ['bjQ', 'bjR', 'bjS'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MassCapacityAndTemperature/4FourOperationsWithMass" */ './4FourOperationsWithMass'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'CompareVolumeAndCapacity',
      questionTypes: ['bjT', 'bjU', 'bjV'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MassCapacityAndTemperature/5CompareVolumeAndCapacity" */ './5CompareVolumeAndCapacity'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'MeasureInMillilitres',
      questionTypes: ['bjW', 'bjX', 'bjY'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MassCapacityAndTemperature/6MeasureInMillilitres" */ './6MeasureInMillilitres'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'MeasureInLitres',
      questionTypes: ['bjZ', 'bj0', 'bj1'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MassCapacityAndTemperature/7MeasureInLitres" */ './7MeasureInLitres'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'FourOperationsWithVolumeAndCapacity',
      questionTypes: ['bj2', 'bj3', 'bj4'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MassCapacityAndTemperature/8FourOperationsWithVolumeAndCapacity" */ './8FourOperationsWithVolumeAndCapacity'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'Temperature',
      questionTypes: ['bj5', 'bj6', 'bj7'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/MassCapacityAndTemperature/9Temperature" */ './9Temperature'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
