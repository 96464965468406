import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'FractionsB',
  field: 'Number',
  weeks: [10, 11],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'MultiplyFractionsByIntegers',
      questionTypes: ['aq12', 'aq2', 'aq3', 'aq4', 'aq5', 'aq62'],
      archivedQuestionTypes: ['aq1', 'aq6'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsB/1MultiplyFractionsByIntegers" */ './1MultiplyFractionsByIntegers'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'MultiplyFractionsByFractions',
      questionTypes: ['aq7', 'aq8', 'aq9', 'ara', 'arb', 'arc'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsB/2MultiplyFractionsByFractions" */ './2MultiplyFractionsByFractions'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'DivideAFractionByAnInteger',
      questionTypes: ['ard', 'are', 'arf', 'arg', 'arh', 'ari'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsB/3DivideAFractionByAnInteger" */ './3DivideAFractionByAnInteger'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'DivideAnyFractionByAnInteger',
      questionTypes: ['arj', 'ark2', 'arl', 'arm', 'arn', 'aro'],
      archivedQuestionTypes: ['ark'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsB/4DivideAnyFractionByAnInteger" */ './4DivideAnyFractionByAnInteger'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'MixedQuestionsWithFractions',
      questionTypes: ['arp2', 'arq', 'arr', 'ars', 'art', 'aru'],
      archivedQuestionTypes: ['arp'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsB/5MixedQuestionsWithFractions" */ './5MixedQuestionsWithFractions'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'FractionOfAnAmount',
      questionTypes: ['arv', 'arw2', 'arx', 'ary', 'arz', 'arA'],
      archivedQuestionTypes: ['arw'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsB/6FractionOfAnAmount" */ './6FractionOfAnAmount'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'FractionOfAnAmountFindTheWhole',
      questionTypes: ['arB', 'arC', 'arD', 'arE2', 'arF', 'arG'],
      archivedQuestionTypes: ['arE'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Autumn/FractionsB/7FractionOfAnAmountFindTheWhole" */ './7FractionOfAnAmountFindTheWhole'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
