import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'DecimalsA',
  field: 'Number',
  weeks: [10, 12],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'TenthsAsFractions',
      questionTypes: ['aNy', 'aNz', 'aNA', 'aNB2', 'aNC2', 'aND'],
      archivedQuestionTypes: ['aNB', 'aNC'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/DecimalsA/1TenthsAsFractions" */ './1TenthsAsFractions'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'TenthsAsDecimals',
      questionTypes: ['aNE', 'aNF', 'aNG', 'aNH', 'aNI2', 'aNJ'],
      archivedQuestionTypes: ['aNI'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/DecimalsA/2TenthsAsDecimals" */ './2TenthsAsDecimals'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'TenthsOnAPlaceValueChart',
      questionTypes: ['aNK', 'aNL', 'aNM', 'aNN', 'aNO', 'aNP'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/DecimalsA/3TenthsOnAPlaceValueChart" */ './3TenthsOnAPlaceValueChart'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'TenthsOnANumberLine',
      questionTypes: ['aNQ', 'aNR', 'aNS', 'aNT', 'aNU', 'aNV'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/DecimalsA/4TenthsOnANumberLine" */ './4TenthsOnANumberLine'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'DivideA1DigitNumberBy10',
      questionTypes: ['aNW', 'aNX', 'aNY', 'aNZ2', 'aN0', 'aN12'],
      archivedQuestionTypes: ['aNZ', 'aN1'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/DecimalsA/5DivideA1DigitNumberBy10" */ './5DivideA1DigitNumberBy10'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'DivideA2DigitNumberBy10',
      questionTypes: ['aN2', 'aN3', 'aN4', 'aN5', 'aN62', 'aN72'],
      archivedQuestionTypes: ['aN6', 'aN7'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/DecimalsA/6DivideA2DigitNumberBy10" */ './6DivideA2DigitNumberBy10'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'HundredthsAsFractions',
      questionTypes: ['aN8', 'aN9', 'aOa', 'aOb2', 'aOc2', 'aOd'],
      archivedQuestionTypes: ['aOb', 'aOc'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/DecimalsA/7HundredthsAsFractions" */ './7HundredthsAsFractions'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'HundredthsAsDecimals',
      questionTypes: ['aOe', 'aOf', 'aOg', 'aOh', 'aOi', 'aOj'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/DecimalsA/8HundredthsAsDecimals" */ './8HundredthsAsDecimals'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'HundredthsOnAPlaceValueChart',
      questionTypes: ['aOk', 'aOl', 'aOm', 'aOn', 'aOo', 'aOp'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/DecimalsA/9HundredthsOnAPlaceValueChart" */ './9HundredthsOnAPlaceValueChart'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'DivideA1Or2DigitNumberBy100',
      questionTypes: ['aOq', 'aOr2', 'aOs', 'aOt2', 'aOu', 'aOv2'],
      archivedQuestionTypes: ['aOr', 'aOt', 'aOv'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Spring/DecimalsA/10DivideA1Or2DigitNumberBy100" */ './10DivideA1Or2DigitNumberBy100'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
