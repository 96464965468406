import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'MassAndCapacity',
  field: 'Measurement',
  weeks: [10, 12],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'UseScales',
      questionTypes: ['aII', 'aIJ', 'aIK', 'aIL', 'aIM', 'aIN'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MassAndCapacity/1UseScales" */ './1UseScales'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'MeasureMassInGrams',
      questionTypes: ['aIO', 'aIP', 'aIQ', 'aIR', 'aIS', 'aIT'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MassAndCapacity/2MeasureMassInGrams" */ './2MeasureMassInGrams'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'MeasureMassInKilogramsAndGrams',
      questionTypes: ['aIU', 'aIV', 'aIW', 'aIX', 'aIY', 'aIZ2'],
      archivedQuestionTypes: ['aIZ'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MassAndCapacity/3MeasureMassInKilogramsAndGrams" */ './3MeasureMassInKilogramsAndGrams'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'EquivalentMassesKilogramsAndGrams',
      questionTypes: ['aI0', 'aI12', 'aI22', 'aI3', 'aI4', 'aI5'],
      archivedQuestionTypes: ['aI1', 'aI2'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MassAndCapacity/4EquivalentMassesKilogramsAndGrams" */ './4EquivalentMassesKilogramsAndGrams'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'CompareMass',
      questionTypes: ['aI6', 'aI7', 'aI8', 'aI92', 'aJa2', 'aJb'],
      archivedQuestionTypes: ['aI9', 'aJa'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MassAndCapacity/5CompareMass" */ './5CompareMass'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'AddAndSubtractMass',
      questionTypes: ['aJc2', 'aJd2', 'aJe2', 'aJf', 'aJg', 'aJh2'],
      archivedQuestionTypes: ['aJc', 'aJd', 'aJe', 'aJh'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MassAndCapacity/6AddAndSubtractMass" */ './6AddAndSubtractMass'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'MeasureCapacityAndVolumeInMillilitres',
      questionTypes: ['aJi', 'aJj2', 'aJk', 'aJl', 'aJm', 'aJn3'],
      archivedQuestionTypes: ['aJj', 'aJn', 'aJn2'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MassAndCapacity/7MeasureCapacityAndVolumeInMillilitres" */ './7MeasureCapacityAndVolumeInMillilitres'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'MeasureCapacityAndVolumeInLitresAndMillilitres',
      questionTypes: ['aJo2', 'aJp', 'aJq', 'aJr', 'aJs', 'aJt'],
      archivedQuestionTypes: ['aJo'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MassAndCapacity/8MeasureCapacityAndVolumeInLitresAndMillilitres" */ './8MeasureCapacityAndVolumeInLitresAndMillilitres'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'EquivalentCapacitiesAndVolumesLitresAndMillilitres',
      questionTypes: ['aJu2', 'aJv', 'aJw', 'aJx', 'aJy2', 'aJz2'],
      archivedQuestionTypes: ['aJu', 'aJy', 'aJz'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MassAndCapacity/9EquivalentCapacitiesAndVolumesLitresAndMillilitres" */ './9EquivalentCapacitiesAndVolumesLitresAndMillilitres'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'CompareCapacityAndVolume',
      questionTypes: ['aJA', 'aJB2', 'aJC', 'aJD', 'aJE', 'aJF'],
      archivedQuestionTypes: ['aJB'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MassAndCapacity/10CompareCapacityAndVolume" */ './10CompareCapacityAndVolume'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'AddAndSubtractCapacityAndVolume',
      questionTypes: ['aJG', 'aJH', 'aJI', 'aJJ2', 'aJK', 'aJL2'],
      archivedQuestionTypes: ['aJJ', 'aJL'],
      module: () =>
        import(
          /* webpackChunkName: "Year3/Spring/MassAndCapacity/11AddAndSubtractCapacityAndVolume" */ './11AddAndSubtractCapacityAndVolume'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
