import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'PlaceValue',
  field: 'Number',
  weeks: [1, 4],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'RepresentNumbersTo1000',
      questionTypes: ['abw', 'abx', 'aby', 'abz', 'abA', 'abB'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/1RepresentNumbersTo1000" */ './1RepresentNumbersTo1000'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'PartitionNumbersTo1000',
      questionTypes: ['abC', 'abD', 'abE2', 'abF', 'abG', 'abH'],
      archivedQuestionTypes: ['abE'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/2PartitionNumbersTo1000" */ './2PartitionNumbersTo1000'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'NumberLineTo1000',
      questionTypes: ['abI', 'abJ', 'abK2', 'abL', 'abM', 'abN'],
      archivedQuestionTypes: ['abK'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/3NumberLineTo1000" */ './3NumberLineTo1000'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'Thousands',
      questionTypes: ['abO', 'abP', 'abQ', 'abR', 'abS', 'abT'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year4/Autumn/PlaceValue/4Thousands" */ './4Thousands').then(
          mod => mod.default
        )
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'RepresentNumbersTo10000',
      questionTypes: ['abU', 'abV', 'abW', 'abX', 'abY', 'abZ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/5RepresentNumbersTo10000" */ './5RepresentNumbersTo10000'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'PartitionNumbersTo10000',
      questionTypes: ['ab0', 'ab1', 'ab22', 'ab3', 'ab4', 'ab5'],
      archivedQuestionTypes: ['ab2'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/6PartitionNumbersTo10000" */ './6PartitionNumbersTo10000'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'FlexiblePartitioningOfNumbersTo10000',
      questionTypes: ['ab6', 'ab7', 'ab8', 'ab9', 'aca', 'acb'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/7FlexiblePartitioningOfNumbersTo10000" */ './7FlexiblePartitioningOfNumbersTo10000'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'Find1To1000MoreOrLess',
      questionTypes: ['acc', 'acd', 'ace', 'acf2', 'acg', 'ach'],
      archivedQuestionTypes: ['acf'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/8Find1To1000MoreOrLess" */ './8Find1To1000MoreOrLess'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'NumberLineTo10000',
      questionTypes: ['aci', 'acj', 'ack', 'acl', 'acm', 'acn'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/9NumberLineTo10000" */ './9NumberLineTo10000'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'EstimateOnANumberLineTo10000',
      questionTypes: ['aco', 'acp2', 'acq', 'acr', 'acs2', 'act2'],
      archivedQuestionTypes: ['acp', 'acs', 'act'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/10EstimateOnANumberLineTo10000" */ './10EstimateOnANumberLineTo10000'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'CompareNumbersTo10000',
      questionTypes: ['acu', 'acv', 'acw', 'acx', 'acy', 'acz'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/11CompareNumbersTo10000" */ './11CompareNumbersTo10000'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'OrderNumbersTo10000',
      questionTypes: ['acA', 'acB', 'acC', 'acD', 'acE', 'acF'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/12OrderNumbersTo10000" */ './12OrderNumbersTo10000'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'RomanNumerals',
      questionTypes: ['acG', 'acH', 'acI', 'acJ', 'acK2', 'acL'],
      archivedQuestionTypes: ['acK'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/13RomanNumerals" */ './13RomanNumerals'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'RoundToTheNearest10',
      questionTypes: ['acM', 'acN', 'acO', 'acP', 'acQ', 'acR2'],
      archivedQuestionTypes: ['acR'],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/14RoundToTheNearest10" */ './14RoundToTheNearest10'
        ).then(mod => mod.default)
    }),
    // Step 15
    newSmallStepContent({
      smallStep: 'RoundToTheNearest100',
      questionTypes: ['acS', 'acT', 'acU', 'acV', 'acW', 'acX'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/15RoundToTheNearest100" */ './15RoundToTheNearest100'
        ).then(mod => mod.default)
    }),
    // Step 16
    newSmallStepContent({
      smallStep: 'RoundToTheNearest1000',
      questionTypes: ['acY', 'acZ', 'ac0', 'ac1', 'ac2', 'ac3'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/16RoundToTheNearest1000" */ './16RoundToTheNearest1000'
        ).then(mod => mod.default)
    }),
    // Step 17
    newSmallStepContent({
      smallStep: 'RoundToTheNearest10100Or1000',
      questionTypes: ['ac4', 'ac5', 'ac6', 'ac8', 'ac7', 'ac9'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year4/Autumn/PlaceValue/17RoundToTheNearest10100Or1000" */ './17RoundToTheNearest10100Or1000'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
