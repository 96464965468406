// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newTermContent } from '../../Term';
import Ratio from './Ratio';
import Algebra from './Algebra';
import Decimals from './Decimals';
import FractionsDecimalsAndPercentages from './FractionsDecimalsAndPercentages';
import AreaPerimeterAndVolume from './AreaPerimeterAndVolume';
import Statistics from './Statistics';

const Term = newTermContent({
  term: 'Spring',
  blocks: [
    Ratio,
    Algebra,
    Decimals,
    FractionsDecimalsAndPercentages,
    AreaPerimeterAndVolume,
    Statistics
  ]
});
export default Term;
