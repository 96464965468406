import { ImageBackground } from 'react-native';
import BaseScreen from 'common/components/screens/BaseScreen';
import { generateParamsForTokens } from '../premadeQuizzes';
import { type SpecificQuestion } from 'common/SchemeOfLearning';
import { useCallback, useEffect, useState } from 'react';
import SocialMediaGameHomeSubScreen from './SocialMediaGameHomeSubScreen';
import SocialMediaGameQuizSubScreen from './SocialMediaGameQuizSubScreen';
import { getPlayer } from 'common/utils/Audio';
import { generateNewQuiz } from '../premadeQuizzes';

/**
 * Main screen for the social media game.
 *
 * This uses an internal sub-screen hierarchy which is not powered by react-navigation:
 * - {@link SocialMediaGameScreen}
 *   - {@link SocialMediaGameHomeSubScreen}
 *   - {@link SocialMediaGameQuizSubScreen}
 *     - loadingSubScreen
 *     - quizSubScreen
 *     - wellDoneSubScreen
 *     - resultsSubScreen
 *
 * We choose not to use react-navigation because it forces us to expose the navigation structure in the URL. We want
 * to be absolutely sure people don't accidentally navigate to the main part of the app (the pupil-app part), even by
 * copy-pasting URLs or refreshing the page, so we keep this whole social media game to 1 screen as far as
 * react-navigation is concerned. Therefore, we navigate around the social media game with our own navigation state.
 */
export default function SocialMediaGameScreen() {
  const [soundEnabled, setSoundEnabled] = useState(true);
  useEffect(() => {
    getPlayer(soundEnabled);
  }, [soundEnabled]);

  ////
  // App navigation state (like react-navigation, but custom)
  ////
  const [appState, setAppState] = useState<AppState>(() => {
    const { quizName, tokens } = generateNewQuiz();
    // Kick off getting the tokens
    tokens.then(it => setAppState(old => ({ ...old, quizName, tokens: it })));
    return { screen: 'Home', quizName, quizKey: 0 };
  });

  /**
   * Go to the Quiz screen - using the quiz name and tokens we just set up (which might be shared to us).
   */
  const navigateToQuiz = useCallback(() => {
    if (!appState.tokens) {
      console.warn('navigateToQuiz called before quiz loaded');
      return undefined;
    }
    setAppState({
      screen: 'Quiz',
      quizName: appState.quizName,
      tokens: appState.tokens,
      quizKey: appState.quizKey
    });
  }, [appState]);

  /**
   * Return to the Home screen - resetting the quiz.
   */
  const navigateToHome = useCallback(() => {
    const { quizName, tokens } = generateNewQuiz();
    setAppState(old => ({
      screen: 'Home',
      quizName,
      quizKey: old?.quizKey !== undefined ? old.quizKey + 1 : 0
    }));
    tokens.then(it => setAppState(old => ({ ...old, tokens: it })));
  }, []);

  /**
   * Restart the quiz - using the same quiz name but new tokens.
   */
  const restartQuizWithNewQuestions = useCallback(async () => {
    if (!appState.tokens) {
      console.warn('restartQuizWithNewQuestions when quiz wasnt loaded');
      return;
    }
    const quizName = appState.quizName;
    const tokens = await generateParamsForTokens(appState.tokens, true);
    setAppState(old => {
      return { screen: 'Quiz', quizName, tokens, quizKey: old.quizKey + 1 };
    });
  }, [appState.quizName, appState.tokens]);

  return (
    <ImageBackground
      source={require('pupil-app/assets/images/SpaceBackground.png')}
      resizeMode="cover"
      style={{
        flex: 1
      }}
    >
      <BaseScreen>
        {(() => {
          switch (appState.screen) {
            case 'Home':
              return (
                <SocialMediaGameHomeSubScreen
                  quizName={appState.quizName}
                  onStartQuiz={navigateToQuiz}
                  soundEnabled={soundEnabled}
                  setSoundEnabled={setSoundEnabled}
                />
              );
            case 'Quiz':
              return (
                <SocialMediaGameQuizSubScreen
                  key={appState.quizKey}
                  quizName={appState.quizName}
                  tokens={appState.tokens}
                  onReturnToHome={navigateToHome}
                  onTryAgain={restartQuizWithNewQuestions}
                />
              );
          }
        })()}
      </BaseScreen>
    </ImageBackground>
  );
}

type AppState = {
  screen: 'Home' | 'Quiz';
  quizName: string;
  /** Undefined means "loading". When in screen 'Quiz', can assume this is defined. */
  tokens?: SpecificQuestion[];
  /** Used internally, just for resetting state */
  quizKey: number;
} & (
  | {
      screen: 'Home';
      tokens?: SpecificQuestion[];
    }
  | {
      screen: 'Quiz';
      tokens: SpecificQuestion[];
    }
);
