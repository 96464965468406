import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Algebra',
  field: 'Number',
  weeks: [3, 4],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'Num1StepFunctionMachines',
      questionTypes: ['aTK', 'aTL', 'aTM2', 'aTN2', 'aTO', 'aTP'],
      archivedQuestionTypes: ['aTM', 'aTN'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Algebra/1Num1StepFunctionMachines" */ './1Num1StepFunctionMachines'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'Num2StepFunctionMachines',
      questionTypes: ['aTQ', 'aTR', 'aTS', 'aTT', 'aTU', 'aTV2'],
      archivedQuestionTypes: ['aTV'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Algebra/2Num2StepFunctionMachines" */ './2Num2StepFunctionMachines'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'FormExpressions',
      questionTypes: ['aTW', 'aTX2', 'aTY', 'aTZ2', 'aT0', 'aT1'],
      archivedQuestionTypes: ['aTX', 'aTZ'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Algebra/3FormExpressions" */ './3FormExpressions'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'Substitution',
      questionTypes: ['aT2', 'aT32', 'aT42', 'aT5', 'aT6', 'aT7'],
      archivedQuestionTypes: ['aT3', 'aT4'],
      module: () =>
        import(/* webpackChunkName: "Year6/Spring/Algebra/4Substitution" */ './4Substitution').then(
          mod => mod.default
        )
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'Formulae',
      questionTypes: ['aT8', 'aT9', 'aUa', 'aUb', 'aUc', 'aUd'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year6/Spring/Algebra/5Formulae" */ './5Formulae').then(
          mod => mod.default
        )
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'FormEquations',
      questionTypes: ['aUe', 'aUf', 'aUg', 'aUh', 'aUi', 'aUj'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Algebra/6FormEquations" */ './6FormEquations'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'Solve1StepEquations',
      questionTypes: ['aUk', 'aUl', 'aUm', 'aUn2', 'aUo2', 'aUp'],
      archivedQuestionTypes: ['aUn', 'aUo'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Algebra/7Solve1StepEquations" */ './7Solve1StepEquations'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'Solve2StepEquations',
      questionTypes: ['aUq', 'aUr', 'aUs', 'aUt2', 'aUu2', 'aUv'],
      archivedQuestionTypes: ['aUt', 'aUu'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Algebra/8Solve2StepEquations" */ './8Solve2StepEquations'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'FindPairsOfValues',
      questionTypes: ['aUw', 'aUx', 'aUy', 'aUz', 'aUA', 'aUB'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Algebra/9FindPairsOfValues" */ './9FindPairsOfValues'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'SolveProblemsWithTwoUnknowns',
      questionTypes: ['aUC', 'aUD', 'aUE2', 'aUF2', 'aUG2', 'aUH2'],
      archivedQuestionTypes: ['aUE', 'aUF', 'aUG', 'aUH'],
      module: () =>
        import(
          /* webpackChunkName: "Year6/Spring/Algebra/10SolveProblemsWithTwoUnknowns" */ './10SolveProblemsWithTwoUnknowns'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
