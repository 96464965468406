// Four operations symbols
export const ADD = '+';
export type ADD = typeof ADD;
export const SUB = '\u2212';
export type SUB = typeof SUB;
export const DIV = '\u00F7';
export type DIV = typeof DIV;
export const MULT = '\u00D7';
export type MULT = typeof MULT;

// Other symbols
export const DECIMAL_POINT = '.';
export type DECIMAL_POINT = typeof DECIMAL_POINT;
export const APPROX_EQUAL = '\u2248';
export type APPROX_EQUAL = typeof APPROX_EQUAL;
export const DEGREES = '°';
export type DEGREES = typeof DEGREES;

// HTML-escaped symbols
export const LESS_THAN = '&lt;';
export type LESS_THAN = typeof LESS_THAN;
export const GREATER_THAN = '&gt;';
export type GREATER_THAN = typeof GREATER_THAN;

// Algebraic symbols - lower case
export const ALGEBRAIC_A = '𝑎';
export type ALGEBRAIC_A = typeof ALGEBRAIC_A;

export const ALGEBRAIC_B = '𝑏';
export type ALGEBRAIC_B = typeof ALGEBRAIC_B;

export const ALGEBRAIC_C = '𝑐';
export type ALGEBRAIC_C = typeof ALGEBRAIC_C;

export const ALGEBRAIC_D = '𝑑';
export type ALGEBRAIC_D = typeof ALGEBRAIC_D;

export const ALGEBRAIC_E = '𝑒';
export type ALGEBRAIC_E = typeof ALGEBRAIC_E;

export const ALGEBRAIC_F = '𝑓';
export type ALGEBRAIC_F = typeof ALGEBRAIC_F;

export const ALGEBRAIC_G = '𝑔';
export type ALGEBRAIC_G = typeof ALGEBRAIC_G;

// Using the Planck's constant ℎ glyph here in place of a similar 'h' to the other algebraic symbols not existing.
// This ℎ glyph will look appropriately-sized in Infinity with White Rose Noto fonts.
export const ALGEBRAIC_H = 'ℎ';
export type ALGEBRAIC_H = typeof ALGEBRAIC_H;

export const ALGEBRAIC_I = '𝑖';
export type ALGEBRAIC_I = typeof ALGEBRAIC_I;

export const ALGEBRAIC_J = '𝑗';
export type ALGEBRAIC_J = typeof ALGEBRAIC_J;

export const ALGEBRAIC_K = '𝑘';
export type ALGEBRAIC_K = typeof ALGEBRAIC_K;

export const ALGEBRAIC_L = '𝑙';
export type ALGEBRAIC_L = typeof ALGEBRAIC_L;

export const ALGEBRAIC_M = '𝑚';
export type ALGEBRAIC_M = typeof ALGEBRAIC_M;

export const ALGEBRAIC_N = '𝑛';
export type ALGEBRAIC_N = typeof ALGEBRAIC_N;

export const ALGEBRAIC_O = '𝑜';
export type ALGEBRAIC_O = typeof ALGEBRAIC_O;

export const ALGEBRAIC_P = '𝑝';
export type ALGEBRAIC_P = typeof ALGEBRAIC_P;

export const ALGEBRAIC_Q = '𝑞';
export type ALGEBRAIC_Q = typeof ALGEBRAIC_Q;

export const ALGEBRAIC_R = '𝑟';
export type ALGEBRAIC_R = typeof ALGEBRAIC_R;

export const ALGEBRAIC_S = '𝑠';
export type ALGEBRAIC_S = typeof ALGEBRAIC_S;

export const ALGEBRAIC_T = '𝑡';
export type ALGEBRAIC_T = typeof ALGEBRAIC_T;

export const ALGEBRAIC_U = '𝑢';
export type ALGEBRAIC_U = typeof ALGEBRAIC_U;

export const ALGEBRAIC_V = '𝑣';
export type ALGEBRAIC_V = typeof ALGEBRAIC_V;

export const ALGEBRAIC_W = '𝑤';
export type ALGEBRAIC_W = typeof ALGEBRAIC_W;

export const ALGEBRAIC_X = '𝑥';
export type ALGEBRAIC_X = typeof ALGEBRAIC_X;

export const ALGEBRAIC_Y = '𝑦';
export type ALGEBRAIC_Y = typeof ALGEBRAIC_Y;

export const ALGEBRAIC_Z = '𝑧';
export type ALGEBRAIC_Z = typeof ALGEBRAIC_Z;

// Algebraic symbols - upper case
export const ALGEBRAIC_CAPITAL_A = '𝐴';
export type ALGEBRAIC_CAPITAL_A = typeof ALGEBRAIC_CAPITAL_A;

export const ALGEBRAIC_CAPITAL_B = '𝐵';
export type ALGEBRAIC_CAPITAL_B = typeof ALGEBRAIC_CAPITAL_B;

export const ALGEBRAIC_CAPITAL_C = '𝐶';
export type ALGEBRAIC_CAPITAL_C = typeof ALGEBRAIC_CAPITAL_C;

export const ALGEBRAIC_CAPITAL_D = '𝐷';
export type ALGEBRAIC_CAPITAL_D = typeof ALGEBRAIC_CAPITAL_D;

export const ALGEBRAIC_CAPITAL_E = '𝐸';
export type ALGEBRAIC_CAPITAL_E = typeof ALGEBRAIC_CAPITAL_E;

export const ALGEBRAIC_CAPITAL_F = '𝐹';
export type ALGEBRAIC_CAPITAL_F = typeof ALGEBRAIC_CAPITAL_F;

export const ALGEBRAIC_CAPITAL_G = '𝐺';
export type ALGEBRAIC_CAPITAL_G = typeof ALGEBRAIC_CAPITAL_G;

export const ALGEBRAIC_CAPITAL_H = '𝐻';
export type ALGEBRAIC_CAPITAL_H = typeof ALGEBRAIC_CAPITAL_H;

export const ALGEBRAIC_CAPITAL_I = '𝐼';
export type ALGEBRAIC_CAPITAL_I = typeof ALGEBRAIC_CAPITAL_I;

export const ALGEBRAIC_CAPITAL_J = '𝐽';
export type ALGEBRAIC_CAPITAL_J = typeof ALGEBRAIC_CAPITAL_J;

export const ALGEBRAIC_CAPITAL_K = '𝐾';
export type ALGEBRAIC_CAPITAL_K = typeof ALGEBRAIC_CAPITAL_K;

export const ALGEBRAIC_CAPITAL_L = '𝐿';
export type ALGEBRAIC_CAPITAL_L = typeof ALGEBRAIC_CAPITAL_L;

export const ALGEBRAIC_CAPITAL_M = '𝑀';
export type ALGEBRAIC_CAPITAL_M = typeof ALGEBRAIC_CAPITAL_M;

export const ALGEBRAIC_CAPITAL_N = '𝑁';
export type ALGEBRAIC_CAPITAL_N = typeof ALGEBRAIC_CAPITAL_N;

export const ALGEBRAIC_CAPITAL_O = '𝑂';
export type ALGEBRAIC_CAPITAL_O = typeof ALGEBRAIC_CAPITAL_O;

export const ALGEBRAIC_CAPITAL_P = '𝑃';
export type ALGEBRAIC_CAPITAL_P = typeof ALGEBRAIC_CAPITAL_P;

export const ALGEBRAIC_CAPITAL_Q = '𝑄';
export type ALGEBRAIC_CAPITAL_Q = typeof ALGEBRAIC_CAPITAL_Q;

export const ALGEBRAIC_CAPITAL_R = '𝑅';
export type ALGEBRAIC_CAPITAL_R = typeof ALGEBRAIC_CAPITAL_R;

export const ALGEBRAIC_CAPITAL_S = '𝑆';
export type ALGEBRAIC_CAPITAL_S = typeof ALGEBRAIC_CAPITAL_S;

export const ALGEBRAIC_CAPITAL_T = '𝑇';
export type ALGEBRAIC_CAPITAL_T = typeof ALGEBRAIC_CAPITAL_T;

export const ALGEBRAIC_CAPITAL_U = '𝑈';
export type ALGEBRAIC_CAPITAL_U = typeof ALGEBRAIC_CAPITAL_U;

export const ALGEBRAIC_CAPITAL_V = '𝑉';
export type ALGEBRAIC_CAPITAL_V = typeof ALGEBRAIC_CAPITAL_V;

export const ALGEBRAIC_CAPITAL_W = '𝑊';
export type ALGEBRAIC_CAPITAL_W = typeof ALGEBRAIC_CAPITAL_W;

export const ALGEBRAIC_CAPITAL_X = '𝑋';
export type ALGEBRAIC_CAPITAL_X = typeof ALGEBRAIC_CAPITAL_X;

export const ALGEBRAIC_CAPITAL_Y = '𝑌';
export type ALGEBRAIC_CAPITAL_Y = typeof ALGEBRAIC_CAPITAL_Y;

export const ALGEBRAIC_CAPITAL_Z = '𝑍';
export type ALGEBRAIC_CAPITAL_Z = typeof ALGEBRAIC_CAPITAL_Z;

// Backspace
export const BACKSPACE = '\u232B';
export type BACKSPACE = typeof BACKSPACE;

// Direction
export const HORIZONTAL = 'horizontal';
export type HORIZONTAL = typeof HORIZONTAL;
export const VERTICAL = 'vertical';
export type VERTICAL = typeof VERTICAL;
export const DIAGONAL_LEFT = 'diagonal left';
export type DIAGONAL_LEFT = typeof DIAGONAL_LEFT;
export const DIAGONAL_RIGHT = 'diagonal right';
export type DIAGONAL_RIGHT = typeof DIAGONAL_RIGHT;
