import { createContext, useContext } from 'react';
import { colors } from './colors';
import { resolveFont } from './fonts';
import { DisplayMode } from '../contexts/displayMode';

type FontVariant = {
  fontFamily: string;
  fontSize?: number;
  fontWeight?:
    | 'normal'
    | 'bold'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900';
  letterSpacing?: number;
  lineHeight?: number;
  fontStyle?: 'normal' | 'italic';
};

const whiteRoseNotoRegular = resolveFont({
  fontFamily: 'White_Rose_Noto',
  fontWeight: '400'
});

const whiteRoseNoto700 = resolveFont({
  fontFamily: 'White_Rose_Noto',
  fontWeight: '700'
});

const libreBaskervilleRegular = resolveFont({
  fontFamily: 'Libre Baskerville',
  fontWeight: '400'
});

const biotifRegular = resolveFont({
  fontFamily: 'Biotif',
  fontWeight: '400'
});

const biotifMedium = resolveFont({
  fontFamily: 'Biotif',
  fontWeight: '500'
});

const biotifBold = resolveFont({
  fontFamily: 'Biotif',
  fontWeight: '700'
});

const roboto400 = resolveFont({
  fontFamily: 'Roboto',
  fontWeight: '400'
});

const roboto500 = resolveFont({
  fontFamily: 'Roboto',
  fontWeight: '500'
});

export const lightTheme = {
  allowFontScaling: false,
  buttonBorderWidth: 2,
  buttonBorderRadius: 999,
  roundness: 8,

  colors: {
    primary: colors.prussianBlue,
    onPrimary: colors.white,
    primaryContainer: colors.prussianBlueLight,
    onPrimaryContainer: colors.prussianBlueDark,
    pdfPrimary: colors.black,

    // Used as an accent
    secondary: colors.seaGreenCrayolaMid,
    onSecondary: colors.white,
    secondaryContainer: colors.seaGreenCrayola,
    onSecondaryContainer: colors.seaGreenCrayolaDark,

    // Used to indicate interactible
    tertiary: colors.burntSienna,
    onTertiary: colors.white,
    tertiaryContainer: colors.burntSiennaLight,
    tertiaryContainerHovered: colors.burntSiennaMedium,
    onTertiaryContainer: colors.prussianBlue,

    // For example, the main panel's background
    background: colors.white,
    onBackground: colors.prussianBlue,
    backgroundBorder: colors.prussianBlue,

    // A surface color to distinguish itself from the background
    surface: colors.greyMatter,
    onSurface: colors.prussianBlue,
    surfaceBorder: colors.prussianBlue,
    onSurfaceVariant: colors.prussianBlue,

    // A container living on the surface color above
    surfaceContainer: colors.greys100,
    onSurfaceContainer: colors.prussianBlue,
    surfaceContainerBorder: colors.greys300,

    // For items which are absent, but need a color to mark where they should be
    absentItemBackground: colors.greys200,

    error: colors.danger,
    onError: colors.white,
    errorContainer: colors.dangerLight,
    onErrorContainer: colors.dangerDark,

    outline: colors.prussianBlue,

    placeholder: colors.grey,

    // surfaceDisabled: '',
    // onSurfaceDisabled: '',

    // inverseOnSurface: '',
    // inverseSurface: '',
    inversePrimary: colors.prussianBlueLight
    // backdrop: '',
  },

  fonts: {
    default: {
      ...whiteRoseNotoRegular,
      letterSpacing: 0,
      lineHeight: undefined,
      fontSize: undefined
    } as FontVariant,

    roman: {
      ...libreBaskervilleRegular,
      letterSpacing: 0.1,
      lineHeight: 20,
      fontSize: 14
    } as FontVariant,

    WRN400: {
      ...whiteRoseNotoRegular,
      fontSize: 40,
      lineHeight: 60
    } as FontVariant,

    WRN700: {
      ...whiteRoseNoto700,
      fontSize: 40,
      lineHeight: 60
    } as FontVariant,

    ButtonLarge: {
      ...biotifBold,
      fontSize: 37,
      lineHeight: 55.5
    } as FontVariant,

    ////
    // React-native-paper font presets (don't use for Question Types)
    ////
    displayLarge: {
      ...biotifRegular,
      lineHeight: 64,
      fontSize: 57
    } as FontVariant,
    displayMedium: {
      ...biotifRegular,
      lineHeight: 52,
      fontSize: 45
    } as FontVariant,
    displaySmall: {
      fontFamily: 'White_Rose_Noto',
      fontWeight: '400',
      lineHeight: 44,
      fontSize: 36
    } as FontVariant,

    headlineLarge: {
      ...biotifRegular,
      lineHeight: 40,
      fontSize: 32
    } as FontVariant,
    headlineMedium: {
      ...biotifRegular,
      lineHeight: 36,
      fontSize: 28
    } as FontVariant,
    headlineSmall: {
      ...biotifRegular,
      lineHeight: 32,
      fontSize: 18
    } as FontVariant,

    titleLarge: {
      ...biotifRegular,
      lineHeight: 28,
      fontSize: 22
    } as FontVariant,
    titleMedium: {
      ...biotifMedium,
      letterSpacing: 0.15,
      lineHeight: 24,
      fontSize: 16
    } as FontVariant,
    titleSmall: {
      ...biotifMedium,
      letterSpacing: 0.1,
      lineHeight: 20,
      fontSize: 14
    } as FontVariant,

    labelLarge: {
      ...roboto500,
      letterSpacing: 1,
      lineHeight: 24,
      fontSize: 16
    } as FontVariant,
    labelMedium: {
      ...biotifMedium,
      letterSpacing: 0.5,
      lineHeight: 16,
      fontSize: 12
    } as FontVariant,
    labelSmall: {
      ...biotifMedium,
      letterSpacing: 0.5,
      lineHeight: 16,
      fontSize: 11
    } as FontVariant,

    bodyLarge: {
      ...biotifRegular,
      letterSpacing: 0.15,
      lineHeight: 24,
      fontSize: 16
    } as FontVariant,
    bodyMedium: {
      ...roboto400,
      lineHeight: 20,
      fontSize: 14
    } as FontVariant,
    bodySmall: {
      ...biotifRegular,
      letterSpacing: 0.4,
      lineHeight: 16,
      fontSize: 12
    } as FontVariant
  }
} as const;

const lightThemePdf = {
  ...lightTheme,
  fonts: {
    ...lightTheme.fonts,
    WRN400: {
      ...whiteRoseNotoRegular,
      fontSize: 50,
      lineHeight: 75
    } as FontVariant,
    WRN700: {
      ...whiteRoseNoto700,
      fontSize: 50,
      lineHeight: 75
    } as FontVariant
  }
} as const;

export type Theme = typeof lightTheme;

const DigitalThemeContext = createContext<Theme>(lightTheme);
const PDFThemeContext = createContext<Theme>(lightThemePdf);

export function useTheme() {
  const displayMode = useContext(DisplayMode);
  return useContext(displayMode === 'digital' ? DigitalThemeContext : PDFThemeContext);
}

export type FontVariantKey = keyof Theme['fonts'];

export function isFontVariantKey(candidate: unknown): candidate is FontVariantKey {
  return typeof candidate === 'string' && Object.keys(lightTheme.fonts).includes(candidate);
}
