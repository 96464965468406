import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'AdditionAndSubtraction',
  field: 'Number',
  weeks: [6, 10],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'IntroducePartsAndWholes',
      questionTypes: ['baT', 'baU', 'baV'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/1IntroducePartsAndWholes" */ './1IntroducePartsAndWholes'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'PartWholeModel',
      questionTypes: ['baW', 'baX', 'baY'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/2PartWholeModel" */ './2PartWholeModel'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'WriteNumberSentences',
      questionTypes: ['baZ', 'ba0', 'ba1'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/3WriteNumberSentences" */ './3WriteNumberSentences'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'FactFamiliesAdditionFacts',
      questionTypes: ['ba2', 'ba32', 'ba42'],
      archivedQuestionTypes: ['ba3', 'ba4'],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/4FactFamiliesAdditionFacts" */ './4FactFamiliesAdditionFacts'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'NumberBondsWithin10',
      questionTypes: ['ba5', 'ba6', 'ba7'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/5NumberBondsWithin10" */ './5NumberBondsWithin10'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'SystematicNumberBondsWithin10',
      questionTypes: ['ba8', 'ba9', 'bba'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/6SystematicNumberBondsWithin10" */ './6SystematicNumberBondsWithin10'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'NumberBondsTo10',
      questionTypes: ['bbb', 'bbc', 'bbd'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/7NumberBondsTo10" */ './7NumberBondsTo10'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'AdditionAddTogether',
      questionTypes: ['bbe', 'bbf', 'bbg'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/8AdditionAddTogether" */ './8AdditionAddTogether'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'AdditionAddMore',
      questionTypes: ['bbh', 'bbi', 'bbj'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/9AdditionAddMore" */ './9AdditionAddMore'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'AdditionProblems',
      questionTypes: ['bbk', 'bbl', 'bbm'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/10AdditionProblems" */ './10AdditionProblems'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'FindAPart',
      questionTypes: ['bbn', 'bbo', 'bbp'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/11FindAPart" */ './11FindAPart'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'SubtractionFindAPart',
      questionTypes: ['bbq', 'bbr', 'bbs'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/12SubtractionFindAPart" */ './12SubtractionFindAPart'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'FactFamiliesTheEightFacts',
      questionTypes: ['bbt', 'bbu', 'bbv'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/13FactFamiliesTheEightFacts" */ './13FactFamiliesTheEightFacts'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'SubtractionTakeAwayCrossOut',
      questionTypes: ['bbw', 'bbx', 'bby'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/14SubtractionTakeAwayCrossOut" */ './14SubtractionTakeAwayCrossOut'
        ).then(mod => mod.default)
    }),
    // Step 15
    newSmallStepContent({
      smallStep: 'SubtractionTakeAway',
      questionTypes: ['bbz', 'bbA', 'bbB'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/15SubtractionTakeAway" */ './15SubtractionTakeAway'
        ).then(mod => mod.default)
    }),
    // Step 16
    newSmallStepContent({
      smallStep: 'SubtractionOnANumberLine',
      questionTypes: ['bbC', 'bbD', 'bbE'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/16SubtractionOnANumberLine" */ './16SubtractionOnANumberLine'
        ).then(mod => mod.default)
    }),
    // Step 17
    newSmallStepContent({
      smallStep: 'AddOrSubtract1Or2',
      questionTypes: ['bbF', 'bbG', 'bbH'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/AdditionAndSubtraction/17AddOrSubtract1Or2" */ './17AddOrSubtract1Or2'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
