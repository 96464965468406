import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { QuestionAttempt } from './useQuestionQueueStore';
import { readonlyObjectEntry } from 'common/utils/collections';

const CACHE_EXPIRY_DAYS = 7;

/** Our internal model of a Question. */
export type QuestionInfo = {
  id: string;
  uid: string;
  /** Stringified question parameters */
  parameters?: string;
  displayOrder: number;
};

/** Our internal model of a Quiz Session. */
export type QuizSessionInfo = {
  id: string;
  name: string;
  randomiseQuestionParameters: boolean;
  questions: QuestionInfo[];
  /** Default: false */
  quizSounds?: boolean;
  questionResults?: QuestionAttempt[];
  /** Information for how to retry the quiz, i.e. create a new quiz session. */
  retryInfo:
    | { type: 'legacy' }
    | {
        type: 'quiz-pin';
        learningGroupShareCode: string;
        quizVersionShareShareCode: string;
      }
    | { type: 'assigned'; quizInstanceAssignmentId: number };
};

/** Cache of where the user is on a given quiz session. */
export type QuizSessionCacheEntry = {
  currentQuestionIndex: number;
  currentQuestionIncorrectAttempts: number;
  /** Stringified question parameters. This is useful if the question was randomly generated locally. */
  questionParams: string;
  /** Milliseconds */
  currentAttemptTimeElapsed: number;
  /** Stringified user answer. */
  currentUserAnswer: string;
  /** Ms since the epoch that this was last updated. */
  updatedAt: number;
};

type State = {
  quizSession: QuizSessionInfo | undefined;
  cache: Record<string, QuizSessionCacheEntry | undefined>;
};

type Actions = {
  setQuizSession: (quizSession: QuizSessionInfo) => void;
  clearQuizSession: () => void;
  updateCacheEntry: (
    quizSessionId: string,
    entry: Omit<QuizSessionCacheEntry, 'updatedAt'>
  ) => void;
  deleteCacheEntry: (quizSessionId: string) => void;
  deleteExpiredCacheEntries: () => void;
  clear: () => void;
};

const defaultState: State = {
  quizSession: undefined,
  cache: {}
};

const useQuizSessionStore = create<State & Actions>()(
  devtools(
    persist(
      (set, get) => ({
        ...defaultState,

        setQuizSession: (quizSession: QuizSessionInfo) => set({ quizSession }),

        clearQuizSession: () => set({ quizSession: undefined }),

        updateCacheEntry: (quizSessionId, cacheEntry) => {
          const cache = get().cache;
          const newCache = readonlyObjectEntry(cache, quizSessionId, () => ({
            ...cacheEntry,
            updatedAt: Date.now()
          }));
          set({ cache: newCache });
        },

        deleteCacheEntry: quizSessionId => {
          const cache = get().cache;
          const newCache = readonlyObjectEntry(cache, quizSessionId, () => undefined);
          set({ cache: newCache });
        },

        deleteExpiredCacheEntries: () => {
          const now = Date.now();
          const millisecondsDays = 1000 * 60 * 60 * 24 * CACHE_EXPIRY_DAYS;

          const cache = get().cache;

          const newCache = { ...cache };

          for (const quizSessionId in newCache) {
            const cacheItem = newCache[quizSessionId];
            if (cacheItem === undefined || now - cacheItem.updatedAt >= millisecondsDays) {
              // Expired
              delete newCache[quizSessionId];
            }
          }

          set({ cache: newCache });
        },

        clear: () => set(defaultState)
      }),
      {
        name: 'quizSession',
        storage: createJSONStorage(() => AsyncStorage)
      }
    )
  )
);

export default useQuizSessionStore;
