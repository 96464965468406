import { StyleSheet, View, FlatList, ScrollView } from 'react-native';
import Button from '../atoms/Button';
import Text from '../typography/Text';
import { AssetSvg } from '../../assets/svg';
import { colors } from '../../theme/colors';
import { MINIMUM_QUESTION_HEIGHT, QUESTION_WIDTH } from '../../theme/scaling';
import { noop } from '../../utils/flowControl';
import LinkText from '../atoms/LinkText';
import { useI18nContext } from '../../i18n/i18n-react';

type QuizResults = {
  /** Results of the quiz. */
  results: Array<{
    /** Number of stars (0 to 3) awarded for this question. */
    stars: number;
  }>;
  quizName: string;
};

/**
 * Screen showing the number of stars achieved on each question of a quiz.
 * Includes return, exit, and home buttos for navigation.
 */
export default function Results({
  results,
  quizName,
  onTryAgainClicked,
  onExitClicked = noop,
  onHomeClicked
}: QuizResults & {
  /** Callback for when the try again button is clicked. If absent, the try again button isn't there. */
  onTryAgainClicked?: () => void;
  /** Callback for when the close button is clicked. */
  onExitClicked?: () => void;
  /** Callback for when the back-to-home button is clocked. If absent, the try again button isn't there. */
  onHomeClicked?: () => void;
}) {
  const translate = useI18nContext().LL;
  // Transform question props to contain id
  const resultsWithIds = results.map((question, idx) => {
    return {
      questionNumber: idx + 1,
      stars: question.stars
    };
  });

  // Get stars total length and total score
  const starsTotalScore = resultsWithIds.reduce((acc, o) => acc + o.stars, 0);
  const starsTotalLength = resultsWithIds.length * 3;

  // Render ratings
  const renderRatings = () => {
    // Split the data into columns of 10
    const dataSplit = resultsWithIds.reduce(
      (acc, x) => {
        const lastArray = acc[acc.length - 1];
        if (lastArray.length < 10) {
          lastArray.push(x);
        } else {
          acc.push([x]);
        }
        return acc;
      },
      [[]] as { questionNumber: number; stars: number }[][]
    );

    return dataSplit.map((column, idx) => (
      <FlatList
        key={idx}
        data={column}
        scrollEnabled={false}
        renderItem={({ item }) => {
          const stars = [];

          const starsMissed = 3 - item.stars;

          // Render <AssetSvg name="Star" /> per amount of stars
          for (let i = 0; i < item.stars; i++) {
            stars.push(
              <View style={{ paddingLeft: 8 }} key={`star-${i}`}>
                <AssetSvg name={'Star'} height={38.2} width={40} />
              </View>
            );
          }

          // Render <AssetSvg name="Star_transparent" /> per amount of stars not collected
          for (let j = 0; j < starsMissed; j++) {
            stars.push(
              <View style={{ paddingLeft: 8 }} key={`star_transparent-${j}`}>
                <AssetSvg name={'GreyStar'} height={38.2} width={40} />
              </View>
            );
          }

          return (
            <View style={styles.rating}>
              <View style={styles.questionLabel}>
                <Text variant="WRN700" style={styles.ratingText}>
                  Q{item.questionNumber}
                </Text>
              </View>
              <View style={styles.ratingIcons}>{stars.map(star => star)}</View>
            </View>
          );
        }}
        ItemSeparatorComponent={() => <View style={styles.ratingSeperator} />}
      />
    ));
  };

  return (
    <View style={styles.container}>
      {/* Left column */}
      <View style={[styles.column, styles.columnA]}>
        {/* Close icon */}
        <Button variant="circle" onPress={onExitClicked} style={styles.iconClose}>
          <AssetSvg name="Close" width={24} height={24} />
        </Button>
        {/* Title */}
        <Text variant="WRN700" style={styles.title}>
          {translate.quiz.results()}
        </Text>
        {/* Quiz Details */}
        <View style={styles.quizDetails}>
          <Text variant="WRN400" style={styles.quizDetailsText} numberOfLines={2}>
            {quizName}
          </Text>
        </View>
        {/* Points */}
        <View style={styles.status}>
          <Text variant="WRN700" style={styles.statusLabel}>
            {translate.quiz.totalPoints()}
          </Text>
          <AssetSvg name="Star" width={45} height={45} />
          <Text variant="WRN700" style={styles.statusScore}>
            {starsTotalScore} / {starsTotalLength}
          </Text>
        </View>
        {/* Actions */}
        <View style={{ rowGap: 24 }}>
          {onTryAgainClicked && (
            <Button
              onPress={onTryAgainClicked}
              style={[styles.button, styles.center]}
              baseColor={colors.seaGreenCrayola}
              onPressInColor={colors.seaGreenCrayolaPressIn}
              testId="TRY_AGAIN_BUTTON"
            >
              <Text variant="ButtonLarge" style={styles.buttonText}>
                {translate.quiz.modals.tryAgain()}
              </Text>
              <AssetSvg name="ArrowRight" style={styles.iconArrow} width={48} height={49} />
            </Button>
          )}
          {onHomeClicked && (
            <View
              testID="BACK_HOME_BUTTON"
              style={{ paddingHorizontal: 32, paddingTop: 22, paddingBottom: 16 }}
            >
              <LinkText action={onHomeClicked} style={[styles.linkText, styles.center]}>
                {translate.quiz.backToHome()}
              </LinkText>
            </View>
          )}
        </View>
      </View>

      {/* Right column */}
      <ScrollView
        horizontal
        scrollEnabled={false}
        contentContainerStyle={{
          flexGrow: 1
        }}
        style={styles.columnBWrapper}
      >
        <View style={[styles.column, styles.columnB]}>{renderRatings()}</View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderColor: colors.prussianBlue,
    borderRadius: 24,
    borderWidth: 3,
    display: 'flex',
    flexDirection: 'row',
    height: MINIMUM_QUESTION_HEIGHT - 2 * 16,
    width: QUESTION_WIDTH - 2 * 12,
    marginVertical: 16,
    marginHorizontal: 12
  },
  column: {
    flex: 1,
    paddingHorizontal: 35,
    columnGap: 40
  },
  columnA: {
    borderRightColor: colors.prussianBlue,
    borderRightWidth: 3,
    paddingTop: 64
  },
  columnBWrapper: {
    backgroundColor: colors.greyMatter,
    borderBottomRightRadius: 24,
    borderTopRightRadius: 24,
    flex: 1,
    paddingTop: 16,
    paddingBottom: 16
  },
  columnB: {
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    paddingBottom: 48,
    textAlign: 'center'
  },
  quizDetails: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 24
  },
  quizDetailsText: { fontSize: 32, textAlign: 'center', lineHeight: 48 },
  status: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 48
  },
  statusLabel: {
    paddingRight: 16
  },
  statusScore: {
    paddingLeft: 16
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: 62,
    paddingLeft: 12,
    paddingRight: 12
  },
  ratingSeperator: {
    borderWidth: 1,
    borderColor: colors.grey
  },
  ratingText: {
    textAlign: 'center',
    fontSize: 26,
    width: 77,
    alignSelf: 'stretch',
    lineHeight: 49,
    paddingRight: 12
  },
  ratingIcons: {
    display: 'flex',
    flexDirection: 'row'
  },
  questionLabel: {
    minWidth: 64
  },
  button: {
    height: 96
  },
  buttonText: {
    paddingRight: 34
  },
  linkText: {
    fontFamily: 'Biotif-Medium',
    fontSize: 29,
    lineHeight: 43.5,
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  icon: {
    paddingBottom: 28
  },
  iconArrow: {
    position: 'absolute',
    right: 24
  },
  iconClose: {
    borderWidth: 0,
    left: 12,
    top: 12,
    position: 'absolute',
    width: 64,
    height: 64,
    zIndex: 100,
    // Override the defaults in button
    paddingTop: 0,
    paddingBottom: 0
  },
  center: {
    alignSelf: 'center'
  }
});
