import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'PlaceValueWithin10',
  field: 'Number',
  weeks: [1, 5],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'SortObjects',
      questionTypes: ['baa', 'bab', 'bac'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/PlaceValueWithin10/1SortObjects" */ './1SortObjects'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'CountObjects',
      questionTypes: ['bad', 'bae', 'baf'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/PlaceValueWithin10/2CountObjects" */ './2CountObjects'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'CountObjectsFromALargerGroup',
      questionTypes: ['bag', 'bah', 'bai'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/PlaceValueWithin10/3CountObjectsFromALargerGroup" */ './3CountObjectsFromALargerGroup'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'RepresentObjects',
      questionTypes: ['baj', 'bak', 'bal'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/PlaceValueWithin10/4RepresentObjects" */ './4RepresentObjects'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'RecogniseNumbersAsWords',
      questionTypes: ['bam', 'ban', 'bao'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/PlaceValueWithin10/5RecogniseNumbersAsWords" */ './5RecogniseNumbersAsWords'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'CountOnFromAnyNumber',
      questionTypes: ['bap', 'baq', 'bar'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/PlaceValueWithin10/6CountOnFromAnyNumber" */ './6CountOnFromAnyNumber'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'OneMore',
      questionTypes: ['bas', 'bat', 'bau'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/PlaceValueWithin10/7OneMore" */ './7OneMore'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'CountBackwardsWithin10',
      questionTypes: ['bav', 'baw', 'bax'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/PlaceValueWithin10/8CountBackwardsWithin10" */ './8CountBackwardsWithin10'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'OneLess',
      questionTypes: ['bay', 'baz', 'baA2'],
      archivedQuestionTypes: ['baA'],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/PlaceValueWithin10/9OneLess" */ './9OneLess'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'CompareGroupsByMatching',
      questionTypes: ['baB', 'baC', 'baD2'],
      archivedQuestionTypes: ['baD'],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/PlaceValueWithin10/10CompareGroupsByMatching" */ './10CompareGroupsByMatching'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'FewerMoreSame',
      questionTypes: ['baE', 'baF', 'baG'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/PlaceValueWithin10/11FewerMoreSame" */ './11FewerMoreSame'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'LessThanGreaterThanEqualTo',
      questionTypes: ['baH', 'baI', 'baJ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/PlaceValueWithin10/12LessThanGreaterThanEqualTo" */ './12LessThanGreaterThanEqualTo'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'CompareNumbers',
      questionTypes: ['baK', 'baL', 'baM'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/PlaceValueWithin10/13CompareNumbers" */ './13CompareNumbers'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'OrderObjectsAndNumbers',
      questionTypes: ['baN', 'baO', 'baP'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/PlaceValueWithin10/14OrderObjectsAndNumbers" */ './14OrderObjectsAndNumbers'
        ).then(mod => mod.default)
    }),
    // Step 15
    newSmallStepContent({
      smallStep: 'TheNumberLine',
      questionTypes: ['baQ', 'baR', 'baS'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/PlaceValueWithin10/15TheNumberLine" */ './15TheNumberLine'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
