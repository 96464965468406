import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'PlaceValue',
  field: 'Number',
  weeks: [1, 4],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'NumbersTo20',
      questionTypes: ['bfM', 'bfN', 'bfO'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/PlaceValue/1NumbersTo20" */ './1NumbersTo20'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'CountObjectsTo100ByMaking10s',
      questionTypes: ['bfP', 'bfQ', 'bfR'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/PlaceValue/2CountObjectsTo100ByMaking10s" */ './2CountObjectsTo100ByMaking10s'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'RecogniseTensAndOnes',
      questionTypes: ['bfS', 'bfT', 'bfU'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/PlaceValue/3RecogniseTensAndOnes" */ './3RecogniseTensAndOnes'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'UseAPlaceValueChart',
      questionTypes: ['bfV', 'bfW', 'bfX'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/PlaceValue/4UseAPlaceValueChart" */ './4UseAPlaceValueChart'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'PartitionNumbersTo100',
      questionTypes: ['bfY', 'bfZ', 'bf0'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/PlaceValue/5PartitionNumbersTo100" */ './5PartitionNumbersTo100'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'WriteNumbersTo100InWords',
      questionTypes: ['bf1', 'bf2', 'bf3'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/PlaceValue/6WriteNumbersTo100InWords" */ './6WriteNumbersTo100InWords'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'FlexiblyPartitionNumbersTo100',
      questionTypes: ['bf4', 'bf5', 'bf6'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/PlaceValue/7FlexiblyPartitionNumbersTo100" */ './7FlexiblyPartitionNumbersTo100'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'WriteNumbersTo100InExpandedForm',
      questionTypes: ['bf7', 'bf8', 'bf9'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/PlaceValue/8WriteNumbersTo100InExpandedForm" */ './8WriteNumbersTo100InExpandedForm'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'TensOnTheNumberLineTo100',
      questionTypes: ['bga', 'bgb', 'bgc'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/PlaceValue/9TensOnTheNumberLineTo100" */ './9TensOnTheNumberLineTo100'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'TensAndOnesOnTheNumberLineTo100',
      questionTypes: ['bgd', 'bge', 'bgf'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/PlaceValue/10TensAndOnesOnTheNumberLineTo100" */ './10TensAndOnesOnTheNumberLineTo100'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'EstimateNumbersOnANumberLine',
      questionTypes: ['bgg', 'bgh', 'bgi'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/PlaceValue/11EstimateNumbersOnANumberLine" */ './11EstimateNumbersOnANumberLine'
        ).then(mod => mod.default)
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'CompareObjects',
      questionTypes: ['bgj', 'bgk', 'bgl'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/PlaceValue/12CompareObjects" */ './12CompareObjects'
        ).then(mod => mod.default)
    }),
    // Step 13
    newSmallStepContent({
      smallStep: 'CompareNumbers',
      questionTypes: ['bgm', 'bgn', 'bgo'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/PlaceValue/13CompareNumbers" */ './13CompareNumbers'
        ).then(mod => mod.default)
    }),
    // Step 14
    newSmallStepContent({
      smallStep: 'OrderObjectsAndNumbers',
      questionTypes: ['bgp', 'bgq', 'bgr'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/PlaceValue/14OrderObjectsAndNumbers" */ './14OrderObjectsAndNumbers'
        ).then(mod => mod.default)
    }),
    // Step 15
    newSmallStepContent({
      smallStep: 'CountIn2s5sAnd10s',
      questionTypes: ['bgs', 'bgt', 'bgu'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/PlaceValue/15CountIn2s5sAnd10s" */ './15CountIn2s5sAnd10s'
        ).then(mod => mod.default)
    }),
    // Step 16
    newSmallStepContent({
      smallStep: 'CountIn3s',
      questionTypes: ['bgv', 'bgw', 'bgx'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year2/Autumn/PlaceValue/16CountIn3s" */ './16CountIn3s').then(
          mod => mod.default
        )
    })
  ]
});
export default Block;
