import { z } from 'zod';
import { type Expand } from 'common/utils/types';
import { getRequestQuery, isHttpSuccess } from './requests';
import Logger from '../utils/logger';

/** Schema for a Learning Group (for validation), according to the API. */
const schoolApiSchema = z.union([
  z.object({
    result: z.literal(true),
    learningGroupName: z.string(),
    hasInfinityPlus: z.boolean()
  }),
  z.object({
    result: z.literal(false)
  })
]);

/** A Learning Group (for validation), according to the API. */
type SchoolApiEntity = Expand<z.infer<typeof schoolApiSchema>>;

/** Validate that a school code exists, and get its display name. */
export const validateSchoolCode = async (
  schoolCode: string
): Promise<
  | { name: string; hasInfinityPlus: boolean }
  | 'no infinity subscription'
  | 'network error'
  | 'http error'
  | 'not found'
  | 'invalid response'
  | 'unknown error'
> => {
  const result = await getRequestQuery(
    `/web/infinity/learning-group-configurations/validate/${schoolCode}`
  );

  if (!isHttpSuccess(result)) {
    // Error - return a string
    switch (result.errorKind) {
      case 'network':
        Logger.captureEvent('error', 'validateSchoolCode', 'NETWORK_ERROR', { eventData: result });

        return 'network error';
      case 'http':
        switch (result.response.status) {
          case 403:
            Logger.captureEvent('error', 'validateSchoolCode', 'NO_INFINITY_SUBSCRIPTION', {
              additionalMsg: schoolCode,
              eventData: result
            });

            return 'no infinity subscription';
          default:
            Logger.captureEvent('error', 'validateSchoolCode', 'HTTP_ERROR', { eventData: result });

            return 'http error';
        }
      case 'unknown':
        Logger.captureEvent('error', 'validateSchoolCode', 'UNKNOWN_ERROR', { eventData: result });

        return 'unknown error';
      default:
        // Produces TS error and throws runtime error if we missed a case
        throw new Error(`Logic error: unreachable (${result satisfies never})`);
    }
  }
  const response = result.response;

  // Success - Validate the response
  const { data } = response;
  const parseResults = schoolApiSchema.safeParse(data);
  if (!parseResults.success) {
    // Response JSON was not in the form we expected
    Logger.captureEvent('error', 'validateSchoolCode', 'PARSE_ERROR', { eventData: parseResults });

    return 'invalid response';
  }

  // Validation success
  const parsedData: SchoolApiEntity = parseResults.data;

  if (parsedData.result === true) {
    return { name: parsedData.learningGroupName, hasInfinityPlus: parsedData.hasInfinityPlus };
  } else {
    Logger.captureEvent('warning', 'validateSchoolCode', 'SCHOOL_CODE_NOT_FOUND', {
      additionalMsg: schoolCode,
      eventData: result
    });

    return 'not found';
  }
};
