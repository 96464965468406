import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Shape',
  field: 'Geometry',
  weeks: [11, 11],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'RecogniseAndName3DShapes',
      questionTypes: ['bbI', 'bbJ', 'bbK'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/Shape/1RecogniseAndName3DShapes" */ './1RecogniseAndName3DShapes'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'Sort3DShapes',
      questionTypes: ['bbL', 'bbM', 'bbN'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year1/Autumn/Shape/2Sort3DShapes" */ './2Sort3DShapes').then(
          mod => mod.default
        )
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'RecogniseAndName2DShapes',
      questionTypes: ['bbO', 'bbP', 'bbQ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/Shape/3RecogniseAndName2DShapes" */ './3RecogniseAndName2DShapes'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'Sort2DShapes',
      questionTypes: ['bbR', 'bbS', 'bbT'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year1/Autumn/Shape/4Sort2DShapes" */ './4Sort2DShapes').then(
          mod => mod.default
        )
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'PatternsWith2DAnd3DShapes',
      questionTypes: ['bbU', 'bbV', 'bbW'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Autumn/Shape/5PatternsWith2DAnd3DShapes" */ './5PatternsWith2DAnd3DShapes'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
