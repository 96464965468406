import { SystemBars } from 'react-native-edge-to-edge';
import FilledButton from 'pupil-app/src/components/FilledButton';
import { ImageBackground } from 'react-native';
import { type RootStackProps } from '../navigation/types';
import { resolveFont } from 'common/theme/fonts';
import { IconButton } from 'react-native-paper';
import useLoginStore from '../storage/useLoginStore';
import { useWindowLayout } from 'common/theme/scaling';
import useBreakpoints from '../hooks/useBreakpoints';
import { Button as PaperButton } from 'react-native-paper';
import Text from 'common/components/typography/Text';
import AppConfig from '../../app.json';
import { Image } from 'expo-image';
import { useI18nContext } from '../i18n/i18n-react';
import { ErrorToast } from '../components/Toast';
import { useNetworkStatus } from '../hooks/useNetworkStatus';
import { AssetSvg } from 'common/assets/svg';
import { useCheckCameraPermissions } from '../utils/permissions';
import { colors } from 'common/theme/colors';

export default function HomeScreen({ navigation }: RootStackProps<'Home'>) {
  const translate = useI18nContext().LL;

  const { isConnected } = useNetworkStatus();

  // Set up the header and get layout information.
  const school = useLoginStore(state => state.school);
  const loggedInUser = useLoginStore(state => state.loggedInUser);

  const hasInfinityPlus = school?.hasInfinityPlus;

  const { insets } = useWindowLayout();
  const { resize } = useBreakpoints();

  // Permissions
  const checkCameraPermissions = useCheckCameraPermissions();
  const handlePermissions = () => {
    (async () => {
      // On success, *push* the ScanQR screen, so the back button goes back here (Home)
      await checkCameraPermissions(() => navigation.push('ScanQR'));
    })();
  };

  return (
    <>
      <SystemBars style="light" />
      <ImageBackground
        source={require('pupil-app/assets/images/SpaceBackground.png')}
        resizeMode="cover"
        fadeDuration={0}
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          gap: 32 * resize,
          paddingTop: insets.top,
          paddingBottom: insets.bottom,
          paddingLeft: insets.left,
          paddingRight: insets.right,
          backgroundColor: colors.prussianBlue
        }}
      >
        {isConnected === false && (
          <ErrorToast
            title={translate.errorModals.internetConnectionLost()}
            message={translate.errorModals.thisAppRequiresAnInternetConnection()}
            persist
            // Need this delay in case the screen is showing the initial Infinity splash screen:
            delay={2000}
          />
        )}
        {
          <IconButton
            testID="LOGIN_BUTTON"
            onPress={
              school === undefined
                ? () => {
                    navigation.push('EnterSchoolCode', { nextScreen: 'EnterPupilAccessCode' });
                  }
                : () => {
                    navigation.push('EnterPupilAccessCode');
                  }
            }
            icon={() => (
              <Image
                source={require('pupil-app/assets/svg/UserIcon.svg')}
                style={{ width: 30 * resize, height: 30 * resize }}
              />
            )}
            containerColor="#335882"
            size={59 * resize}
            style={{
              position: 'absolute',
              top: insets.top + 28 * resize,
              left: insets.left + 39 * resize,
              borderRadius: 999
            }}
          />
        }
        <AssetSvg name="WhiteRoseInfinity" width={377 * resize} height={108 * resize} />
        <FilledButton
          icon={() => (
            <Image
              source={require('pupil-app/assets/svg/QRCodeIcon.svg')}
              style={{ width: 48 * resize, height: 48 * resize }}
            />
          )}
          onPress={handlePermissions}
        >
          {translate.home.scanQrCode()}
        </FilledButton>
        <PaperButton
          mode="text"
          testID="enterCodeButton"
          uppercase
          labelStyle={resolveFont({
            fontFamily: 'Biotif',
            fontWeight: '500',
            fontSize: 29 * resize,
            lineHeight: 43.5 * resize,
            color: 'white',
            textDecorationLine: 'underline' as const
          })}
          onPress={
            // If school code has been entered and hasInfinityPlus is true and user not logged in go to Enter Pupil Access Code page
            school !== undefined && loggedInUser === undefined && hasInfinityPlus
              ? () => {
                  navigation.push('EnterPupilAccessCode', { onSuccessGoToEnterQuizPIN: true });
                }
              : school !== undefined
              ? () => navigation.push('EnterQuizPIN')
              : () => {
                  navigation.push('EnterSchoolCode', { nextScreen: 'EnterQuizPIN' });
                }
          }
        >
          {translate.home.enterCode()}
        </PaperButton>
        <Text
          style={{
            fontSize: 22 * resize,
            lineHeight: 33 * resize,
            color: '#ffffff75', // Opacity: 75%
            bottom: 16,
            position: 'absolute'
          }}
        >
          {translate.home.appVersion(AppConfig.expo.version)}
        </Text>
      </ImageBackground>
    </>
  );
}
