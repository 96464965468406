import Text from 'common/components/typography/Text';
import Button from 'common/components/atoms/Button';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated';
import { useEffect, useMemo, useState } from 'react';
import { colors } from 'common/theme/colors';
import IconButton from 'common/components/atoms/IconButton';
import VolumeOff from 'pupil-app/assets/svg/zest/volume-off';
import VolumeHigh from 'pupil-app/assets/svg/zest/volume-high';
import QuestionMark from 'pupil-app/assets/svg/zest/circled-question';
import { View } from 'react-native';
import { AssetSvg } from 'common/assets/svg';
import ConsentModal from '../components/modals/ConsentModal';

/**
 * Home sub-screen. This is where the social media game starts.
 */
export default function SocialMediaGameHomeSubScreen({
  quizName,
  onStartQuiz,
  soundEnabled,
  setSoundEnabled
}: {
  quizName: string;
  /** If undefined, it means the quiz isn't ready yet. Disable the button. */
  onStartQuiz?: () => void;
  soundEnabled: boolean;
  setSoundEnabled: (soundEnabled: boolean) => void;
}) {
  const [cookieConsent, setCookieConsent] = useState('null');
  const opacity = useSharedValue(1);
  const opacityStyle = useAnimatedStyle(() => ({ opacity: opacity.value }), [opacity]);
  const onStartClicked = useMemo(() => {
    if (!onStartQuiz) return undefined;
    return () => {
      opacity.value = withTiming(0, { duration: 500 }, finished => {
        if (finished) runOnJS(onStartQuiz)();
      });
    };
  }, [onStartQuiz, opacity]);

  useEffect(() => {
    // Check if value is already in local storage
    const storedVal = localStorage.getItem('cookieConsent');

    if (storedVal !== null) {
      setCookieConsent(storedVal);
    }
  }, [cookieConsent]);

  return (
    <>
      {cookieConsent === 'null' && (
        <ConsentModal
          onDecline={() => {
            localStorage.setItem('cookieConsent', 'false');
            window.dispatchEvent(new Event('storage'));
            setCookieConsent('false');
          }}
          onAccept={() => {
            localStorage.setItem('cookieConsent', 'true');
            window.dispatchEvent(new Event('storage'));
            setCookieConsent('true');
          }}
        />
      )}
      <Animated.View style={[{ alignItems: 'center', gap: 30 }, opacityStyle]}>
        <AssetSvg name="WhiteRoseInfinity" height={108} />
        <Text variant="WRN400" style={{ color: 'white', textAlign: 'center' }}>
          <Text variant="WRN700" style={{ color: 'white' }}>
            {quizName}
          </Text>
          {'\n'}
          Try to get the most stars in the shortest time!
        </Text>
        <Button
          onPress={onStartClicked}
          baseColor={colors.seaGreenCrayola}
          onPressInColor={colors.seaGreenCrayolaPressIn}
          disabled={!onStartClicked}
        >
          Start
        </Button>
        <View style={{ position: 'absolute', top: 30, right: 30, alignItems: 'center' }}>
          <IconButton onPress={() => setSoundEnabled(!soundEnabled)}>
            {soundEnabled ? (
              <VolumeHigh fill={colors.prussianBlue} />
            ) : (
              <VolumeOff fill={colors.prussianBlue} />
            )}
          </IconButton>
          <Text
            variant="WRN400"
            style={{
              width: 120,
              textAlign: 'center',
              fontSize: 24,
              color: 'white',
              marginTop: -10
            }}
          >
            Sound {soundEnabled ? 'on' : 'off'}
          </Text>
        </View>
      </Animated.View>
      <IconButton
        onPress={() => {
          localStorage.setItem('cookieConsent', 'null');
          setCookieConsent('null');
        }}
        style={{ position: 'absolute', bottom: 20, left: 20 }}
      >
        <QuestionMark fill={colors.prussianBlue} />
      </IconButton>
    </>
  );
}
