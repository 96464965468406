import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Shape',
  field: 'Geometry',
  weeks: [10, 12],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'Recognise2DAnd3DShapes',
      questionTypes: ['bhz', 'bhA', 'bhB'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/Shape/1Recognise2DAnd3DShapes" */ './1Recognise2DAnd3DShapes'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'CountSidesOn2DShapes',
      questionTypes: ['bhC', 'bhD', 'bhE'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/Shape/2CountSidesOn2DShapes" */ './2CountSidesOn2DShapes'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'CountVerticesOn2DShapes',
      questionTypes: ['bhF', 'bhG', 'bhH'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/Shape/3CountVerticesOn2DShapes" */ './3CountVerticesOn2DShapes'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'Draw2DShapes',
      questionTypes: ['bhI', 'bhJ', 'bhK'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year2/Autumn/Shape/4Draw2DShapes" */ './4Draw2DShapes').then(
          mod => mod.default
        )
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'LinesOfSymmetryOnShapes',
      questionTypes: ['bhL', 'bhM', 'bhN'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/Shape/5LinesOfSymmetryOnShapes" */ './5LinesOfSymmetryOnShapes'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'UseLinesOfSymmetryToCompleteShapes',
      questionTypes: ['bhO', 'bhP', 'bhQ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/Shape/6UseLinesOfSymmetryToCompleteShapes" */ './6UseLinesOfSymmetryToCompleteShapes'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'Sort2DShapes',
      questionTypes: ['bhR2', 'bhS', 'bhT2'],
      archivedQuestionTypes: ['bhR', 'bhT'],
      module: () =>
        import(/* webpackChunkName: "Year2/Autumn/Shape/7Sort2DShapes" */ './7Sort2DShapes').then(
          mod => mod.default
        )
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'CountFacesOn3DShapes',
      questionTypes: ['bhU', 'bhV', 'bhW'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/Shape/8CountFacesOn3DShapes" */ './8CountFacesOn3DShapes'
        ).then(mod => mod.default)
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'CountEdgesOn3DShapes',
      questionTypes: ['bhX', 'bhY', 'bhZ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/Shape/9CountEdgesOn3DShapes" */ './9CountEdgesOn3DShapes'
        ).then(mod => mod.default)
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'CountVerticesOn3DShapes',
      questionTypes: ['bh0', 'bh1', 'bh2'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/Shape/10CountVerticesOn3DShapes" */ './10CountVerticesOn3DShapes'
        ).then(mod => mod.default)
    }),
    // Step 11
    newSmallStepContent({
      smallStep: 'Sort3DShapes',
      questionTypes: ['bh3', 'bh4', 'bh5'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year2/Autumn/Shape/11Sort3DShapes" */ './11Sort3DShapes').then(
          mod => mod.default
        )
    }),
    // Step 12
    newSmallStepContent({
      smallStep: 'MakePatternsWith2DAnd3DShapes',
      questionTypes: ['bh6', 'bh7', 'bh8'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Autumn/Shape/12MakePatternsWith2DAnd3DShapes" */ './12MakePatternsWith2DAnd3DShapes'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
