import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'PositionAndDirection',
  field: 'Geometry',
  weeks: [6, 6],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'DescribeTurns',
      questionTypes: ['beI', 'beJ', 'beK'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/PositionAndDirection/1DescribeTurns" */ './1DescribeTurns'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'DescribePositionLeftAndRight',
      questionTypes: ['beL', 'beM', 'beN'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/PositionAndDirection/2DescribePositionLeftAndRight" */ './2DescribePositionLeftAndRight'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'DescribePositionForwardsAndBackwards',
      questionTypes: ['beO', 'beP', 'beQ'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/PositionAndDirection/3DescribePositionForwardsAndBackwards" */ './3DescribePositionForwardsAndBackwards'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'DescribePositionAboveAndBelow',
      questionTypes: ['beR', 'beS', 'beT'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/PositionAndDirection/4DescribePositionAboveAndBelow" */ './4DescribePositionAboveAndBelow'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'OrdinalNumbers',
      questionTypes: ['beU', 'beV', 'beW'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Summer/PositionAndDirection/5OrdinalNumbers" */ './5OrdinalNumbers'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
