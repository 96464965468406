import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'ConvertingUnits',
  field: 'Measurement',
  weeks: [10, 11],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'KilogramsAndKilometres',
      questionTypes: ['aC0', 'aC1', 'aC2', 'aC3', 'aC4', 'aC5'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/ConvertingUnits/1KilogramsAndKilometres" */ './1KilogramsAndKilometres'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'MillimetresAndMillilitres',
      questionTypes: ['aC6', 'aC7', 'aC8', 'aC9', 'aDa2', 'aDb2'],
      archivedQuestionTypes: ['aDa', 'aDb'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/ConvertingUnits/2MillimetresAndMillilitres" */ './2MillimetresAndMillilitres'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'ConvertUnitsOfLength',
      questionTypes: ['aDc', 'aDd', 'aDe2', 'aDf2', 'aDg', 'aDh'],
      archivedQuestionTypes: ['aDe', 'aDf'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/ConvertingUnits/3ConvertUnitsOfLength" */ './3ConvertUnitsOfLength'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'ConvertBetweenMetricAndImperialUnits',
      questionTypes: ['aDi', 'aDj', 'aDk', 'aDl', 'aDm', 'aDn2'],
      archivedQuestionTypes: ['aDn'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/ConvertingUnits/4ConvertBetweenMetricAndImperialUnits" */ './4ConvertBetweenMetricAndImperialUnits'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'ConvertUnitsOfTime',
      questionTypes: ['aDo2', 'aDp', 'aDq2', 'aDr', 'aDs2', 'aDt'],
      archivedQuestionTypes: ['aDo', 'aDq', 'aDs'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/ConvertingUnits/5ConvertUnitsOfTime" */ './5ConvertUnitsOfTime'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'CalculateWithTimetables',
      questionTypes: ['aDu', 'aDv', 'aDw2', 'aDx', 'aDy', 'aDz'],
      archivedQuestionTypes: ['aDw'],
      module: () =>
        import(
          /* webpackChunkName: "Year5/Summer/ConvertingUnits/6CalculateWithTimetables" */ './6CalculateWithTimetables'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
