import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'PlaceValueWithin50',
  field: 'Number',
  weeks: [7, 8],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'CountFrom20To50',
      questionTypes: ['bc1', 'bc2', 'bc3'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin50/1CountFrom20To50" */ './1CountFrom20To50'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'TwentyThirtyFortyAndFifty',
      questionTypes: ['bc4', 'bc5', 'bc6'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin50/2TwentyThirtyFortyAndFifty" */ './2TwentyThirtyFortyAndFifty'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'CountByMakingGroupsOfTens',
      questionTypes: ['bc7', 'bc8', 'bc9'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin50/3CountByMakingGroupsOfTens" */ './3CountByMakingGroupsOfTens'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'GroupsOfTensAndOnes',
      questionTypes: ['bda', 'bdb', 'bdc'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin50/4GroupsOfTensAndOnes" */ './4GroupsOfTensAndOnes'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'PartitionIntoTensAndOnes',
      questionTypes: ['bdd', 'bde', 'bdf'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin50/5PartitionIntoTensAndOnes" */ './5PartitionIntoTensAndOnes'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'TheNumberLineTo50',
      questionTypes: ['bdg', 'bdh', 'bdi'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin50/6TheNumberLineTo50" */ './6TheNumberLineTo50'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'EstimateOnANumberLineTo50',
      questionTypes: ['bdj', 'bdk', 'bdl'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin50/7EstimateOnANumberLineTo50" */ './7EstimateOnANumberLineTo50'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'OneMoreOneLess',
      questionTypes: ['bdm', 'bdn', 'bdo'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year1/Spring/PlaceValueWithin50/8OneMoreOneLess" */ './8OneMoreOneLess'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
