import Year1 from './Year 1';
import Year2 from './Year 2';
import Year3 from './Year 3';
import Year4 from './Year 4';
import Year5 from './Year 5';
import Year6 from './Year 6';
import { newSolContent } from './Sol';
import { type YearContent, type YearId } from './Year';
import { type QuestionTypeContent } from './Question';
import { type TermContent, type TermId } from './Term';
import { type BlockContent, type BlockId } from './Block';
import { type SmallStepContent, type SmallStepId } from './SmallStep';
import { fluent } from '@codibre/fluent-iterable';
import { type TermKey, type YearKey } from '../i18n/custom-types';
import { type TranslationFunctions } from '../i18n/i18n-types';
import { base64ToString, stringToBase64 } from '../utils/encodings';
import { version } from '../version';

// If this file is imported, add the version number to global variables, for debugging only.
// Usage at runtime: type `window.__WRE_commonVersion` into the console.
// It's usually frowned upon for libraries to modify the global state in any way. However, this is only to be used by
// humans for debugging, and it's been named in a way to avoid conflicting with anything else.
(globalThis as Record<string, unknown>)['__WRE_commonVersion'] = version;

/**
 * The data used by the question store app to show an entry for each question definition, with forms, etc.
 *
 * Each data type has an Id (which is unique and serializable, so it can be used as a navigation prop) and some
 * Content. The data is nested in the directory structure, which maps the SoL.
 */
export const Sol = newSolContent({
  years: [Year1, Year2, Year3, Year4, Year5, Year6]
});

/** Fully qualified question type - contains information about where it is in the course. */
export type FQQuestionTypeContent<Q = Record<string, unknown>> = {
  questionType: QuestionTypeContent<Q>;
} & SmallStepId & { uid: string; indexInSmallStep?: number; archived?: true };

/** Like {@link FQQuestionTypeContent} except the question type has not been loaded yet. */
export type FQQuestionTypeContentUnloaded<Q = Record<string, unknown>> = {
  questionType: () => Promise<QuestionTypeContent<Q>>;
} & SmallStepId & { uid: string; indexInSmallStep?: number; archived?: true };

/**
 * Straightforward map from UID to question type (promise).
 *
 * INCLUDES ARCHIVED QUESTION TYPES.
 */
export const QuestionTypesByUid: Map<string, FQQuestionTypeContentUnloaded> = fluent(Sol.years)
  .flatMap(({ year, terms }) =>
    fluent(terms).flatMap(({ term, blocks }) =>
      fluent(blocks).flatMap(({ block, smallSteps }) =>
        fluent(smallSteps).flatMap(
          ({ smallStep, questionTypes, archivedQuestionTypes, module }) => {
            const questionTypesArray = fluent(questionTypes).map(
              (uid): FQQuestionTypeContentUnloaded => ({
                year,
                term,
                block,
                smallStep,
                uid,
                indexInSmallStep: questionTypes.indexOf(uid),
                questionType: () =>
                  module().then(smallStepContent => smallStepContent.find(it => it.uid === uid)!)
              })
            );

            const archivedQuestionTypesArray = fluent(archivedQuestionTypes).map(
              (uid): FQQuestionTypeContentUnloaded => ({
                uid,
                year,
                term,
                block,
                smallStep,
                archived: true,
                questionType: () =>
                  module().then(smallStepContent => smallStepContent.find(it => it.uid === uid)!)
              })
            );

            return questionTypesArray.concat(archivedQuestionTypesArray);
          }
        )
      )
    )
  )
  .toMap(
    it => it.uid,
    it => it
  );

export const QuestionTypes = [...QuestionTypesByUid.values()];
export const QuestionTypeUids = [...QuestionTypesByUid.keys()];
export const ValidYears: YearKey[] = [...new Set(QuestionTypes.map(it => it.year))];
export const isValidYear = (year: string): year is YearKey => ValidYears.some(it => it === year);
export const ValidTerms: TermKey[] = [...new Set(QuestionTypes.map(it => it.term))];
export const isValidTerm = (term: string): term is TermKey => ValidTerms.some(it => it === term);

/** A question type UID with its data. The first element is the UID, the second element is the parameters. */
export type SpecificQuestion = [uid: string, parameters: Record<string, unknown>];
/** Information uniquely specifying a question type or specific question. This is small and serializable. */
export type QuestionToken = string | [uid: string, parameters: Record<string, unknown>];

/** Fully qualified question type optionally with data. */
export type FQQuestionTypeContentMaybeWithData<Q = Record<string, unknown>> =
  FQQuestionTypeContent<Q> & { data?: Q };

export type FQQuestionTypeContentWithData<Q = Record<string, unknown>> =
  FQQuestionTypeContent<Q> & { data: Q };

/**
 * Lookup a question token, without validating.
 *
 * This returns instantly, and unlike {@link loadQuestion} doesn't start importing the question's small step.
 */
export function lookupQuestion(
  token: QuestionToken
): (FQQuestionTypeContentUnloaded & { unvalidatedData?: Record<string, unknown> }) | null {
  const [uid, data] = typeof token === 'string' ? [token] : token;

  const fqQuestionTypeUnloaded = QuestionTypesByUid.get(uid);
  if (fqQuestionTypeUnloaded === undefined) return null;

  return { ...fqQuestionTypeUnloaded, unvalidatedData: data };
}

/**
 * Load and parse a question, returning an error string if it was invalid.
 *
 * The return type is a promise, because we have to dynamically import the small step. If the question was found,
 * its small step begins importing **immediately** upon calling this function.
 *
 * The input takes the form of a UID string, or a UID + question params pair (AKA {@link SpecificQuestion}).
 *
 * A question is invalid if:
 * - a Question Type for the UID was not found (promise resolves immediately in this case)
 * - the question params (if provided) were not valid according to the Question Type's schema
 *
 * If the input was a valid {@link SpecificQuestion}, the returned object's `data` is guaranteed to conform to the
 * Question Type's schema.
 */
// Some function overloads just to make it easier to use - data is guaranteed when you provide a SpecificQuestion
export async function loadQuestion(
  token: SpecificQuestion
): Promise<
  | { status: 'success' | 'invalid'; q: FQQuestionTypeContentWithData }
  | { status: 'notFound'; q?: undefined }
>;
export async function loadQuestion(
  token: QuestionToken
): Promise<
  | { status: 'success' | 'invalid'; q: FQQuestionTypeContentMaybeWithData }
  | { status: 'notFound'; q?: undefined }
>;
export async function loadQuestion(
  token: QuestionToken
): Promise<
  | { status: 'success' | 'invalid'; q: FQQuestionTypeContentMaybeWithData }
  | { status: 'notFound'; q?: undefined }
> {
  const [uid, data] = typeof token === 'string' ? [token] : token;

  // First look up the UID
  const fqQuestionTypeUnloaded = QuestionTypesByUid.get(uid);
  if (fqQuestionTypeUnloaded === undefined) return { status: 'notFound' };

  // Start the question loading
  const questionTypeContent = await fqQuestionTypeUnloaded.questionType();
  const fqQuestionTypeMaybeWithData = {
    ...fqQuestionTypeUnloaded,
    questionType: questionTypeContent,
    data
  };

  if (data !== undefined) {
    // Now validate
    const validationResult = questionTypeContent.schema.safeParse(data);
    if (!validationResult.success)
      return {
        status: 'invalid',
        q: fqQuestionTypeMaybeWithData
      };
  }

  return { status: 'success', q: fqQuestionTypeMaybeWithData };
}

////
// Utility functions for accessing the data a bit more easily.
////

export function getYearContent(id: YearId): YearContent | undefined {
  return Sol.years.find(it => it.year === id.year);
}

export function getTermContent(id: TermId): TermContent | undefined {
  return getYearContent(id)?.terms.find(it => it.term === id.term);
}

export function getBlockContent(id: BlockId): BlockContent | undefined {
  return getTermContent(id)?.blocks.find(it => it.block === id.block);
}

export function getSmallStepContent(id: SmallStepId): SmallStepContent | undefined {
  return getBlockContent(id)?.smallSteps.find(it => it.smallStep === id.smallStep);
}

/**
 * Get all question types matching some filter.
 *
 * @deprecated Marked as deprecated since this isn't fit for production.
 */
export function filterQuestionTypes(
  translate: TranslationFunctions,
  /** All terms that the user might enter - they are post-translation. */
  filter: {
    year?: string;
    term?: string;
    block?: string;
    smallStep?: string;
    // NB: cannot support keywords due to how this is architected.
    // keywords?: string[];
  }
): FQQuestionTypeContentUnloaded<Record<string, unknown>>[] {
  return fluent(QuestionTypes)
    .filter(questionType => {
      return (
        (filter.year === undefined || filter.year === translate.year(questionType.year)) &&
        (filter.term === undefined || filter.term === translate.term(questionType.term)) &&
        (filter.block === undefined || filter.block === translate.block(questionType.block)) &&
        (filter.smallStep === undefined ||
          filter.smallStep === translate.smallStep(questionType.smallStep))
      );
    })
    .toArray();
}

////
// Utility functions for encoding/decoding tokens for URL strings
////

/**
 * Convert a question token to a URL-safe string.
 *
 * String tokens are as-they-are. Tuple tokens are represented as uid~base64EncodedData, e.g.
 * abc~eyJudW1iZXIiOjQyLCJsZWZ0UGFydCI6MjB9, where the base64 encoding uses -_ as its final two symbols.
 */
export function stringifyToken(token: QuestionToken): string {
  if (typeof token === 'string') {
    return token;
  }
  const [uid, data] = token;
  return `${uid}~${stringToBase64(JSON.stringify(data), true)}`;
}

/**
 * Convert several question tokens to a URL-safe string. See {@link stringifyToken}.
 *
 * Tokens are joined using the `.` symbol, e.g. abc.def.ghi is a list of 3 tokens.
 */
export function stringifyTokens(tokens: QuestionToken[]): string {
  return tokens.map(stringifyToken).join('.');
}

/**
 * Converts a URL-safe token string into a question token. Undoes {@link stringifyToken}.
 *
 * Returns error object if the token was invalid (e.g. not recognized or invalid data).
 */
export async function parseToken(
  s: string
): Promise<QuestionToken | { error: string; token: string }> {
  let token: QuestionToken;
  if (s.includes('~')) {
    const [uid, encodedData] = s.split('~');
    try {
      token = [uid, JSON.parse(base64ToString(encodedData, true))] as SpecificQuestion;
    } catch (e) {
      return { error: 'JSON parse error', token: s };
    }
  } else {
    token = s;
  }

  const parseResult = await loadQuestion(token);
  if (parseResult.status !== 'success') {
    return { error: parseResult.status, token: s };
  } else {
    return token;
  }
}

export type TokenParseError = { error: string; token: string };
export function isTokenParseError(x: QuestionToken | TokenParseError): x is TokenParseError {
  return typeof x === 'object' && 'error' in x;
}
export function isQuestionToken(x: QuestionToken | TokenParseError): x is QuestionToken {
  return !isTokenParseError(x);
}

/**
 * Converts a URL-safe token string into an array of question tokens. Undoes {@link stringifyTokens}.
 *
 * Each token may be null if that token is invalid (e.g. not recognized or invalid data).
 */
export async function parseTokens(s: string): Promise<(QuestionToken | TokenParseError)[]> {
  return Promise.all(s.split('.').map(parseToken));
}

/**
 * Like {@link parseTokens}, but just keeps the valid tokens, and logs out an error for the invalid ones.
 */
export async function parseTokensBestEffort(s: string): Promise<QuestionToken[]> {
  const parsed = await parseTokens(s);

  const valids = parsed.filter(isQuestionToken);
  if (valids.length !== parsed.length) {
    const invalids = parsed.filter(isTokenParseError);
    console.error(`Filtering out invalid tokens: ${JSON.stringify(invalids)}`);
  }
  return valids;
}
