import { loadQuestion, type QuestionToken, type SpecificQuestion } from 'common/SchemeOfLearning';
import premadeQuizzesJson from './premadeQuizzes.json';

/** Number of milliseconds in a day. */
const MS_PER_DAY = 1000 * 60 * 60 * 24;
/** Arbitrary start date, relatively recent. */
const START_DATE = new Date('2024-07-03');

/** Map from quiz display name to tokens. */
const premadeQuizzes = premadeQuizzesJson;

type QuizName = keyof typeof premadeQuizzes;

/** List of premade quiz names. */
const premadeQuizNames = Object.keys(premadeQuizzes) as QuizName[];

/** Get the quiz for the current day. We pick a new quiz every 24h, and cycle when reaching the end. */
export function getTodaysQuiz(): QuizName {
  const currentDate = new Date();
  const differenceInMilliseconds = currentDate.getTime() - START_DATE.getTime();

  // Number of whole days since startDate:
  const differenceInDays = Math.floor(differenceInMilliseconds / MS_PER_DAY);

  // We have N=premadeQuizNames.length quizzes, so we want to divide differenceInDays by N and use the remainder to
  // determine which to choose each day:
  const remainderDifference = differenceInDays % premadeQuizNames.length;
  return premadeQuizNames[remainderDifference];
}

/**
 * Generate question params for the input tokens. If a token already has params, then keep those.
 * @param tokens - input tokens, either UIDs or UIDS with params
 * @param forceNewParams - if true, new params are generated even for tokens already with them
 */
export async function generateParamsForTokens(
  tokens: QuestionToken[],
  forceNewParams = false
): Promise<SpecificQuestion[]> {
  return Promise.all(
    tokens.map(token =>
      loadQuestion(token).then(it => {
        if (it.status !== 'success') {
          throw new Error(`Token not valid: ${token}`);
        }
        const newToken: SpecificQuestion = [
          it.q.questionType.uid,
          forceNewParams || it.q.data === undefined ? it.q.questionType.generator() : it.q.data
        ];
        return newToken;
      })
    )
  );
}

/** Generate a new quiz, based on the current day. */
export function generateNewQuiz(): { quizName: QuizName; tokens: Promise<SpecificQuestion[]> } {
  const quizName = getTodaysQuiz();
  const tokens = generateParamsForTokens(premadeQuizzes[quizName]);
  return { quizName, tokens };
}
