// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newTermContent } from '../../Term';
import PlaceValueWithin20 from './PlaceValueWithin20';
import AdditionAndSubtraction from './AdditionAndSubtraction';
import MassAndVolume from './MassAndVolume';
import PlaceValueWithin50 from './PlaceValueWithin50';
import LengthAndHeight from './LengthAndHeight';

const Term = newTermContent({
  term: 'Spring',
  blocks: [
    PlaceValueWithin20,
    AdditionAndSubtraction,
    MassAndVolume,
    PlaceValueWithin50,
    LengthAndHeight
  ]
});
export default Term;
