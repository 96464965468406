import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { loadQuestion, type QuestionToken, type SpecificQuestion } from '../../SchemeOfLearning';
import Quiz from '../molecules/Quiz';
import Spinner from '../molecules/Spinner';
import BaseScreen from './BaseScreen';
import Results from '../molecules/Results';
import Text from '../typography/Text';
import QuizCard from './QuizCard';
import { useI18nContext } from '../../i18n/i18n-react';
import { WellDone } from './WellDone';
import { FullMarks } from './FullMarks';
import { getPlayer } from '../../utils/Audio';

type Props = {
  quizName: string;
  /** The tokens to show. Only used on first render, where it's saved internally. */
  tokens: QuestionToken[];
  /**
   * Position to resume from. Only used on first render.
   * If this is defined, then the loading screen says "x/y question(s)", otherwise it just says "y question(s)".
   */
  resumeFrom?: {
    currentQuestionIndex: number;
    currentQuestionIncorrectAttempts: number;
    results: { stars?: number }[];
    /** Stringified question params - should be used instead of generating new ones, if randomizeQuestionParams. */
    questionParams?: string;
    /** Time they've already spent on this question, in ms. */
    currentAttemptTimeElapsed?: number;
    /** Stringified user answer state. */
    currentUserAnswer?: string;
  };
  loadingTextColor: string;
  /**
   * Called during the loading screen when any tokens are invalid.
   *
   * If all tokens are invalid, the quiz will get stuck at the loading screen.
   */
  onTokensInvalid?: (invalidTokens: QuestionToken[]) => void;
  /** Called when the X button at the top left is clicked.*/
  onExitQuiz: (currentState?: {
    currentQuestionIndex: number;
    currentQuestionIncorrectAttempts: number;
    results: { stars?: number }[];
    /** Stringified question params. */
    questionParams: string;
    /** Time they've already spent on this question, in ms. */
    currentAttemptTimeElapsed: number;
    /** Stringified user answer state. */
    currentUserAnswer: string;
  }) => void;
  /** Called from the results screen. If absent, the retry quiz button isn't there. */
  onRetryQuiz?: () => void;
  /** Called from the results screen. If absent, the return to home button isn't there. */
  onReturnToHome?: () => void;
  /** Optional calback called from the quiz screen on every answer. */
  onAnswer?: (
    questionIndex: number,
    answer: string,
    isCorrect: boolean,
    timeTaken: number,
    attemptNumber: number,
    parameters: Record<string, unknown>
  ) => void;
  /** Optional calback called from the results screen. */
  onQuizEnd?: () => void;
  /**
   * Callback to run if there's an error in a question. Use this to log to something like Sentry.
   */
  onQuestionError?: (
    question: SpecificQuestion,
    userAnswer: Record<string, unknown>,
    error: Error
  ) => void;
  /**
   * Whether to skip the loading spinner (which doesn't actually do anything) and load straight into
   * the first question. Only used on first render.
   *
   * Default: false
   */
  skipLoading?: boolean;
};

/**
 * A full-screen component combining:
 * - Loading screen
 * - Quiz Screen
 * - Well done / 100% animation
 * - Results screen
 *
 * This will take up the whole window, and put the content in the largest 16:9 rectangle, centered.
 *
 * Various callbacks are available to run custom code at different points.
 */
export default function QuizAndResultsScreen({
  quizName,
  tokens: tokensProp,
  resumeFrom,
  loadingTextColor,
  onTokensInvalid,
  onExitQuiz: onExitQuizProp,
  onRetryQuiz: onRetryQuizProp,
  onReturnToHome: onReturnToHomeProp,
  onAnswer,
  onQuizEnd: onQuizEndProp,
  onQuestionError,
  skipLoading = false
}: Props) {
  const translate = useI18nContext().LL;
  const player = getPlayer();

  // Lock in the tokens with a ref, since we don't support them changing.
  const tokens = useRef(tokensProp).current;

  const [subScreen, setSubScreen] = useState<'loading' | 'quiz' | 'welldone' | 'results'>(
    skipLoading ? 'quiz' : 'loading'
  );
  const [results, setResults] = useState<Array<{
    stars: number;
  }> | null>(null);
  const fullMarks =
    (subScreen === 'results' || subScreen === 'welldone') && results!.every(it => it.stars === 3);

  const [questionInfo, setQuestionInfo] = useState<{
    questionTypes: Awaited<ReturnType<typeof loadQuestion>>[];
    invalidTokens: QuestionToken[];
    validTokens: QuestionToken[];
  }>();

  // Load and validate tokens on first render.
  //
  // (If `skipLoading` was false, this will be while the loading spinner is showing.)
  // (If these tokens have already been loaded, this should be instant.)
  // (By loading them here, when <Quiz> tries to load them it will be instant.)
  //
  // If any are invalid, call the callback.
  //
  // If at least one is valid, hide the loading spinner (if it was showing) once at least 3s have passed and they're
  // all loaded.
  useEffect(() => {
    (async () => {
      const atLeast3Seconds = new Promise(res => setTimeout(res, 3000));
      const questionTypes = await Promise.all(tokens.map(token => loadQuestion(token)));
      const invalidTokens = tokens.filter(
        (_token, index) => questionTypes[index].status !== 'success'
      );
      const validTokens = tokens.filter(
        (_token, index) => questionTypes[index].status === 'success'
      );

      if (invalidTokens.length > 0 && onTokensInvalid) {
        onTokensInvalid(invalidTokens);
      }

      if (validTokens.length > 0) {
        setQuestionInfo({ questionTypes, validTokens, invalidTokens });
        if (subScreen === 'loading') {
          // If we did all this during the loading spinner subscreen, wait until at least 3 seconds have passed and
          // switch to the quiz subscreen.
          await atLeast3Seconds;
          setSubScreen('quiz');
        }
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Play the correct audio
  useEffect(() => {
    if (subScreen === 'loading') {
      player.playSound('loading');
    } else if (subScreen === 'quiz') {
      player.stopSound();
      player.playSound('quizstart');
    } else if (subScreen === 'welldone') {
      fullMarks ? player.playSound('hundredpercent') : player.playSound('welldone');
    } else if (subScreen === 'results') {
      player.playSound('resultsscreen');
    }
  }, [fullMarks, player, subScreen]);

  ////
  // Callbacks
  ////

  const onExitQuiz = useCallback(
    (currentState?: {
      currentQuestionIndex: number;
      currentQuestionIncorrectAttempts: number;
      results: { stars?: number }[];
      questionParams: string;
      currentAttemptTimeElapsed: number;
      currentUserAnswer: string;
    }) => {
      player.stopSound();
      onExitQuizProp(currentState);
    },
    [onExitQuizProp, player]
  );

  const onRetryQuiz = useMemo(
    () =>
      // Keep this prop undefined if onRetryQuiz is undefined
      onRetryQuizProp
        ? () => {
            player.stopSound();
            onRetryQuizProp();
          }
        : undefined,
    [onRetryQuizProp, player]
  );

  const onReturnToHome = useMemo(
    () =>
      // Keep this prop undefined if onReturnToHome is undefined
      onReturnToHomeProp
        ? () => {
            player.stopSound();
            onReturnToHomeProp();
          }
        : undefined,
    [onReturnToHomeProp, player]
  );

  const onFinishQuiz = useCallback(
    (quizResults: { stars: number }[]) => {
      onQuizEndProp && onQuizEndProp();
      setResults(quizResults);
      setSubScreen('welldone');
    },
    [onQuizEndProp]
  );

  const questions = useMemo(
    () =>
      questionInfo !== undefined
        ? { mode: 'tokens' as const, tokens: questionInfo.validTokens, resumeFrom: resumeFrom }
        : undefined,
    [resumeFrom, questionInfo]
  );

  return (
    <BaseScreen>
      {subScreen !== 'loading' && (
        // Card to go behind questions as they animate out and back in, as well as between subScreens
        <Animated.View
          style={[StyleSheet.absoluteFill, { justifyContent: 'center', alignItems: 'center' }]}
          // Delay long enough to not get in the way of the first question appearing
          // Workaround: entering/exiting animation don't seem to be working well in web as of react-native-reanimated
          // 3.6 (crash) and 3.7 (weird effects). Disabling them for now (they used to be disabled anyway.)
          entering={Platform.OS === 'web' ? undefined : FadeIn.duration(250).delay(1000)}
          exiting={Platform.OS === 'web' ? undefined : FadeOut.duration(300)}
        >
          <QuizCard />
        </Animated.View>
      )}
      {(() => {
        switch (subScreen) {
          case 'loading':
            return (
              <View key="loading" style={{ gap: 64, alignItems: 'center' }}>
                <View style={{ gap: 24 }}>
                  <Spinner height={156} />
                  <Text
                    variant="WRN400"
                    style={{ color: loadingTextColor, fontSize: 32, lineHeight: 48 }}
                  >
                    {translate.misc.loadingEllipsis()}
                  </Text>
                </View>
                <View style={{ gap: 5, alignItems: 'center' }}>
                  <Text
                    variant="WRN700"
                    style={{
                      color: loadingTextColor,
                      fontSize: 32,
                      lineHeight: 48,
                      textAlign: 'center'
                    }}
                  >
                    {quizName}
                  </Text>
                  {questionInfo && (
                    <Text
                      variant="WRN400"
                      style={{ color: loadingTextColor, fontSize: 32, lineHeight: 48 }}
                    >
                      {resumeFrom
                        ? translate.misc.numberOfQuestionsFraction(
                            resumeFrom.currentQuestionIndex,
                            questionInfo.validTokens.length
                          )
                        : translate.misc.numberOfQuestions(questionInfo.validTokens.length)}
                    </Text>
                  )}
                </View>
              </View>
            );
          case 'quiz':
            return (
              questions && (
                <Quiz
                  key="quiz"
                  questions={questions}
                  onExitQuiz={onExitQuiz}
                  onFinishQuiz={onFinishQuiz}
                  onAnswer={onAnswer}
                  onRetryQuiz={onRetryQuiz}
                  onReturnToHome={onReturnToHome}
                  onQuestionError={onQuestionError}
                />
              )
            );
          case 'welldone':
            return (
              <Animated.View
                key="welldone"
                style={{ gap: 24, alignItems: 'center', zIndex: 9990 }}
                // Delay long enough for the last question to exit
                // Workaround: entering/exiting animation don't seem to be working well in web as of react-native-reanimated
                // 3.6 (crash) and 3.7 (weird effects). Disabling them for now (they used to be disabled anyway.)
                entering={Platform.OS === 'web' ? undefined : FadeIn.duration(250).delay(300)}
                exiting={Platform.OS === 'web' ? undefined : FadeOut.duration(300)}
              >
                {fullMarks ? (
                  <FullMarks
                    onAnimationFinish={
                      () =>
                        setTimeout(() => {
                          setSubScreen('results');
                        }, 1500) // 3.5 seconds - animation.length === 1500
                    }
                  />
                ) : (
                  <WellDone onAnimationFinish={() => setSubScreen('results')} />
                )}
              </Animated.View>
            );
          case 'results':
            return (
              <Animated.View
                key="results"
                style={{ gap: 24, alignItems: 'center', zIndex: 9999 }}
                // Delay long enough for the well done screen to exit
                // Workaround: entering/exiting animation don't seem to be working well in web as of react-native-reanimated
                // 3.6 (crash) and 3.7 (weird effects). Disabling them for now (they used to be disabled anyway.)
                entering={Platform.OS === 'web' ? undefined : FadeIn.duration(250).delay(300)}
                exiting={Platform.OS === 'web' ? undefined : FadeOut.duration(300)}
              >
                <Results
                  results={results!}
                  // onExitQuiz takes optional arguments, but onExitClicked is typed as () => void, meaning it claims
                  // it ignores all arguments it's provided. Therefore, it's not *sound* to simply assign onExitQuiz
                  // to the type of onExitClicked. So we make an explicit arrow function for this.
                  onExitClicked={() => onExitQuiz()}
                  onTryAgainClicked={onRetryQuiz}
                  onHomeClicked={onReturnToHome}
                  quizName={quizName}
                />
              </Animated.View>
            );
        }
      })()}
    </BaseScreen>
  );
}
