import { newBlockContent } from '../../../Block';
import { newSmallStepContent } from '../../../SmallStep';

const Block = newBlockContent({
  block: 'Money',
  field: 'Measurement',
  weeks: [1, 2],
  smallSteps: [
    // Step 1
    newSmallStepContent({
      smallStep: 'CountMoneyPence',
      questionTypes: ['bh9', 'bia', 'bib'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/Money/1CountMoneyPence" */ './1CountMoneyPence'
        ).then(mod => mod.default)
    }),
    // Step 2
    newSmallStepContent({
      smallStep: 'CountMoneyPoundsNotesAndCoins',
      questionTypes: ['bic', 'bid', 'bie'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/Money/2CountMoneyPoundsNotesAndCoins" */ './2CountMoneyPoundsNotesAndCoins'
        ).then(mod => mod.default)
    }),
    // Step 3
    newSmallStepContent({
      smallStep: 'CountMoneyPoundsAndPence',
      questionTypes: ['bif', 'big', 'bih'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/Money/3CountMoneyPoundsAndPence" */ './3CountMoneyPoundsAndPence'
        ).then(mod => mod.default)
    }),
    // Step 4
    newSmallStepContent({
      smallStep: 'ChooseNotesAndCoins',
      questionTypes: ['bii', 'bij', 'bik'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/Money/4ChooseNotesAndCoins" */ './4ChooseNotesAndCoins'
        ).then(mod => mod.default)
    }),
    // Step 5
    newSmallStepContent({
      smallStep: 'MakeTheSameAmount',
      questionTypes: ['bil', 'bim', 'bin'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/Money/5MakeTheSameAmount" */ './5MakeTheSameAmount'
        ).then(mod => mod.default)
    }),
    // Step 6
    newSmallStepContent({
      smallStep: 'CompareAmountsOfMoney',
      questionTypes: ['bio', 'bip2', 'biq'],
      archivedQuestionTypes: ['bip'],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/Money/6CompareAmountsOfMoney" */ './6CompareAmountsOfMoney'
        ).then(mod => mod.default)
    }),
    // Step 7
    newSmallStepContent({
      smallStep: 'CalculateWithMoney',
      questionTypes: ['bir', 'bis', 'bit'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/Money/7CalculateWithMoney" */ './7CalculateWithMoney'
        ).then(mod => mod.default)
    }),
    // Step 8
    newSmallStepContent({
      smallStep: 'MakeAPound',
      questionTypes: ['biu', 'biv', 'biw'],
      archivedQuestionTypes: [],
      module: () =>
        import(/* webpackChunkName: "Year2/Spring/Money/8MakeAPound" */ './8MakeAPound').then(
          mod => mod.default
        )
    }),
    // Step 9
    newSmallStepContent({
      smallStep: 'FindChange',
      questionTypes: ['bix', 'biy2', 'biz'],
      archivedQuestionTypes: ['biy'],
      module: () =>
        import(/* webpackChunkName: "Year2/Spring/Money/9FindChange" */ './9FindChange').then(
          mod => mod.default
        )
    }),
    // Step 10
    newSmallStepContent({
      smallStep: 'TwoStepProblems',
      questionTypes: ['biA', 'biB', 'biC'],
      archivedQuestionTypes: [],
      module: () =>
        import(
          /* webpackChunkName: "Year2/Spring/Money/10TwoStepProblems" */ './10TwoStepProblems'
        ).then(mod => mod.default)
    })
  ]
});
export default Block;
